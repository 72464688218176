import "./VideoCard.scss";
import dayjs from "dayjs";
import {
  bytesToMB,
  getLangName,
  getVideoStatus,
  shortName,
} from "../../../../Common/helper/helper";
import { VideoCardProps } from "../../../../Common/interface/types";
import { Tooltip } from "reactstrap";
import { useState } from "react";
import { VIDEO_CARD_TYPE } from "../../../../Constants/Common/Data";

const VideoCard = (props: VideoCardProps) => {
  const {
    id,
    title,
    date,
    size,
    user,
    thumbnail,
    trimSize = 28,
    type,
    lang,
    status,
  } = props;
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const [tooltipUserName, setTooltipUserName] = useState(false);

  const toggleTooltip = () => {
    setTooltipOpen(!tooltipOpen);
  };

  const toggleTooltipUserName = () => {
    setTooltipUserName(!tooltipUserName);
  };

  return (
    <div className="video-card-outer" key={id}>
      <div className="inner">
        <img
          alt="thumb"
          src={thumbnail}
          className={`video-thumb ${
            VIDEO_CARD_TYPE.INFO === type ? "info" : ""
          }`}
        />
        <div className="side-info">
          <div id={`title-${id}`} className="title">
            {shortName(title, trimSize)}
          </div>
          <Tooltip
            placement="top"
            isOpen={tooltipOpen}
            autohide={true}
            target={`title-${id}`}
            toggle={toggleTooltip}
          >
            {title}
          </Tooltip>
          {type === VIDEO_CARD_TYPE.INFO && (
            <>
              <div className="other-info">
                {size && <div className="info">Size : {bytesToMB(size)}</div>}
                {lang && <div className="info">Lang : {getLangName(lang)}</div>}
                {status && (
                  <div className="info">Status : {getVideoStatus(status)}</div>
                )}
              </div>
              <div className="upload-info">
                {date && (
                  <div className="info">
                    Uploaded at : {dayjs(date).format("DD-MM-YYYY")}
                  </div>
                )}
                {user && (
                  <>
                    <div  id={`user-name-${id}`} className="user">Uploaded by : {user}</div>
                    <Tooltip
                      placement="bottom"
                      isOpen={tooltipUserName}
                      autohide={true}
                      target={`user-name-${id}`}
                      toggle={toggleTooltipUserName}
                    >
                      {user}
                    </Tooltip>
                  </>
                )}
              </div>
            </>
          )}
          {type === VIDEO_CARD_TYPE.SHARE && (
            <>
              <div className="other-info">
                {date && (
                  <div className="info">{dayjs(date).format("DD-MM-YYYY")}</div>
                )}
                {size && <div className="info">{bytesToMB(size)}</div>}
                {user && (
                  <>
                    <div
                      id={`user-name-${id}`}
                      className={`user ${
                        type === VIDEO_CARD_TYPE.SHARE ? "share-user" : ""
                      }`}
                    >
                      {user}
                    </div>
                    <Tooltip
                      placement="bottom"
                      isOpen={tooltipUserName}
                      autohide={true}
                      target={`user-name-${id}`}
                      toggle={toggleTooltipUserName}
                    >
                      {user}
                    </Tooltip>
                  </>
                )}
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default VideoCard;
