import React, { useEffect, useState } from "react";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";

const StreamProcessing = () => {
  const [counter, setCounter] = useState(0);

  useEffect(() => {
    if (counter < 100) {
      const interval = setInterval(() => setCounter(counter + 1), 30);
      //Cleaning it up
      return () => clearInterval(interval);
    }
  }, [counter]);

  return (
    <div className="stream-processing">
      <div className="header">
        <h4>Please wait while your stream is being processed...</h4>
      </div>
      <CircularProgressbar
        value={counter}
        text={`${counter}%`}
        strokeWidth={5}
        styles={buildStyles({
          strokeLinecap: "butt",
          pathColor: "#A546EF",
          textColor: "#A546EF",
        })}
      />
    </div>
  );
};

export default StreamProcessing;
