import React, { Fragment } from "react";
import PropTypes from "prop-types";
import {
  useTable,
  useGlobalFilter,
  useSortBy,
  useFilters,
  useExpanded,
  usePagination,
  useRowSelect
} from "react-table";
import { Table, Row } from "reactstrap";
import { DefaultColumnFilter } from "./filters";
import { Link } from "react-router-dom";
import { TableContainerProps } from "../../Common/interface/types";

const TableContainerComp = ({
  columns,
  data,
  customPageSize,
  tableClass,
  theadClass,
  trClass,
  thClass,
  divClass,
  totalCount,
  handleNextPage,
  handlePreviousPage,
  pageIndex,
  handlePageChange,
}: TableContainerProps & {
  handleNextPage: () => void;
  handlePreviousPage: () => void;
  handlePageChange: (page: number) => void;
}) => {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    pageOptions,
    gotoPage,
  } = useTable(
    {
      columns,
      data,
      defaultColumn: { Filter: DefaultColumnFilter },
      initialState: {
        pageIndex: 0,
        pageSize: customPageSize,
        selectedRowIds: 0,
      },
      manualPagination: true,
      pageCount: Math.ceil(totalCount / customPageSize),
    },
    useGlobalFilter,
    useFilters,
    useSortBy,
    useExpanded,
    usePagination,
    useRowSelect
  );

  const generateSortingIndicator = (column: any) => {
    return column.isSorted ? (column.isSortedDesc ? " " : "") : "";
  };

  const handlePageChangeRecord = (page: number) => {
    handlePageChange(page)
    gotoPage(page)
  };


  return (
    <Fragment>
      <div className={divClass}>
        <Table hover {...getTableProps()} className={tableClass}>
          <thead className={theadClass}>
            {headerGroups.map((headerGroup: any) => (
              <tr
                className={trClass}
                key={headerGroup.id}
                {...headerGroup.getHeaderGroupProps()}
              >
                {headerGroup.headers.map((column: any) => (
                  <th
                    key={column.id}
                    className={thClass}
                    {...column.getSortByToggleProps()}
                    
                  >
                    {column.render('Header')}
                    {generateSortingIndicator(column)}
                  </th>
                ))}
              </tr>
            ))}
          </thead>

          <tbody {...getTableBodyProps()}>
            {page.map((row: any, index: number) => {
              prepareRow(row);
              return (
                <Fragment key={row.getRowProps().key}>
                  <tr className={'table-default'}>
                    {row.cells.map((cell: any, index: any) => {
                      return (

                        <td key={cell.id} {...cell.getCellProps()} >
                          {cell.render('Cell')}
                        </td>
                      );
                    })}
                  </tr>
                </Fragment>
              );
            })}
          </tbody>
        </Table>
      </div>

      <Row className='align-items-center mt-2 g-3 text-center text-sm-start'>
        <div className='col-sm'>
          <div className='text-muted'>
            Showing<span className='fw-semibold ms-1'>{page.length}</span> of{' '}
            <span className='fw-semibold'>{totalCount}</span> Results
          </div>
        </div>
        <div className='col-sm-auto'>
          <ul className='pagination pagination-separated pagination-md justify-content-center justify-content-sm-start mb-0'>
            <li
              className={
                pageIndex === 0 ? 'page-item disabled' : 'page-item'
              }
            >
              <Link to='#' className='page-link' onClick={handlePreviousPage}>
                Previous
              </Link>
            </li>
            {pageOptions.map((item: any, key: number) => {
              if (item === '...' || pageOptions.length <= 7) {
                return (
                  <React.Fragment key={key}>
                    <li className='page-item'>
                      <Link
                        to='#'
                        className={
                          pageIndex === item
                            ? 'page-link active'
                            : 'page-link'
                        }
                        onClick={() => {
                          handlePageChangeRecord(item);
                        }}
                      >
                        {item + 1}
                      </Link>
                    </li>
                  </React.Fragment>
                );
              }

              // Display first two pages, current page, last two pages, and ellipsis
              if (
                item === 0 ||
                item === 1 ||
                item === pageIndex - 1 ||
                item === pageIndex ||
                item === pageIndex + 1 ||
                item === pageOptions.length - 1
              ) {
                return (
                  <React.Fragment key={key}>
                    <li className='page-item'>
                      <Link
                        to='#'
                        className={
                          pageIndex === item
                            ? 'page-link active'
                            : 'page-link'
                        }
                        onClick={() => {
                          handlePageChange(item);
                        }}
                      >
                        {item + 1}
                      </Link>
                    </li>
                  </React.Fragment>
                );
              }

              // Display ellipsis for other pages
              if (key === 2 || key === pageOptions.length - 3) {
                return (
                  <React.Fragment key={key}>
                    <li className='page-item'>
                      <Link to='#' className='page-link disabled'>
                        ...
                      </Link>
                    </li>
                  </React.Fragment>
                );
              }

              return null;
            })}
            <li className={pageIndex === pageOptions.length - 1 ? 'page-item disabled' : 'page-item'}>
              <Link
                to='#'
                className='page-link'
                onClick={() => handleNextPage()}
              >
                Next
              </Link>
            </li>
          </ul>
        </div>
      </Row>
    </Fragment>
  );
};

TableContainerComp.propTypes = {
  preGlobalFilteredRows: PropTypes.any,
};

export default TableContainerComp;