import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import useUserStore from "../Store/UserStore/UserStore";

const NavData = () => {
    const history = useNavigate();
    const [isDashboard, setIsDashboard] = useState<boolean>(false);
    const [isSystem, setIsSystem] = useState<boolean>(false);
    const [isResource, setIsResource] = useState<boolean>(false);
    const [isCurrentState, setIsCurrentState] = useState('Dashboard');
    const [showUser, setShowUser] = useState(false);

    const { currentUser } = useUserStore((state: any) => ({
        currentUser: state.currentUser,
    }))

    function updateIconSidebar(e: any) {
        if (e && e.target && e.target.getAttribute("sub-items")) {
            const ul: any = document.getElementById("two-column-menu");
            const iconItems: any = ul.querySelectorAll(".nav-icon.active");
            let activeIconItems = [...iconItems];
            activeIconItems.forEach((item) => {
                item.classList.remove("active");
                var id = item.getAttribute("sub-items");
                const getID = document.getElementById(id) as HTMLElement;
                if (getID)
                    getID.classList.remove("show");
            });
        }
    }

    useEffect(() => {
        if (currentUser && currentUser?.isHSAdmin) {
            setShowUser(true)
        }
        else {
            setShowUser(false)
        }
    }, [currentUser])

    useEffect(() => {
        document.body.classList.remove('twocolumn-panel');
        if (isCurrentState !== 'Dashboard') {
            setIsDashboard(false);
        }
        if (isCurrentState !== 'System') {
            setIsSystem(false);
        }
        if (isCurrentState !== 'Resource') {
            setIsResource(false);
        }
    }, [
        history,
        isCurrentState,
        isDashboard,
        isResource,
        isSystem
    ]);

    const menuItems: any = [
        {
            id: "dashboard",
            label: "Dashboard",
            icon: "ri-apps-2-line",
            link: "/dashboard",
            stateVariables: isDashboard,
            checkPermission: true,
            click: function (e: any) {
                e.preventDefault();
                setIsDashboard(!isDashboard);
                setIsCurrentState('Dashboard');
                updateIconSidebar(e);
            }
        },
        {
            id: "system",
            label: "System",
            icon: "ri-account-circle-line",
            link: "/#",
            stateVariables: isSystem,
            checkPermission: showUser,
            click: function (e: any) {
                e.preventDefault();
                setIsCurrentState('System');
                updateIconSidebar(e);
                setIsSystem(!isSystem)
            },
            subItems: [
                { id: "user", label: "User", link: "/user", parentId: "system" },
            ],
        },
        // {
        //     id: "resource",
        //     label: "Resource",
        //     icon: "ri-folder-open-fill",
        //     link: "/#",
        //     stateVariables: isResource,
        //     checkPermission: true,
        //     click: function (e: any) {
        //         e.preventDefault();
        //         setIsCurrentState('Resource');
        //         setIsResource(!isResource)
        //         updateIconSidebar(e);
        //     },
        //     subItems: [
        //         { id: "shared_url", label: "Shared Url", link: "/share-url", parentId: "resource" },
        //     ],
        // },
        // {
        //     id: "dashboard",
        //     label: "Dashboard",
        //     icon: "ri-apps-2-line",
        //     link: "/new-dashboard",
        //     stateVariables: isDashboard,
        //     checkPermission: true,
        //     click: function (e: any) {
        //         e.preventDefault();
        //         setIsDashboard(!isDashboard);
        //         setIsCurrentState('Dashboard');
        //         updateIconSidebar(e);
        //     }
        // },
    ];
    return <React.Fragment>{menuItems}</React.Fragment>;
};
export default NavData;