import React, { useEffect, useState } from "react";
import "./LiveStream.scss";
import useTabStore from "../../../Store/TabStore/TabStore";
import {
  CONFIRMATION_MODAL_TYPE,
  ERROR_CODE,
  LIVE_SUBTITLE_TYPE,
  LIVE_VIDEO_STATUS,
  STREAM_TAB_TYPE,
  TAB_TYPE,
} from "../../../Constants/Common/Data";
import DeletePopupIcon from "../../../assets/images/New/DeletePopupIcon.svg";
import AutoDetectForm from "./AutoDetectForm";
import VideoEditor from "./VideoEditor";
import TextEditor from "./TextEditor";
import useStreamStore from "../../../Store/StreamStore/StreamStore";
import { Panel, PanelGroup } from "react-resizable-panels";
import StreamInfoModal from "./StreamInfoModal";
import ResizeHandle from "../ResizeHandler/ResizeHandler";
import TranscriptEditor from "./TranscriptEditor";
import {
  deleteLiveStream,
  getCheckLiveExist,
  getCheckVideoStatus,
  getLiveStreamList,
  getShortSummary,
  getVideoDetail,
  postStartVideoStatus,
} from "../../../ApiService/ApiCall";
import { toast } from "react-hot-toast";
import SpinnerLoader from "../../Common/Spinner/Spinner";
import ConfirmationModal from "./ConfirmationModel/ConfirmationModel";
import { LiveVideoAddFormDataProvider } from "../../../Common/interface/types";
import { LiveVideoAddFormDataDefaultValue } from "../../../Common/interface/defaultValue";
import Header from "./Components/Header/header";
import StreamListOuter from "./StreamList/StreamListOuter/StreamListOuter";
import StreamProcessing from "./StreamProcessing";
import { MESSAGE } from "../../../Constants/Messages";
import {
  ACTIVE_LIVE_VIDEO_COUNT,
  LIVE_VIDEO_LOAD_TIME,
} from "../../../Constants/Environment";
import InfoModal from "./Components/InfoModal/InfoModal";
import LiveTimerModal from "./Components/LiveTimerModal/LiveTimerModal";
import useStreamData from "./Hooks/useStreamData";
import PauseModal from "./Components/PauseModal/PauseModal";
import useUserStore from "../../../Store/UserStore/UserStore";
import { getPermissionDetails } from "../../../Common/helper/helper";
import { PERMISSION_CONFIG } from "../../../Constants/Permission";
import usePermissionStore from "../../../Store/PermissionStore/PermissionStore";
import BlankMessageBox from "./BlankMessageBox";

const LiveSteamSection = () => {
  const [isProcess, setIsProcess] = useState<boolean>(false);
  const [openDetectForm, setOpenDetectForm] = useState<boolean>(false);
  const [formData, setFormData] = useState<LiveVideoAddFormDataProvider>(
    LiveVideoAddFormDataDefaultValue
  );
  const [currentStream, setCurrentStream] = useState(null); // This state variable holds the index of the stream we're currently editing.
  const [isEdit, setIsEdit] = useState<null>(null); //Is Edit
  const [streamInfoModal, setStreamInfoModal] = useState<boolean>(false); // show the stream information
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [autoDetectData, setAutoDetectData] = useState<any>(null); //Set AutoDetect DATA
  const [limit] = useState(15);
  const [deleteModalStatus, setDeleteModalStatus] = useState(false);
  const [videoType, setVideoType] = useState(null);
  const [timeRemaining, setTimeRemaining] = useState(0);
  const [liveTimerModalStatus, setLiveTimerModalStatus] = useState(false);
  const [liveTimerModalVideoTitle, setLiveTimerModalVideoTitle] = useState("");
  const [selectedResourceId, setSelectedResourceId] = useState("");

  const LOAD_TIME = +LIVE_VIDEO_LOAD_TIME;
  const LIVE_VIDEO_COUNT = +ACTIVE_LIVE_VIDEO_COUNT;

  const { activeItems } = useTabStore((state: any) => ({
    activeItems: state.activeItems,
  }));

  const { currentUser } = useUserStore((state: any) => ({
    currentUser: state.currentUser,
  }));

  const {
    activeStreamItems,
    setVideoLoadStatus,
    toggleStreamActiveItem,
    setStreamActiveItem,
    setSelectedVideoStatus,
  } = useStreamStore();

  const {
    setSelectedVideoInfo,
    setStreamList,
    streamVideoList,
    setTotalCount,
    setPageCount,
    skipCount,
    setSkipCount,
    selectedPage,
    setSelectedPage,
    setSelectedVideoSubtitle,
    selectedVideoIds,
    setSelectedVideoIds,
    selectedVideoInfo,
    setInfoModalMessage,
    setInfoModalStatus,
    setIsProgressBarClick,
    isUpdateStreamList,
    setIsUpdateStreamList,
    searchValue,
  } = useStreamStore((state: any) => ({
    setSelectedVideoInfo: state.setSelectedVideoInfo,
    setStreamList: state.setStreamList,
    streamVideoList: state.streamVideoList,
    setTotalCount: state.setTotalCount,
    setPageCount: state.setPageCount,
    skipCount: state.skipCount,
    setSkipCount: state.setSkipCount,
    selectedPage: state.selectedPage,
    setSelectedPage: state.setSelectedPage,
    setSelectedVideoSubtitle: state.setSelectedVideoSubtitle,
    selectedVideoIds: state.selectedVideoIds,
    setSelectedVideoIds: state.setSelectedVideoIds,
    selectedVideoInfo: state.selectedVideoInfo,
    setInfoModalMessage: state.setInfoModalMessage,
    setInfoModalStatus: state.setInfoModalStatus,
    setIsProgressBarClick: state.setIsProgressBarClick,
    isUpdateStreamList: state.isUpdateStreamList,
    setIsUpdateStreamList: state.setIsUpdateStreamList,
    searchValue: state.searchValue,
  }));

  const { moveToListingPage } = useStreamData();

  const { liveFieldPermission, setLiveFieldPermission } = usePermissionStore();

  useEffect(() => {
    if (
      currentUser &&
      currentUser?.permissions &&
      currentUser?.permissions.length > 0
    ) {
      setLiveFieldPermission(
        getPermissionDetails(
          currentUser.permissions,
          PERMISSION_CONFIG.LIVE.MODEL,
          liveFieldPermission
        )
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentUser]);

  const handleDeleteModalClose = () => {
    setDeleteModalStatus(false);
    setSelectedVideoIds([]);
  };

  const handleDeleteModalOpen = () => {
    if (selectedVideoIds.length > 0) {
      setDeleteModalStatus(true);
    }
  };

  // handle Delete Stream
  const handleDelete = async () => {
    try {
      setDeleteModalStatus(false);
      setIsLoading(true);
      const res: any = await deleteLiveStream({
        resourceIds: selectedVideoIds,
      });
      if (res && res?.status) {
        await fetchListData(false, 0);
        setSkipCount(0);
        setSelectedPage(0);
        setIsLoading(false);
        toast.success(MESSAGE?.SUCCESS?.delete);
      } else if (res?.message) {
        setIsLoading(false);
        toast.error(res?.message);
      }
      setSelectedVideoIds([]);
      setIsLoading(false);
    } catch (err: any) {
      setIsLoading(false);
      setSelectedVideoIds([]);
      console.log(err);
    }
  };

  // show stream
  const handleShowStream = async (e: any, data: any, index: any) => {
    e.stopPropagation();
    setIsLoading(true);
    setVideoLoadStatus(false);
    const videoStatus: any = await checkVideoStatusApi(data?._id);
    if (
      videoStatus &&
      (videoStatus === LIVE_VIDEO_STATUS?.LIVE ||
        videoStatus === LIVE_VIDEO_STATUS?.RE_START ||
        videoStatus === LIVE_VIDEO_STATUS?.LIVE_ENDED ||
        videoStatus === LIVE_VIDEO_STATUS?.PAUSE)
    ) {
      setIsLoading(false);
      const videoInfo = await fetchVideoDetails(data?._id);
      if (videoInfo?.isProcessed) {
        setSelectedVideoStatus(videoStatus);
        await getSubtile(data);
        setVideoType(videoInfo?.type);
        setSelectedResourceId(videoInfo?._id);
        // setSelectedVideoInfo(selectedVideoInfo);
        setCurrentStream(index);
        if (videoStatus === LIVE_VIDEO_STATUS?.LIVE) {
          setLiveTimerModalStatus(true);
          setLiveTimerModalVideoTitle(videoInfo?.title);
          await executeAfterGivenTime(videoInfo);
        } else {
          // restart or pause case
          if (
            videoInfo?.status === LIVE_VIDEO_STATUS?.PAUSE &&
            videoStatus === LIVE_VIDEO_STATUS?.RE_START
          ) {
            const tempVideoInfo = {
              ...videoInfo,
              status: LIVE_VIDEO_STATUS.LIVE,
            };
            setSelectedVideoInfo(tempVideoInfo);
          } else {
            setSelectedVideoInfo(videoInfo);
          }

          setStreamActiveItem([
            STREAM_TAB_TYPE?.LIVE_STREAM_EDITOR,
            STREAM_TAB_TYPE.TRANSCRIPT_EDITOR,
          ]);
        }
      } else if (videoInfo && !videoInfo?.isProcessed) {
        setIsLoading(false);
        setLiveTimerModalStatus(false);
        setInfoModalMessage(MESSAGE.ERROR.videoIsINQueue);
        setInfoModalStatus(true);
      }
    } else {
      setIsLoading(false);
      if (videoStatus === LIVE_VIDEO_STATUS?.NOT_STARTED) {
        setInfoModalMessage(`${data?.title} ${MESSAGE.ERROR.videoNotLive}`);
        setInfoModalStatus(true);
      } else if (videoStatus === LIVE_VIDEO_STATUS?.ERROR) {
        setInfoModalMessage(`Internal server error`);
        setInfoModalStatus(true);
      }
    }
  };

  // check video status
  const checkVideoStatusApi = async (videoId: string) => {
    try {
      const payload = { videoId: videoId };
      const res = await getCheckVideoStatus(payload);
      return res?.status;
    } catch (err: any) {
      console.log(err);
      toast.error(err?.msg || err?.message);
    }
  };

  // handle Auto Detect Edit form
  const handleInfoEdit = async (e: any, index: any) => {
    e.stopPropagation();
    const res = await fetchVideoDetails(streamVideoList?.result[index]?._id);
    if (res) {
      setFormData(res);
      setAutoDetectData(null);
      setOpenDetectForm(true); //open autoDetect Form Modal
      setIsEdit(index);
      setCurrentStream(index);
      const updatedStream = streamVideoList?.result?.map((stream: any) =>
        stream?._id === res?._id ? res : stream
      );
      setStreamList({ result: updatedStream });
    }
  };

  // handle info Model
  const handleInfo = async () => {
    if (currentStream !== null) {
      const res = await fetchVideoDetails(
        streamVideoList?.result[currentStream]?._id
      );
      if (res) {
        setFormData(res);
        setAutoDetectData(null);
        setOpenDetectForm(true); //open autoDetect Form Modal
        setIsEdit(currentStream);
      }
    }
  };

  // handle Auto Detect Open form
  const handleOpenAutoDetectForm = () => {
    setOpenDetectForm(true);
    setIsEdit(null);
    setAutoDetectData(null);
    setFormData(LiveVideoAddFormDataDefaultValue);
    setSelectedVideoIds([]);
  };

  const fetchListData = async (isUpdate = false, skip = skipCount) => {
    const out = {
      skip: skip,
      limit: limit,
      searchStr: searchValue,
      sortBy: "createdAt desc",
    };
    try {
      setIsLoading(true);
      const data: any = await getLiveStreamList(out);
      if (data) {
        if (isUpdate) {
          const prevList = streamVideoList;
          const newList = {
            ...prevList,
            result: [...prevList.result, ...data.result],
            total: data.total,
          };
          setStreamList(newList);
          setTotalCount(data?.total);
          setPageCount(Math.ceil(data?.total / limit));
          // setSkipCount(selectedPage * limit);
          setIsLoading(false);
        } else {
          setStreamList(data);
          setTotalCount(data?.total);
          setPageCount(Math.ceil(data?.total / limit));
          setSkipCount(selectedPage * limit);
          setIsLoading(false);
        }
        return data;
      }
    } catch (error: any) {
      setIsLoading(false);
      toast.error(error?.message);
    }
  };

  useEffect(() => {
    const getMoreList = async () => {
      await fetchListData();
    };
    getMoreList();

    return () => {
      setTotalCount(0);
      setSelectedPage(0);
      setSkipCount(0);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchValue]);

  const loadData = async () => {
    await fetchListData(true, skipCount + limit);
    setSkipCount(skipCount + limit);
    setSelectedPage(selectedPage + 1);
  };

  // for delete first video
  useEffect(() => {
    if (streamVideoList?.result?.length === 0 && streamVideoList?.total > 0) {
      fetchListData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [streamVideoList]);

  useEffect(() => {
    if (isUpdateStreamList) {
      fetchListData();
      setIsUpdateStreamList(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isUpdateStreamList]);

  const startStream = async (apiRes: any) => {
    // const data = await fetchListData();
    // setStreamList(data);
    setIsProgressBarClick(false);
    const output = await checkLive();
    if (output && output?.length === LIVE_VIDEO_COUNT - 1) {
      await changeVideoStatusApi(
        apiRes?.res?.data?._id,
        LIVE_VIDEO_STATUS?.LIVE,
        apiRes?.newList
      );
      const videoInfo = await fetchVideoDetails(apiRes?.res?.data?._id);
      setSelectedResourceId(videoInfo?._id);
      if (videoInfo?.isProcessed) {
        await executeAfterGivenTime(videoInfo, false);
      } else {
        setIsLoading(false);
        setLiveTimerModalStatus(false);
        setInfoModalMessage(MESSAGE.ERROR.videoIsINQueue);
        setInfoModalStatus(true);
      }
    } else {
      setInfoModalMessage(
        MESSAGE?.ERROR?.anotherLive +
          `(${output[0]?.title} ) by different user.  `
      );
      setInfoModalStatus(true);
    }
  };

  const checkLive = async () => {
    try {
      const res: any = await getCheckLiveExist({});
      return res;
    } catch (err: any) {
      console.log(err);
      toast.error(err?.msg || err?.message);
    }
  };

  const executeAfterGivenTime = async (videoInfo: any, type = true) => {
    const currentTime = new Date();
    const targetTime = new Date(videoInfo?.liveStreamStartedAt);
    const fiveMinutesInMs = LOAD_TIME * 60 * 1000; // 5 minutes in milliseconds

    // Add 5 minutes to the given datetime
    const targetTimePlusFiveMin = new Date(
      targetTime.getTime() + fiveMinutesInMs
    );

    // Calculate the time difference
    const timeDifference =
      targetTimePlusFiveMin.getTime() - currentTime.getTime();
    const delay = timeDifference > 0 ? timeDifference : 0;
    setTimeRemaining(delay);

    const executeActions = () => {
      setLiveTimerModalStatus(false);
      setSelectedVideoInfo(videoInfo);
      if (type) {
        setStreamActiveItem([
          STREAM_TAB_TYPE?.LIVE_STREAM_EDITOR,
          STREAM_TAB_TYPE.TRANSCRIPT_EDITOR,
        ]);
      }
    };

    if (delay > 0) {
      setLiveTimerModalVideoTitle(videoInfo?.title);
      setLiveTimerModalStatus(true);
    } else {
      executeActions();
    }
  };

  const handleStart = async () => {
    setLiveTimerModalStatus(false);
    setIsProgressBarClick(false);
    setIsLoading(true);
    const videoInfo = await fetchVideoDetails(
      selectedVideoInfo?._id || selectedResourceId
    );
    await getSubtile(videoInfo);
    setSelectedVideoInfo(videoInfo);
    setSelectedVideoStatus(videoInfo?.status);
    if (videoInfo?.isProcessed) {
      await executeAfterGivenTime(videoInfo);
      setIsLoading(false);
    } else if (videoInfo && !videoInfo?.isProcessed) {
      setIsLoading(false);
      setInfoModalMessage(MESSAGE.ERROR.videoIsINQueue);
      setInfoModalStatus(true);
      setStreamActiveItem([STREAM_TAB_TYPE?.LIVE_STREAM_LIST]);
    }
  };

  const fetchVideoDetails = async (resourceId: string) => {
    try {
      setIsLoading(true);
      const response: any = await getVideoDetail({
        resourceId: resourceId,
        type: 2,
      });
      setIsLoading(false);
      return response;
    } catch (err: any) {
      console.log(err);
      setIsLoading(false);
      if (err?.type && err?.type === ERROR_CODE?.DELETE) {
        moveToListingPage();
      }
      toast.error(err?.msg || err?.message);
    }
  };

  const changeVideoStatusApi = async (
    id: string,
    status: number,
    data: any
  ) => {
    try {
      const payload = {
        productionResourceId: id,
        status: status,
      };
      const res: any = await postStartVideoStatus(payload);
      if (res && res?.status) {
        const temList = data?.result;
        const newList = temList?.map((item: any) => {
          if (item?._id === id) {
            return { ...item, status: status };
          } else {
            return item;
          }
        });
        setStreamList({ ...data, result: newList });
      } else if (res?.message) {
        toast.error(res?.message);
      }
    } catch (err: any) {
      console.log(err);
      toast.error(err?.msg || err?.message);
    }
  };

  const getSubtile = async (data: any) => {
    const out = {
      productionResourceId: data?._id || data?.id,
      type: LIVE_SUBTITLE_TYPE,
    };
    try {
      setIsLoading(true);
      const output: any = await getShortSummary(out);
      setSelectedVideoSubtitle(output);
      setIsLoading(false);
    } catch (err: any) {
      setIsLoading(false);
      toast.error(err?.msg || err?.message);
    }
  };

  const handleCloseVideoEditor = () => {
    if (activeStreamItems.length === 1) {
      setStreamActiveItem([STREAM_TAB_TYPE?.LIVE_STREAM_LIST]);
    } else {
      toggleStreamActiveItem(STREAM_TAB_TYPE.LIVE_STREAM_EDITOR);
    }
  };

  return (
    <div
      className={`stream-section ${
        activeItems.includes(TAB_TYPE.LIVE_STREAM) ? "h-80" : ""
      }`}
    >
      {isLoading ? <SpinnerLoader /> : ""}
      {streamVideoList?.result && streamVideoList?.result.length > 0 && (
        <Header handleOpenAutoDetectForm={handleOpenAutoDetectForm} />
      )}
      <div
        className="live-stream-section"
        style={{ width: "100%", height: "100%" }}
      >
        <PanelGroup
          autoSaveId="live-stream-section"
          className="live-stream-panel-group"
          direction="horizontal"
        >
          {activeStreamItems.includes(STREAM_TAB_TYPE?.LIVE_STREAM_LIST) &&
            liveFieldPermission.LISTING && (
              <>
                {isProcess ? (
                  <StreamProcessing />
                ) : (
                  <StreamListOuter
                    handleOpenAutoDetectForm={handleOpenAutoDetectForm}
                    handleDeleteModalOpen={handleDeleteModalOpen}
                    handleShowStream={handleShowStream}
                    handleInfoEdit={handleInfoEdit}
                    setIsLoading={setIsLoading}
                    loadData={loadData}
                  />
                )}
              </>
            )}
          {!liveFieldPermission.LISTING && <BlankMessageBox />}

          {/* Text Editor Section */}
          {activeStreamItems.includes(STREAM_TAB_TYPE?.TEXT_EDITOR) && (
            <>
              <Panel
                collapsible={false}
                defaultSize={50}
                order={3}
                id="3"
                minSize={29}
              >
                <div className="outer-wrapper">
                  <TextEditor
                    toggleComponent={() =>
                      toggleStreamActiveItem(STREAM_TAB_TYPE?.TEXT_EDITOR)
                    }
                    type={STREAM_TAB_TYPE?.TEXT_EDITOR}
                  />
                </div>
              </Panel>
              <ResizeHandle />
            </>
          )}
          {/* Script Editor Section */}
          {activeStreamItems.includes(STREAM_TAB_TYPE?.SCRIPT_EDITOR) && (
            <>
              <Panel
                collapsible={false}
                defaultSize={50}
                order={4}
                id="4"
                minSize={29}
              >
                <div className="outer-wrapper">
                  <TextEditor
                    title="Script"
                    toggleComponent={() =>
                      toggleStreamActiveItem(STREAM_TAB_TYPE?.SCRIPT_EDITOR)
                    }
                    type={STREAM_TAB_TYPE?.SCRIPT_EDITOR}
                  />
                </div>
              </Panel>
              <ResizeHandle />
            </>
          )}

          {/* Transcript Editor Section */}
          {activeStreamItems.includes(STREAM_TAB_TYPE?.TRANSCRIPT_EDITOR) && (
            <>
              <Panel
                collapsible={false}
                defaultSize={50}
                order={5}
                id="5"
                minSize={29}
              >
                <div className="outer-wrapper">
                  <TranscriptEditor
                    toggleComponent={() =>
                      toggleStreamActiveItem(STREAM_TAB_TYPE?.TRANSCRIPT_EDITOR)
                    }
                  />
                </div>
              </Panel>
              <ResizeHandle />
            </>
          )}

          {/* Live Stream Section */}
          {activeStreamItems.includes(STREAM_TAB_TYPE?.LIVE_STREAM_EDITOR) && (
            <>
              <Panel
                collapsible={false}
                defaultSize={50}
                order={6}
                id="6"
                minSize={50}
              >
                <div className="outer-wrapper">
                  <VideoEditor
                    state={activeStreamItems}
                    handleCloseVideoEditor={handleCloseVideoEditor}
                    handleInfo={handleInfo}
                  />
                </div>
              </Panel>
              <ResizeHandle />
            </>
          )}
          <Panel
            collapsible={false}
            defaultSize={1}
            order={7}
            id="7"
            minSize={0}
          >
            <div></div>
          </Panel>
        </PanelGroup>
      </div>

      {/* Auto Detect Form */}
      <AutoDetectForm
        openDetectForm={openDetectForm}
        setOpenDetectForm={setOpenDetectForm}
        isEdit={isEdit}
        formData={formData}
        setFormData={setFormData}
        currentStream={currentStream}
        setCurrentStream={setCurrentStream}
        startStream={startStream}
        setIsProcess={setIsProcess}
        setInfoShow={setStreamInfoModal}
        setVideoType={setVideoType}
        autoDetectData={autoDetectData}
        setAutoDetectData={setAutoDetectData}
      />

      <StreamInfoModal
        videoType={videoType}
        infoShow={streamInfoModal}
        setInfoShow={setStreamInfoModal}
        formData={formData}
      />

      <InfoModal />
      <PauseModal />

      {liveTimerModalStatus && (
        <LiveTimerModal
          videoType={videoType}
          modalStatus={liveTimerModalStatus}
          setModalStatus={setLiveTimerModalStatus}
          title={formData?.title ? formData?.title : liveTimerModalVideoTitle}
          timeRemaining={timeRemaining}
          handleStart={handleStart}
        />
      )}

      {/* Error Auto Detect Form */}
      {/* <ErrorAutoDetect
        isShowing={isShowing}
        toggle={toggle}
      /> */}
      <ConfirmationModal
        show={deleteModalStatus}
        actionType="Delete"
        icon={DeletePopupIcon}
        handleClose={handleDeleteModalClose}
        handleSubmit={handleDelete}
        modalType={CONFIRMATION_MODAL_TYPE?.ACTION}
        title="Delete livestream"
        description={MESSAGE?.CONTENT?.deleteLiveStream}
      />
    </div>
  );
};

export default LiveSteamSection;
