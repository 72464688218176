import { MAX_FILE_NAME_LENGTH } from "./Common/Data";

export const MESSAGE = {
  SUCCESS: {
    shared: "Your video is now shared and ready for colleague collaboration.",
    upload:
      "Video uploaded and processing: AI transcription will be available shortly—refresh soon to view.",
    translationNow: "Translation now available in",
    documentUpdated: "Document updated.",
    transcriptionDeleted: "Transcription deleted at",
    textCopied: "Text copied to clipboard",
    textVerified: "Text verified",
    subtitleAdded: "Subtitle Added Successfully",
    linkCopy: "copied to clipboard",
    linkGenerated: "New upload URL generated for file transfers",
    linkDeactivated: "Upload URL expiration notice: Link deactivated",
    linkDeleted: "Upload URL removal notice: Link deleted",
    videoStatusChanges: "Video Status Changed To Processing Successfully",
    delete: "Live Stream Deleted Successfully",
    saveStream: "Live Stream Created and Saved for Further Processing",
    fileUpload: "File uploaded successfully!",
    addedForTransfer: "A file has been added for transfer",
  },
  WARNING: {
    uploadStop:
      "Upload Stopped: You've stopped the upload. Feel free to try again anytime",
    uploading: "We're on It!: Hang tight – your video is on its way up!",
    downloadWarning:
      "Hold Up, Download Happening: Just a sec, another download is wrapping up. Thanks for waiting!",
    uploadVideo:
      "Let’s Get Started with a Video: Ready, set, upload your first video to kick things off!",
    lastSubtitleDelete:
      "Can’t Remove the Last Subtitle: Oops, the last subtitle needs to stay put.",
    uploadOneVideo:
      "Need at Least One Video: Let’s upload a video to move forward, shall we?",
    selectVideo: "Please select a live stream video",
    ftpUploadWarning:
      "Upload in Progress: Just a moment, another file is being uploaded. Thanks for your patience!",
  },
  ERROR: {
    accessDenied: "Access denied: Contact admin for permission",
    copyFail: "Failed to copy",
    uploadFail: "The video upload wasn't completed. Please give it another try",
    emptyTextBox: "Empty text box: Please enter text before saving",
    fileFormatError:
      "Please use supported video formats: MXF, MOV, MP4, MPEG,M4V",
    downloadFail: "Download error: Please check the error : and retry",
    fileRenameError: `The file name should be less than ${MAX_FILE_NAME_LENGTH} characters`,
    userUpdateFail:
      "There was an issue updating the user status. Please try again or seek assistance",
    videoNotLive: "is not live. You have not yet started the live stream.",
    anotherLive:
      "We are processing another video right now. Please check back later.",
    videoIsINQueue:
      "Your video is in the queue for processing due to resource bottlenecks. Please check back later.",
    selectLang: "Please select a language",
    urlExist: "Url already exist",
    videoUrl: "Video URL is required to add the live stream",
    videoTitle: "Video title is required to add the live stream",
  },
  CONTENT: {
    reTranscribe:
      "A video has been added to the queue for transcription. You can check the status in the Operations tab",
    closeWindow:
      " You can close this window now and continue to work on your project.",
    translationNotAvailable:
      "Translation is currently not available for the selected video. Please check back later.",
    translationAddManual:
      "Translation is currently not available for the selected video. You can add it manually.",
    notProcessed:
      "The selected video is currently being processed and is not available yet. Please check back later.",
    deleteLiveStream:
      "Live Stream along with its subtitles and associated files are completely cleaned. This action cannot be undone.",
    stopStream:
      "Live stream stopped from pulling sources. All data up to this point has been saved as a file.",
    restartStream:
      "Live stream restarted from pulling sources. Previously saved data will be merged with newly fetched data.",
    startStream: "Live stream started from pulling sources for the first time.",
    internalServer: "Internal server error",
    saveData:
      "Please wait while we save your data. The data up to this point has been saved as a file.",
    dataSaved: "Your data has been successfully saved.",
    listNotAvailable:
      "No listings available at the moment. Please check back later.",
    searchNotFound:
      "No results found for your search. Try adjusting your filters or search terms.",
  },
};
