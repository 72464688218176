import React from "react";
import { Modal, ModalBody } from "reactstrap";
import CloseIcon from "../../../assets/images/New/close.svg";

const ModalLayout = (props: any) => {
  const { isShowing, hide, hideCloseButton = false, className } = props;
  return (
    <Modal
      fade={true}
      isOpen={isShowing}
      className={`cancel-modal-outer ${className}`}
      centered={true}
    >
      <ModalBody className="cancel-modal" {...props}>
        {!hideCloseButton && (
          <div className="close" onClick={hide}>
            <img src={CloseIcon} className="cursor-pointer" alt="close" />
          </div>
        )}
        {props.children}
      </ModalBody>
    </Modal>
  );
};

export default ModalLayout;
