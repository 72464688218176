import React, { useEffect, useState, useCallback, useMemo } from "react";
import { Link, useNavigate } from "react-router-dom";
import ActionIcon from "../assets/images/New/Action.svg";
import {
  Col,
  Container,
  Row,
  Card,
  CardBody,
  Input,
  ModalHeader,
  ModalBody,
  Label,
  ModalFooter,
  Modal,
  Form,
  FormFeedback,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Badge,
} from "reactstrap";
import {
  getUser,
  updateUser,
  deleteUser,
  createUser,
} from "../ApiService/ApiCall";
import BreadCrumb from "../Components/Common/BreadCrumb";
import TableContainer from "./../Components/Common/TableContainer";
import DeleteModal from "../Components/Common/DeleteModal";
import * as Yup from "yup";
import { useFormik } from "formik";
import useUserStore from "../Store/UserStore/UserStore";
import { userType } from "../Common/interface/types";
import dayjs from "dayjs";
import SpinnerLoader from "../Components/Common/Spinner/Spinner";
import { isBlank } from "../Common/helper/helper";
import { MESSAGE } from "../Constants/Messages";
import UserNameToolTip from "../Components/New/Common/UserNameTooltip/UserNameTooltip";
import Toast from "../Common/helper/toasterService";

const User = () => {
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const [user, setUser] = useState<any>([]);
  const [deleteModal, setDeleteModal] = useState<boolean>(false);
  const [deleteType, setDeleteType] = useState(false);
  const [modal, setModal] = useState<boolean>(false);
  const [usersList, setUsersList] = useState<userType[]>([]);
  const [skipLimit, setSkipLimit] = useState(0);
  const [pageSize] = useState(10);
  const [userPageIndex, setUserPageIndex] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [totalCountOfPage, setTotalCountOfPage] = useState(0);
  const [search, setSearch] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [sortOrder, setSortOrder] = useState({
    column: "firstName",
    order: "asc",
  });
  const [isSearch, setIsSearch] = useState(false);

  const { currentUser } = useUserStore((state: any) => ({
    currentUser: state.currentUser,
  }));

  const navigate = useNavigate();

  //To get user list
  const getUserList = async (pageSize: number) => {
    try {
      setIsLoading(true);
      const skip = userPageIndex * pageSize;
      const limit = pageSize;
      let res: any = await getUser({
        sortBy: `${sortOrder.column} ${sortOrder.order}`,
        skip: skip,
        limit: limit,
        searchStr: search,
      });
      const tempUsersList = res.userList || [];
      const userTotalCount = res.totalCount || 0;
      const calculatedTotalPages = Math.ceil(userTotalCount / pageSize);
      setUsersList(tempUsersList);
      setSkipLimit(skip);
      setTotalCountOfPage(userTotalCount);
      setTotalPages(calculatedTotalPages);
      setIsLoading(false);
      return tempUsersList;
    } catch (err: any) {
      setIsLoading(false);
      Toast.showError(err);
      console.log(err);
    }
  };

  useEffect(() => {
    if (!isBlank(currentUser)) {
      fetchData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userPageIndex, pageSize, currentUser, sortOrder]);

  const fetchData = async () => {
    await callApi();
  };

  const callApi = async () => {
    try {
      if (!isBlank(currentUser) && currentUser?.isHSAdmin) {
        const tempUserList = await getUserList(pageSize);
        setUsersList(tempUserList);
      } else if (!isBlank(currentUser) && !currentUser?.isHSAdmin) {
        navigate("/");
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const handleNextPage = () => {
    setUserPageIndex((prevIndex) => {
      const newIndex = Math.min(prevIndex + 1, totalPages - 1);
      return newIndex;
    });
  };

  const handlePreviousPage = () => {
    setUserPageIndex((prevIndex) => {
      const newIndex = Math.max(prevIndex - 1, 0);
      return newIndex;
    });
  };

  //add User
  const addNewUser = async (user: any) => {
    try {
      setIsLoading(true);
      setModal(false);
      await createUser(user);
      getUserList(pageSize);
      validation.resetForm();
      setIsLoading(false);
      Toast.showSuccess(
        `New user ${user?.firstName} added to the system successfully`
      );
    } catch (err: any) {
      setIsLoading(false);
      setModal(true);
      if (err?.msg === "Email Already Exists") {
        validation.errors.email = err?.msg;
      }
      Toast.showError(err?.msg || err?.message);
      console.log(err);
    }
  };

  // Function to update user
  const handleUpdateUser = async (userToUpdate: any) => {
    try {
      setModal(false);
      await updateUser(userToUpdate);
      const updatedUsers = await getUserList(pageSize);
      setUsersList(updatedUsers);
      Toast.showSuccess(
        `User ${userToUpdate?.firstName} information has been updated.`
      );
      setUser("");
      validation.resetForm();
    } catch (err: any) {
      Toast.showError(err?.msg);
      console.error(err);
    }
  };

  // Function to toggle modal
  const toggle = useCallback(() => {
    if (modal) {
      setModal(false);
      setUser("");
      validation.resetForm();
    } else {
      setModal(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modal]);

  // Function to handle user click for updating
  const handleUserClick = useCallback(
    (arg: any) => {
      const user = arg;

      setUser({
        _id: user._id,
        firstName: user.firstName,
        lastName: user.lastName,
        email: user.email,
        phoneNumber: user.phoneNumber,
      });

      setIsEdit(true);
      toggle();
    },
    [toggle]
  );

  // Function to handle user deletion
  const handleDeleteUser = async (recordId: string) => {
    try {
      await deleteUser({ _id: recordId });
      const updatedUsers = await getUserList(pageSize);
      Toast.showSuccess(
        `User status updated: Account is ${
          deleteType ? "activated" : "deactivated"
        }`
      );
      setUser("");
      setDeleteModal(false);
      setUsersList(updatedUsers);
    } catch (err) {
      Toast.showError(MESSAGE.ERROR.userUpdateFail);
      console.error(err);
    }
  };
  // Function to handle delete button click
  const onClickDelete = async (user: any, type: boolean) => {
    await setUser(user);
    setDeleteType(type);
    setDeleteModal(true);
  };

  // Formik validation
  const validation: any = useFormik({
    enableReinitialize: true,
    initialValues: {
      firstName: (user && user.firstName) || "",
      lastName: (user && user.lastName) || "",
      email: (user && user.email) || "",
      phoneNumber: (user && user.phoneNumber) || null,
    },
    validationSchema: Yup.object({
      firstName: Yup.string()
        .trim()
        .matches(/^[A-Za-z ]+$/, "First Name cannot contain numbers or space")
        .max(20, "First Name must be at most 20 characters")
        .required("Please Enter First Name"),
      lastName: Yup.string()
        .trim()
        .matches(/^[A-Za-z ]+$/, "Last Name cannot contain numbers or space")
        .max(20, "Last Name must be at most 20 characters")
        .required("Please Enter Last Name"),
      email: Yup.string()
        .matches(
          /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
          "Invalid email address"
        )
        .required("Please Enter Email"),
    }),
    onSubmit: (values: any) => {
      if (isEdit) {
        const userToUpdate = {
          _id: user ? user._id : "",
          firstName: values.firstName.trim(),
          lastName: values.lastName.trim(),
          email: values.email,
          phoneNumber: values.phoneNumber || null,
        };
        handleUpdateUser(userToUpdate);
      } else {
        const newUser = {
          firstName: values["firstName"].trim(),
          lastName: values["lastName"].trim(),
          email: values["email"].trim(),
          phoneNumber: values["phoneNumber"],
        };

        addNewUser(newUser);
      }
    },
  });

  useEffect(() => {
    const getData = setTimeout(() => {
      if ((search.length === 0 || search.length > 2) && isSearch) {
        fetchData();
      }
    }, 800);

    return () => clearTimeout(getData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search, isSearch]);

  const handleSearchChange = async (event: any) => {
    setUserPageIndex(0);
    setIsSearch(true);
    setSearch((prevValue) => event.target.value);
  };

  const handlePageChange = async (page: number) => {
    setUserPageIndex(page);
  };

  const handleSortToggle = (columnName: string) => {
    setSortOrder((prevSortOrder) => ({
      column: columnName,
      order: prevSortOrder.order === "asc" ? "desc" : "asc",
    }));
  };

  // Column
  const columns = useMemo(
    () => [
      {
        Header: "Sr No.",
        accessor: (row: any, index: any) => {
          return skipLimit + 1 + index;
        },
        filterable: false,
      },
      {
        Header: (
          <div className="align-items-center" title="Sort">
            <span>Name</span>
            <i
              className={`${
                sortOrder.order === "asc"
                  ? "ri-arrow-up-line"
                  : "ri-arrow-down-line"
              } cursor-pointer mx-1`}
              onClick={() => handleSortToggle("firstName")}
            ></i>
          </div>
        ),
        accessor: "firstName",
        filterable: false,
        disableSortBy: true,
        Cell: (usersList: any) => (
          <>
            <UserNameToolTip
              index={usersList.row.original._id}
              key={usersList.row.original._id}
              item={usersList.row.original}
            />
          </>
        ),
      },
      {
        Header: "Email",
        accessor: "email",
        filterable: false,
        disableSortBy: true,
      },
      {
        Header: "Phone Number",
        accessor: "phoneNumber",
        filterable: false,
        disableSortBy: true,
      },
      {
        Header: "Joined Date",
        accessor: "createdAt",
        filterable: false,
        disableSortBy: true,
        Cell: (usersList: any) => (
          <>
            <div>
              {dayjs(usersList.row.original.createdAt).format(
                "DD-MM-YYYY HH:mm:ss"
              )}
            </div>
          </>
        ),
      },
      {
        Header: "Status",
        filterable: false,
        Cell: (cellProps: any) => (
          <>
            {cellProps.row.original.isArchived ? (
              <Badge color="danger">Inactive</Badge>
            ) : (
              <Badge color="primary">Active</Badge>
            )}
          </>
        ),
      },
      {
        Header: "Action",
        Cell: (cellProps: any) => {
          return (
            <UncontrolledDropdown>
              <DropdownToggle
                href="#"
                className="btn btn-soft-secondary btn-sm"
                tag="button"
                style={{ backgroundColor: "transparent", color: "black" }}
              >
                <img alt="action" className="action-icon" src={ActionIcon} />
              </DropdownToggle>
              <DropdownMenu className="dropdown-menu-end">
                <DropdownItem>
                  <Link
                    className="edit-item-btn"
                    to="#"
                    onClick={() => {
                      const UserData = cellProps.row.original;
                      handleUserClick(UserData);
                    }}
                  >
                    <i className="ri-pencil-fill align-bottom me-2 text-muted"></i>{" "}
                    Edit
                  </Link>
                </DropdownItem>
                {cellProps.row.original.isArchived ? (
                  <DropdownItem
                    onClick={() => {
                      const UserData = cellProps.row.original;
                      onClickDelete(UserData, true);
                    }}
                  >
                    <Link className="remove-item-btn" to="#">
                      <i className="ri-user-follow-fill align-bottom me-2 text-muted"></i>{" "}
                      Activated
                    </Link>
                  </DropdownItem>
                ) : (
                  <DropdownItem
                    onClick={() => {
                      const UserData = cellProps.row.original;
                      onClickDelete(UserData, false);
                    }}
                  >
                    <Link className="remove-item-btn" to="#">
                      <i className="ri-user-unfollow-fill align-bottom me-2 text-muted"></i>{" "}
                      Deactivated
                    </Link>
                  </DropdownItem>
                )}
              </DropdownMenu>
            </UncontrolledDropdown>
          );
        },
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [handleUserClick, skipLimit, sortOrder]
  );

  const handleCloseDeleteModal = () => {
    setDeleteModal(false);
    setUser("");
  };

  return (
    <React.Fragment>
      {isLoading ? <SpinnerLoader /> : ""}
      <div className="page-content">
        <DeleteModal
          show={deleteModal}
          onDeleteClick={handleDeleteUser}
          onCloseClick={handleCloseDeleteModal}
          recordId={user?._id}
          deleteType={deleteType}
        />
        <Container fluid>
          <BreadCrumb title="Users" pageTitle={""} />
          <Row>
            <Col lg={12}>
              <Card id="usersList">
                <CardBody className="border border-dashed border-end-0 border-start-0">
                  <Row className="g-4 align-items-center">
                    <Col sm={3}>
                      <div className="search-box">
                        <Input
                          type="text"
                          className="form-control search"
                          placeholder="Search"
                          value={search}
                          onChange={(event) => {
                            handleSearchChange(event);
                          }}
                        />
                        <i className="ri-search-line search-icon"></i>
                      </div>
                    </Col>
                    <Col className="d-flex justify-content-end">
                      <div className="flex-shrink-0">
                        <div className="d-flex gap-1 flex-wrap">
                          <button
                            type="button"
                            className="btn add-btn"
                            id="create-btn"
                            onClick={() => {
                              setIsEdit(false);
                              toggle();
                            }}
                          >
                            <i className="ri-add-line align-bottom me-1"></i>{" "}
                            Add Users
                          </button>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </CardBody>
                <CardBody className="pt-3">
                  <div>
                    {usersList && usersList.length ? (
                      <TableContainer
                        columns={columns}
                        data={usersList || []}
                        customPageSize={10}
                        className="custom-header-css"
                        divClass="table-responsive table-card"
                        tableClass="align-middle"
                        theadClass="table-light"
                        totalCount={totalCountOfPage}
                        handleNextPage={handleNextPage}
                        handlePreviousPage={handlePreviousPage}
                        handlePageChange={handlePageChange}
                        pageIndex={userPageIndex}
                      />
                    ) : (
                      <span>No User Found</span>
                    )}
                  </div>

                  <Modal id="showModal" isOpen={modal} toggle={toggle} centered>
                    <ModalHeader className="bg-light p-3" toggle={toggle}>
                      {!!isEdit ? "Edit User" : "Add User"}
                    </ModalHeader>
                    <Form
                      className="tablelist-form"
                      onSubmit={(e) => {
                        e.preventDefault();
                        validation.handleSubmit();
                        return false;
                      }}
                    >
                      <ModalBody>
                        <Input type="hidden" id="id-field" />
                        <Row className="g-3">
                          <Col lg={12}>
                            <div>
                              <Label
                                htmlFor="name-field"
                                className="form-label"
                              >
                                First Name *
                              </Label>
                              <Input
                                name="firstName"
                                id="customername-field"
                                className="form-control"
                                placeholder="Enter First Name"
                                type="text"
                                validate={{
                                  required: { value: true },
                                }}
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.firstName || ""}
                                invalid={
                                  validation.touched.firstName &&
                                  validation.errors.firstName
                                    ? true
                                    : false
                                }
                              />
                              {validation.touched.firstName &&
                              validation.errors.firstName ? (
                                <FormFeedback type="invalid">
                                  {validation.errors.firstName}
                                </FormFeedback>
                              ) : null}
                            </div>
                          </Col>
                          <Col lg={12}>
                            <div>
                              <Label
                                htmlFor="name-field"
                                className="form-label"
                              >
                                Last Name *
                              </Label>
                              <Input
                                name="lastName"
                                id="customername-field"
                                className="form-control"
                                placeholder="Enter Last Name"
                                type="text"
                                validate={{
                                  required: { value: true },
                                }}
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.lastName || ""}
                                invalid={
                                  validation.touched.lastName &&
                                  validation.errors.lastName
                                    ? true
                                    : false
                                }
                              />
                              {validation.touched.lastName &&
                              validation.errors.lastName ? (
                                <FormFeedback type="invalid">
                                  {validation.errors.lastName}
                                </FormFeedback>
                              ) : null}
                            </div>
                          </Col>
                          <Col lg={6}>
                            <div>
                              <Label
                                htmlFor="leads_score-field"
                                className="form-label"
                              >
                                Email *
                              </Label>
                              <Input
                                name="email"
                                id="company_name-field"
                                className="form-control"
                                placeholder="Enter Email"
                                type="text"
                                disabled={isEdit}
                                validate={{
                                  required: { value: true },
                                }}
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.email || ""}
                                invalid={
                                  validation.touched.email &&
                                  validation.errors.email
                                    ? true
                                    : false
                                }
                              />
                              {validation.touched.email &&
                              validation.errors.email ? (
                                <FormFeedback type="invalid">
                                  {validation.errors.email}
                                </FormFeedback>
                              ) : null}
                            </div>
                          </Col>
                          <Col lg={6}>
                            <div>
                              <Label
                                htmlFor="phone-field"
                                className="form-label"
                              >
                                Phone Number
                              </Label>
                              <Input
                                name="phoneNumber"
                                id="phone-field"
                                className="form-control"
                                placeholder="Enter Phone Number"
                                type="text"
                                validate={{
                                  required: { value: true },
                                }}
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.phoneNumber || null}
                                invalid={
                                  validation.touched.phoneNumber &&
                                  validation.errors.phoneNumber
                                    ? true
                                    : false
                                }
                              />
                              {validation.touched.phoneNumber &&
                              validation.errors.phoneNumber ? (
                                <FormFeedback type="invalid">
                                  {validation.errors.phoneNumber}
                                </FormFeedback>
                              ) : null}
                            </div>
                          </Col>
                        </Row>
                      </ModalBody>
                      <ModalFooter>
                        <div className="hstack gap-2 justify-content-end">
                          <button
                            type="button"
                            className="btn close-btn"
                            onClick={toggle}
                          >
                            {" "}
                            Close{" "}
                          </button>
                          <button
                            type="submit"
                            className="btn add-btn"
                            id="add-btn"
                          >
                            {" "}
                            {!!isEdit ? "Update" : "Add User"}{" "}
                          </button>
                        </div>
                      </ModalFooter>
                    </Form>
                  </Modal>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default User;
