import "./WaveSection.scss";
import WavePanelIcon from "../../../assets/images/New/panel.svg";
import CloseIcon from "../../../assets/images/New/close.svg";
import { TAB_TYPE } from "../../../Constants/Common/Data";
import useTabStore from "../../../Store/TabStore/TabStore";
import useVideoRefStore from "../../../Store/VideoStore/VideoStore";
import { useEffect, useMemo, useRef, useState } from "react";
import WaveSurfer from "wavesurfer.js";
import useTranscriptionStore from "../../../Store/TranscriptionStore/TranscriptionStore";
import TimelinePlugin from "wavesurfer.js/dist/plugins/timeline.esm.js";
import RegionsPlugin from "wavesurfer.js/dist/plugins/regions";
import { CustomTimelineOptions } from "../../../Common/interface/types";
import { getAudioWaveFormData } from "../../../ApiService/ApiCall";

const WaveSection = () => {
  const waveSurferRef = useRef<HTMLDivElement | any>(null);
  const topTimelineRef = useRef<HTMLDivElement | null>(null);
  const bottomTimelineRef = useRef<HTMLDivElement | null>(null);

  const [waveState, setWaveState] = useState<any>("");
  const [waveStatus, setWaveStatus] = useState(true);
  const [audioData, setAudioData] = useState<any>(null);

  const { toggleActiveItem } = useTabStore((state: any) => ({
    toggleActiveItem: state.toggleActiveItem,
  }));

  const { selectedVideoId, videoLoad, leftSubtitleData, setLoading } =
    useTranscriptionStore((state: any) => ({
      selectedVideoId: state.selectedVideoId,
      videoLoad: state.videoLoad,
      leftSubtitleData: state.leftSubtitleData,
      setLoading: state.setLoading,
    }));

  const { playerRef, setWaveSufferRef, setWaveSufferRegionRef } =
    useVideoRefStore((state: any) => ({
      playerRef: state.playerRef,
      setWaveSufferRef: state.setWaveSufferRef,
      setWaveSufferRegionRef: state.setWaveSufferRegionRef,
    }));

  const topTimelineOptions: CustomTimelineOptions = {
    height: 20,
    insertPosition: "beforebegin" as InsertPosition,
    timeInterval: 0.2,
    primaryLabelInterval: 5,
    secondaryLabelInterval: 1,
    style: {
      fontSize: "14px",
      color: "#ffffff",
    },
  };

  const bottomTimelineOptions: CustomTimelineOptions = {
    height: 10,
    timeInterval: 0.2,
    primaryLabelInterval: 10,
    secondaryLabelInterval: 1,
    style: {
      fontSize: "9px",
      color: "#ffffff",
    },
  };

  const callApi = async () => {
    await getAudioData();
  };

  const getAudioData = async () => {
    try {
      setLoading(true);
      const res = await getAudioWaveFormData({ resourceId: selectedVideoId });
      setAudioData(res);
      setLoading(false);
    } catch (err) {
      setLoading(false);
      console.log(err);
    }
  };

  useEffect(() => {
    callApi();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedVideoId]);

  useEffect(() => {
    const player = playerRef;
    if (player && waveState) {
      player.on("timeupdate", () => {
        waveState.seekTo(player.currentTime() / player.duration());
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [playerRef]);

  const createWaveForm = () => {
    const player = playerRef;
    const waveSurfer: any = WaveSurfer.create({
      container: waveSurferRef.current,
      waveColor: "#323232",
      progressColor: "#ac4bbe",
      normalize: false,
      autoScroll: true,
      minPxPerSec: 120,
      plugins: [
        TimelinePlugin.create(topTimelineOptions),
        TimelinePlugin.create(bottomTimelineOptions),
      ],
    });
    setWaveSufferRef(waveSurfer);
    setWaveState(waveSurfer);
    player.on("timeupdate", () => {
      waveSurfer.seekTo(player.currentTime() / player.duration());
    });
    const wsRegions = waveSurfer.registerPlugin(RegionsPlugin.create());
    setWaveSufferRegionRef(wsRegions);
    if (
      audioData &&
      audioData?.resourceDetail?.path &&
      audioData?.audioJson &&
      audioData?.audioJson?.data
    ) {
      waveSurfer
        .load(audioData?.resourceDetail?.path, [audioData?.audioJson?.data])
        .catch((error: any) => {
          console.error("Error loading audio file:", error);
        });
    } else if (audioData && audioData?.resourceDetail?.path) {
      waveSurfer.load(audioData?.resourceDetail?.path).catch((error: any) => {
        console.error("Error loading audio file:", error);
      });
    }

    player.on("waveReady", () => {
      // console.log('waveform is ready!');
    });

    waveSurfer.on("click", (progress: any) => {
      const newTime = progress * player.duration();
      player.currentTime(newTime);
    });

    waveSurfer.on("ready", () => {
      console.log("waveform is ready!");
      setWaveStatus(false);
      const existingRegions = wsRegions.getRegions();
      if (existingRegions && existingRegions.length > 0) {
        wsRegions.clearRegions();
      }
      addSubtitle(wsRegions);
    });

    waveSurfer.on("timeupdate", (progress: any) => {
      // setDuration(progress);
    });
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const memoizedCreateWaveForm = useMemo(() => createWaveForm, []);

  useEffect(() => {
    if (
      playerRef &&
      audioData &&
      !playerRef?.isDisposed_ &&
      waveSurferRef &&
      waveSurferRef.current
    ) {
      createWaveForm();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [playerRef, audioData, memoizedCreateWaveForm]);

  const addSubtitle = (wsRegions: any) => {
    const subtitles = leftSubtitleData.segments;

    if (subtitles && subtitles.length > 0) {
      subtitles.forEach((subtitle: any, index: number) => {
        const start = subtitle.start;
        const end = subtitle.end;
        let content = subtitle.text;

        wsRegions.addRegion({
          start,
          end,
          content,
          drag: false,
          color: "#4a4d4d57",
          attributes: {
            class: `wavesurfer-region important region-${index}`,
            'div[part="region-content"]': `region-content-${index}`,
          },
          style: {
            textAlign: "center !important",
            marginTop: "50px !important",
          },
        });
      });
    }
  };

  return (
    <div className="wave-section">
      <div className="header">
        <div>
          <img src={WavePanelIcon} alt="wave" />
        </div>
        <div
          className="close-btn"
          onClick={() => toggleActiveItem(TAB_TYPE.WAVE)}
        >
          <img src={CloseIcon} alt="close" />
        </div>
      </div>
      <div>
        {waveStatus && <div className="loader">Generating...</div>}
        {waveSurferRef != null && videoLoad && (
          <div className="wave-form-outer">
            <div className="top-timeline">
              <div className="wave-timeline" ref={topTimelineRef} />
            </div>
            <div className="wave-form" ref={waveSurferRef} />
            <div className="bottom-timeline">
              <div className="wave-timeline" ref={bottomTimelineRef} />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default WaveSection;
