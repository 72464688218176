import React, { useEffect } from "react";
import { Link, useMatch, useNavigate } from "react-router-dom";

//import images
import logoSm from "../assets/images/logo-sm.png";
import logoDark from "../assets/images/logo-dark.png";
import logoLight from "../assets/images/logo-light.png";

import useLayoutStore from "../Store/layouts/layouts";
import useUserStore from "../Store/UserStore/UserStore";
import SpinnerLoader from "../Components/Common/Spinner/Spinner";
import NewHeader from "../Components/New/Header/Header";
import Profile from "../Components/Common/Profile/Profile";
import { customSideBarShow } from "../Constants/layout";
import { getCurrentUser } from "../ApiService/ApiCall";
import Download from "../Components/New/Header/Download/Download";
import { MESSAGE } from "../Constants/Messages";
import Toast from "../Common/helper/toasterService";

const Header = ({ headerClass }: any) => {
  const { setCurrentUser, setLoading, currentUser, isLoading } = useUserStore(
    (state: any) => ({
      setCurrentUser: state.setCurrentUser,
      setLoading: state.setLoading,
      currentUser: state.currentUser,
      isLoading: state.isLoading,
    })
  );

  const { sidebarBarShow, changeSidebarShow } = useLayoutStore(
    (state: any) => ({
      sidebarBarShow: state.sidebarBarShow,
      changeSidebarShow: state.changeSidebarShow,
    })
  );

  const isMatched = useMatch("/dashboard");
  const navigate = useNavigate();

  useEffect(() => {
    callApi();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const callApi = async () => {
    setLoading(true);
    await getCurrentUserApi();
    setLoading(false);
  };

  const getCurrentUserApi = async () => {
    try {
      setLoading(true);
      const res: any = await getCurrentUser({});
      setCurrentUser(res);
      setLoading(false);
    } catch (error: any) {
      if (error?.statusCode === 401 || error?.statusCode === 500) {
        Toast.showError(MESSAGE.ERROR.accessDenied);
        localStorage.clear();
        navigate("/login");
      }
      setLoading(false);
    }
  };

  useEffect(() => {
    if (isMatched) {
      changeSidebarShow(customSideBarShow.HIDDEN);
    } else {
      changeSidebarShow(customSideBarShow.SHOW);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isMatched]);

  const changeSidebar = () => {
    if (sidebarBarShow === customSideBarShow.SHOW) {
      changeSidebarShow(customSideBarShow.HIDDEN);
    } else {
      changeSidebarShow(customSideBarShow.SHOW);
    }
  };

  const toogleMenuBtn = () => {
    changeSidebar();
  };

  return (
    <React.Fragment>
      {isLoading ? <SpinnerLoader /> : ""}
      <header
        id={`page-topbar`}
        className={`${headerClass} ${
          sidebarBarShow === customSideBarShow.SHOW ? "show" : ""
        }`}
      >
        <div className="layout-width">
          <div className="navbar-header">
            <div className="d-flex">
              <div className="navbar-brand-box horizontal-logo">
                <Link to="/" className="logo logo-dark">
                  <span className="logo-sm">
                    <img src={logoSm} alt="" height="22" />
                  </span>
                  <span className="logo-lg">
                    <img src={logoDark} alt="" height="17" />
                  </span>
                </Link>

                <Link to="/" className="logo logo-light">
                  <span className="logo-sm">
                    <img src={logoSm} alt="" height="22" />
                  </span>
                  <span className="logo-lg">
                    <img src={logoLight} alt="" height="17" />
                  </span>
                </Link>
              </div>
              <button
                onClick={toogleMenuBtn}
                type="button"
                className="btn btn-sm px-3 fs-16 header-item vertical-menu-btn topnav-hamburger"
                id="topnav-hamburger-icon"
              >
                <span className="hamburger-icon">
                  <span></span>
                  <span></span>
                  <span></span>
                </span>
              </button>
            </div>
            {isMatched ? <NewHeader /> : null}
            <div className="d-flex align-items-center">
              <Download />
              <Profile currentUser={currentUser} />
            </div>
          </div>
        </div>
      </header>
    </React.Fragment>
  );
};

export default Header;
