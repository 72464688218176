import { Modal, ModalBody } from "reactstrap";
import "./UnSavedModal.scss";
import { UnSavedModalProps } from "../../../../../Common/interface/types";

const UnSavedModal = (props: UnSavedModalProps) => {
  const {
    show,
    handleClose,
    handleSubmit,
    icon,
    title,
    description,
    actionType,
  } = props;
  return (
    <Modal
      className="un-saved-modal-popup"
      fade={true}
      isOpen={show}
      toggle={handleClose}
      centered={true}
      size="md"
    >
      <ModalBody>
        <div className="un-saved-modal-container">
          <div className="header">
            <img src={icon} alt="icon" />
          </div>
          <div className="content">
            <div className="title">{title}</div>
            <div className="description">{description}</div>
          </div>
          <div className="action">
            <div className="submit-btn" onClick={handleSubmit}>
              {actionType}
            </div>
            <div className="cancel-btn" onClick={handleClose}>
              Cancel
            </div>
          </div>
        </div>
      </ModalBody>
    </Modal>
  );
};
export default UnSavedModal;
