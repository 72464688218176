import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { getInstance, init } from '../supabaseClient';
import { Session } from '@supabase/supabase-js';
import axios from "axios";

const AuthProtected = (props: any) => {
  const navigate = useNavigate();

  const [, setSession] = useState<Session | null>(null);
  const [profile, setProfile] = useState(null)

  useEffect(() => {
    init()
    fetchProfile()
    if(!localStorage.getItem('API_VERSION')) {
      localStorage.setItem('API_VERSION','false')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  async function fetchProfile() {
    let profileData = null
    await getInstance().auth.getSession().then(({ data: { session } }: any) => {
      if (session) {
        profileData = session
        setSession(session)
        const token = JSON.stringify(session);
        const { access_token } = JSON.parse(token)
        axios.defaults.headers.common["Authorization"] = "Bearer " + access_token;
      }
      //     // eslint-disable-next-line react-hooks/exhaustive-deps
    });
    getInstance().auth.onAuthStateChange((_event: any, session: any) => {
      setSession(session);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    });
    if (!profileData) {
      navigate('/login')
    } else {
      setProfile(profileData)
    }
  }

  /*
    Navigate is un-auth access protected routes via url
    */
  if (!profile) {
    return (
      null
    );
  }

  return <>{props.children}</>;
};

export { AuthProtected };