import { create } from "zustand";
import { LivePermissionDefaultValue } from "../../Common/interface/defaultValue";

const PermissionStore = (set: any) => ({
  liveFieldPermission: LivePermissionDefaultValue,

  setLiveFieldPermission: (list: any) => {
    set(() => ({ liveFieldPermission: list }));
  },
});

const usePermissionStore = create(PermissionStore);

export default usePermissionStore;
