import { Navigate } from "react-router-dom";

//Dashboard
import User from "../Pages/User";
import FileUpload from "../Pages/FileUpload";

import Login from "../Pages/Login";
import UrlListing from "../Pages/UrlListing";
import NewDashboard from "../Pages/NewDashboard";

const authProtectedRoutes = [
  { path: "/dashboard", component: <NewDashboard /> },
  { path: "/index", component: <NewDashboard /> },
  { path: "/user", component: <User /> },
  { path: "/share-url", component: <UrlListing /> },
  { path: "*", component: <Navigate to="/dashboard" /> },
];

const publicRoutes = [
  { path: "/login", component: <Login /> },
  { path: "/upload-resource", component: <FileUpload /> },
];

export { authProtectedRoutes, publicRoutes };
