import { Modal, ModalBody, Spinner } from "reactstrap";
import Select from "react-select";
import { ReTranscribeModelProps } from "../../../../Common/interface/types";
import { LangListForWhisper } from "../../../../Constants/Common/LangListForWhisper";
import { useState } from "react";
import "./ReTranscribeModel.scss";
import CloseIcon from "../../../../assets/images/New/close.svg";
import { MESSAGE } from "../../../../Constants/Messages";
const ReTranscribeModel = (props: ReTranscribeModelProps) => {
  const { show, isApiCall, onCloseClick, onSubmitClick } = props;
  const [selectedLang, setSelectedLang] = useState("");
  const [addLangInputValue, setAddLangInputValue] = useState("");

  const handleAddLang = (selectedValue: any) => {
    setSelectedLang(selectedValue);
  };

  const handleLangInputChange = (inputValue: string) => {
    if (inputValue.length > 10) {
      setAddLangInputValue(inputValue.substr(0, 10));
    } else {
      setAddLangInputValue(inputValue);
    }
  };

  const handleSubmit = () => {
    onSubmitClick(selectedLang);
  };

  return (
    <Modal
      fade={true}
      isOpen={show}
      className="re-transcribe-modal-outer"
      toggle={onCloseClick}
      centered={true}
    >
      <ModalBody
        className={`re-transcribe-modal ${isApiCall ? "loader-section" : ""}`}
      >
        <div className="close cursor-pointer" onClick={onCloseClick}>
          <img src={CloseIcon} alt="close" />
        </div>
        {!isApiCall && (
          <>
            <div className="header">
              <div className="title">Add a new subtitle</div>
              <div className="description">
                Choose a language in the list below
              </div>
            </div>
            <div className="lang-select-outer">
              <Select
                className="lang-select"
                onChange={handleAddLang}
                options={LangListForWhisper}
                maxMenuHeight={200}
                onInputChange={handleLangInputChange}
                inputValue={addLangInputValue}
                placeholder="Select Language"
              />
            </div>
            <div className="d-flex gap-2 justify-content-center mt-4 mb-2">
              <button
                type="button"
                className="btn custom-btn-close"
                data-bs-dismiss="modal"
                onClick={onCloseClick}
              >
                Cancel
              </button>
              <button
                type="button"
                className="btn custom-btn-submit"
                id="delete-record"
                onClick={handleSubmit}
              >
                Okay
              </button>
            </div>
          </>
        )}
        {isApiCall && (
          <>
            <div className="header">
              <div className="title">Subtitle is changing</div>
            </div>
            <div className="progress-section">
              <Spinner />
            </div>
            <div className="info-box">{MESSAGE.CONTENT.closeWindow}</div>
            <div className="d-flex gap-2 justify-content-center mt-4 mb-2">
              <button
                type="button"
                className="btn custom-btn-close"
                data-bs-dismiss="modal"
                onClick={onCloseClick}
              >
                Cancel
              </button>
              <button
                type="button"
                className="btn custom-btn-submit"
                id="delete-record"
                onClick={onCloseClick}
              >
                Okay
              </button>
            </div>
          </>
        )}
      </ModalBody>
    </Modal>
  );
};

export default ReTranscribeModel;
