import { useState } from "react";
import "./DropDown.scss";
import { getLangName } from "../../../../../Common/helper/helper";
import { DropDown, DropUp } from "../../../../Common/Icon";
import { ADD_NEW_LANG } from "../../../../../Constants/Common/Data";

const CustomDropDown = (props: any) => {
  const {
    placeholder,
    options,
    selectedValue,
    defaultLanguageId,
    handleChangeLanguage,
    videoLoadStatus,
    isDefaultSelected,
  } = props;
  const [isOpen, setIsOpen] = useState(false);

  const handleLang = (value: string) => {
    handleChangeLanguage(value);
    setIsOpen(!isOpen);
  };

  const handleNewLang = () => {
    handleChangeLanguage(ADD_NEW_LANG);
    setIsOpen(!isOpen);
  };

  const handleOpen = () => {
    if (videoLoadStatus) {
      setIsOpen(!isOpen);
    }
  };

  return (
    <div className={`custom-select-outer`}>
      <div
        className={`custom-select ${isOpen ? "open" : ""} ${
          videoLoadStatus ? "" : "disable"
        }`}
        onClick={handleOpen}
      >
        <div>
          {selectedValue
            ? getLangName(selectedValue?.toLowerCase())
            : placeholder}
          {isDefaultSelected ? "(ORG)" : ""}
        </div>
        {isOpen ? <DropUp /> : <DropDown />}
      </div>
      {isOpen && (
        <div className="options">
          {options &&
            options.length > 0 &&
            options.map((_option: any) => {
              return (
                <div
                  onClick={() => handleLang(_option?.value)}
                  key={_option?.value}
                  className="item"
                >
                  {_option?.label}
                  {defaultLanguageId === _option?.value ? "(ORG)" : ""}
                </div>
              );
            })}
          {options && options.length > 0 && (
            <div onClick={() => handleNewLang()} className="item">
              + add new
            </div>
          )}
        </div>
      )}
    </div>
  );
};
export default CustomDropDown;
