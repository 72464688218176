import { create } from "zustand";

const downloadStore = (set: any) => ({
  downloadFiles: [],
  showDownload: false,
  downloadStatus: false,
  downloadType: null,

  addFileToDownload: (file: any[]) =>
    set((state: any) => ({
      downloadFiles: [...state.downloadFiles, ...file],
    })),

  removeFileFromDownload: (file: any[]) =>
    set((state: any) => ({
      downloadFiles: file,
    })),

  setShowDownload: (value: boolean) =>
    set((state: any) => ({
      showDownload: value,
    })),

  setDownloadType: (value: string) =>
    set((state: any) => ({
      downloadType: value,
    })),

  setDownloadStatus: (value: boolean) =>
    set((state: any) => ({
      downloadStatus: value,
    })),
});

const useFileDownloadStore = create(downloadStore);

export default useFileDownloadStore;
