import { useEffect } from "react";
import useLayoutStore from "../Store/layouts/layouts";
import { useNavigate } from "react-router-dom";
import { COOKIES_KEY } from "../Constants/Environment";

const NonAuthLayout = ({ children }: any) => {
  const { layoutModeType } = useLayoutStore((state: any) => ({
    layoutModeType: state.layoutModeType,
  }));
  const navigate = useNavigate();

  useEffect(() => {
    if (layoutModeType === "dark") {
      document.body.setAttribute("data-layout-mode", "dark");
    } else {
      document.body.setAttribute("data-layout-mode", "light");
    }
    fetchToken();
    return () => {
      document.body.removeAttribute("data-layout-mode");
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [layoutModeType]);

  async function fetchToken() {
    const authTokenString = localStorage.getItem(COOKIES_KEY);

    if (authTokenString) {
      navigate("/dashboard");
    }
  }

  return <div>{children}</div>;
};

export default NonAuthLayout;
