import React, { useEffect, useState } from "react";
import { Offcanvas, OffcanvasHeader, OffcanvasBody } from "reactstrap";
import withRouter from "./withRouter";

import Layout1 from "../../assets/images/LayoutScreen/Layout1.png";
import Layout2 from "../../assets/images/LayoutScreen/Layout2.png";
import Layout3 from "../../assets/images/LayoutScreen/Layout3.png";
import Layout4 from "../../assets/images/LayoutScreen/Layout4.png";
import Layout5 from "../../assets/images/LayoutScreen/Layout5.png";

//SimpleBar
import SimpleBar from "simplebar-react";
import { LAYOUT_TYPES, TAB_TYPE } from "../../Constants/Common/Data";
import useTranscriptionStore from "../../Store/TranscriptionStore/TranscriptionStore";
import { postUpdateUserDefaultSetting } from "../../ApiService/ApiCall";
import useUserStore from "../../Store/UserStore/UserStore";
import { getValueFromLabel } from "../../Common/helper/helper";
import useTabStore from "../../Store/TabStore/TabStore";
const RightSidebar = (props: any) => {
  const [show] = useState<boolean>(false);
  const [selectedLayout, setSelectedLayout] = useState({
    value: LAYOUT_TYPES.LAYOUT1.value,
    label: LAYOUT_TYPES.LAYOUT1.label,
  });

  const { setActiveItem } = useTabStore((state: any) => ({
    setActiveItem: state.setActiveItem,
  }));

  const { setLoading } = useTranscriptionStore((state: any) => ({
    setLoading: state.setLoading,
  }));

  const { currentUser } = useUserStore((state: any) => ({
    currentUser: state.currentUser,
  }));

  useEffect(() => {
    const tabSetting = currentUser?.userDefaultSetting?.tabSetting;
    if (tabSetting) {
      setSelectedLayout({
        label: tabSetting,
        value: getValueFromLabel(tabSetting) || [],
      });
    }
  }, [currentUser]);

  useEffect(() => {
    const sidebarColorDark = document.getElementById(
      "sidebar-color-dark"
    ) as HTMLInputElement;
    const sidebarColorLight = document.getElementById(
      "sidebar-color-light"
    ) as HTMLInputElement;

    if (show && sidebarColorDark && sidebarColorLight) {
      sidebarColorDark.checked = false;
      sidebarColorLight.checked = false;
    }
  }, [show]);

  // open offcanvas
  const [open, setOpen] = useState<boolean>(false);
  const toggleLeftCanvas = () => {
    setOpen(!open);
  };

  window.onscroll = function () {
    scrollFunction();
  };

  const scrollFunction = () => {
    const element = document.getElementById("back-to-top");
    if (element) {
      if (
        document.body.scrollTop > 100 ||
        document.documentElement.scrollTop > 100
      ) {
        element.style.display = "block";
      } else {
        element.style.display = "none";
      }
    }
  };

  const changeLayout = (value: any[], label: number) => {
    setSelectedLayout({
      value: value,
      label: label,
    });
  };

  const handleSubmit = async () => {
    setLoading(true);
    await callUpdateTabApi(selectedLayout.label);
    setTheme(selectedLayout.label);
    setLoading(false);
    setOpen(false);
  };

  const callUpdateTabApi = async (layout: number) => {
    try {
      await postUpdateUserDefaultSetting({
        tabSetting: layout,
      });
    } catch (err) {
      console.log(err);
    }
  };

  const handleReset = async () => {
    setSelectedLayout({
      value: LAYOUT_TYPES.LAYOUT1.value,
      label: LAYOUT_TYPES.LAYOUT1.label,
    });
    setLoading(true);
    await callUpdateTabApi(LAYOUT_TYPES.LAYOUT1.label);
    setLoading(false);
    setOpen(false);
  };

  const setTheme = (label: number) => {
    const value = getValueFromLabel(label);
    setActiveItem(value);
    const values = getValueFromLabel(label);
    if (
      values?.includes(TAB_TYPE.WAVE) &&
      currentUser?.configSetting &&
      currentUser?.configSetting?.isWaveformAllow
    ) {
      setActiveItem(values);
    } else {
      const tempValue = values?.filter(
        (value: string) => value !== TAB_TYPE.WAVE
      );
      setActiveItem(tempValue);
    }
  };

  return (
    <React.Fragment>
      <div>
        <div className="customizer-setting d-none d-md-block">
          <div
            onClick={toggleLeftCanvas}
            className="btn-info rounded-pill shadow-lg btn btn-icon btn-lg p-2 rounded-pill"
          >
            <i className="mdi mdi-spin mdi-cog-outline fs-22"></i>
          </div>
        </div>
        <Offcanvas
          isOpen={open}
          toggle={toggleLeftCanvas}
          direction="end"
          className="offcanvas-end border-0"
        >
          <OffcanvasHeader
            className="d-flex align-items-center bg-primary bg-gradient p-3 offcanvas-header-dark"
            toggle={toggleLeftCanvas}
          >
            <span className="m-0 me-2 text-white">Layout Customizer</span>
          </OffcanvasHeader>
          <OffcanvasBody className="p-0">
            <SimpleBar className="h-100">
              <div className="p-4">
                <h6 className="mb-0 fw-semibold text-uppercase">Layout</h6>
                <p className="text-muted">Choose your layout</p>
                <div className="row gy-3">
                  <div className="col-4">
                    <div className="form-check card-radio">
                      <input
                        id="customizer-layout01"
                        name="data-layout"
                        type="radio"
                        value={LAYOUT_TYPES.LAYOUT1.label}
                        checked={
                          selectedLayout.label === LAYOUT_TYPES.LAYOUT1.label
                        }
                        onChange={(e) => {
                          changeLayout(
                            LAYOUT_TYPES.LAYOUT1.value,
                            LAYOUT_TYPES.LAYOUT1.label
                          );
                        }}
                        className="form-check-input"
                      />
                      <label
                        className="form-check-label p-0 avatar-md w-100"
                        htmlFor="customizer-layout01"
                      >
                        <img
                          width="100px"
                          height="70px"
                          src={Layout1}
                          alt="layout-1"
                        />
                      </label>
                    </div>
                    <h5 className="fs-13 text-center mt-2">Layout 1</h5>
                  </div>
                  <div className="col-4">
                    <div className="form-check card-radio">
                      <input
                        id="customizer-layout02"
                        name="data-layout"
                        type="radio"
                        value={LAYOUT_TYPES.LAYOUT2.label}
                        checked={
                          selectedLayout.label === LAYOUT_TYPES.LAYOUT2.label
                        }
                        onChange={(e) => {
                          changeLayout(
                            LAYOUT_TYPES.LAYOUT2.value,
                            LAYOUT_TYPES.LAYOUT2.label
                          );
                        }}
                        className="form-check-input"
                      />
                      <label
                        className="form-check-label p-0 avatar-md w-100"
                        htmlFor="customizer-layout02"
                      >
                        <img
                          width="100px"
                          height="70px"
                          src={Layout2}
                          alt="layout-1"
                        />
                      </label>
                    </div>
                    <h5 className="fs-13 text-center mt-2">Layout 2</h5>
                  </div>
                  <div className="col-4">
                    <div className="form-check card-radio">
                      <input
                        id="customizer-layout03"
                        name="data-layout"
                        type="radio"
                        value={LAYOUT_TYPES.LAYOUT3.label}
                        checked={
                          selectedLayout.label === LAYOUT_TYPES.LAYOUT3.label
                        }
                        onChange={(e) => {
                          changeLayout(
                            LAYOUT_TYPES.LAYOUT3.value,
                            LAYOUT_TYPES.LAYOUT3.label
                          );
                        }}
                        className="form-check-input"
                      />
                      <label
                        className="form-check-label p-0 avatar-md w-100"
                        htmlFor="customizer-layout03"
                      >
                        <img
                          width="100px"
                          height="70px"
                          src={Layout3}
                          alt="layout-1"
                        />
                      </label>
                    </div>
                    <h5 className="fs-13 text-center mt-2">Layout 3</h5>
                  </div>
                  <div className="col-4">
                    <div className="form-check card-radio">
                      <input
                        id="customizer-layout04"
                        name="data-layout"
                        type="radio"
                        className="form-check-input"
                        value={LAYOUT_TYPES.LAYOUT4.label}
                        checked={
                          selectedLayout.label === LAYOUT_TYPES.LAYOUT4.label
                        }
                        onChange={(e) => {
                          changeLayout(
                            LAYOUT_TYPES.LAYOUT4.value,
                            LAYOUT_TYPES.LAYOUT4.label
                          );
                        }}
                      />
                      <label
                        className="form-check-label p-0 avatar-md w-100"
                        htmlFor="customizer-layout04"
                      >
                        <img
                          width="100px"
                          height="70px"
                          src={Layout4}
                          alt="layout-1"
                        />
                      </label>
                    </div>
                    <h5 className="fs-13 text-center mt-2">Layout 4</h5>
                  </div>
                  <div className="col-4">
                    <div className="form-check card-radio">
                      <input
                        id="customizer-layout05"
                        name="data-layout"
                        type="radio"
                        className="form-check-input"
                        value={LAYOUT_TYPES.LAYOUT5.label}
                        checked={
                          selectedLayout.label === LAYOUT_TYPES.LAYOUT5.label
                        }
                        onChange={(e) => {
                          changeLayout(
                            LAYOUT_TYPES.LAYOUT5.value,
                            LAYOUT_TYPES.LAYOUT5.label
                          );
                        }}
                      />
                      <label
                        className="form-check-label p-0 avatar-md w-100"
                        htmlFor="customizer-layout05"
                      >
                        <img
                          width="100px"
                          height="70px"
                          src={Layout5}
                          alt="layout-1"
                        />
                      </label>
                    </div>
                    <h5 className="fs-13 text-center mt-2">Layout 5</h5>
                  </div>
                </div>
              </div>
            </SimpleBar>
          </OffcanvasBody>
          <div className="offcanvas-footer border-top p-3 text-center">
            <div className="row">
              <div className="col-6">
                <button
                  type="button"
                  className="btn btn-light w-100"
                  id="reset-layout"
                  onClick={handleReset}
                >
                  Reset
                </button>
              </div>
              <div className="col-6">
                <button
                  type="button"
                  className="btn btn-primary w-100"
                  id="submit-layout"
                  onClick={handleSubmit}
                >
                  Submit
                </button>
              </div>
            </div>
          </div>
        </Offcanvas>
      </div>
    </React.Fragment>
  );
};

export default withRouter(RightSidebar);
