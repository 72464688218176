import React from "react";

const ButtonComponent = (props: any) => {
  const { onClick, icon, title = "", disable = false, className } = props;

  const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
    if (!disable && onClick) {
      onClick(event);
    }
  };

  return (
    <div
      {...props}
      className={`btn-style ${disable === true ? "disable" : ""} ${className}`}
      style={{
        ...props.style,
      }}
      onClick={handleClick}
    >
      {title ? title : <img src={icon} alt="add" />}
    </div>
  );
};

export default ButtonComponent;
