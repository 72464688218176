export const PERMISSION_CONFIG = {
  LIVE: {
    MODEL: "liveVideoResources",
    CREATE: "liveVideoResources.create",
    LISTING: "liveVideoResources.listing",
    AUTO_DETECT: "liveVideoResources.auto.detect",
    UPDATE: "liveVideoResources.update",
    ARCHIVE: "liveVideoResources.archive",
    CHANGE_STATUS: "liveVideoResources.change.status",
    CHECK_STREAM: "liveVideoResources.check.stream.status",
    START_STREAM: "liveVideoResources.start.stream",
    GENERATE_SRT: "liveVideoResources.generate.srt",
  },
};
