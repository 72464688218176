import './Spinner.css';
import { Spinner } from 'reactstrap';

const SpinnerLoader = () => {
  return (
    <div className='spinner-loader'>
      <Spinner color="secondary">
        Loading...
      </Spinner>
    </div>
  );
};

export default SpinnerLoader;
