import { create } from "zustand";
import { STREAM_TAB_TYPE } from "../../Constants/Common/Data";

const StreamStore = (set: any) => ({
  activeStreamItems: [STREAM_TAB_TYPE.LIVE_STREAM_LIST],
  streamVideoList: [],
  selectedVideoInfo: {},
  selectedVideoStatus: null,
  selectedVideoSubtitle: {},
  selectedLang: {},
  subtitleData: [],
  selectedVideoIds: [],
  totalCount: 0,
  pageCount: 1,
  selectedPage: 0,
  skipCount: 0,
  searchValue: "",
  liveVideoPlayerTime: 0,
  videoLoadStatus: false,
  infoMessage: "",
  infoModalStatus: false,
  pauseModalStatus: false,
  playerReference: null,
  isProgressBarClick: false,
  liveVideoList: [],
  isUpdateStreamList: false,

  toggleStreamActiveItem: (item: any) =>
    set((state: any) => {
      const isActive = state.activeStreamItems.includes(item);
      const updatedItems = isActive
        ? state.activeStreamItems.filter(
            (activeItem: any) => activeItem !== item
          )
        : [...state.activeStreamItems, item];
      return { activeStreamItems: updatedItems };
    }),

  setStreamActiveItem: (item: any[]) =>
    set((state: any) => {
      return { activeStreamItems: item };
    }),

  setStreamList: (dataList: any[]) => {
    set(() => ({ streamVideoList: dataList }));
  },

  setSelectedVideoInfo: (info: any) => {
    set((state: any) => ({
      selectedVideoInfo: info,
    }));
  },

  setSelectedVideoStatus: (status: any) => {
    set((state: any) => ({
      selectedVideoStatus: status,
    }));
  },

  setVideoLoadStatus: (status: any) => {
    set((state: any) => ({
      videoLoadStatus: status,
    }));
  },

  setSelectedVideoSubtitle: (info: any) => {
    set((state: any) => ({
      selectedVideoSubtitle: info,
    }));
  },
  setSubtitleData: (data: any[]) => {
    set(() => ({ subtitleData: data }));
  },
  setTotalCount: (count: number) =>
    set((state: any) => ({
      totalCount: count,
    })),
  setPageCount: (count: number) =>
    set((state: any) => ({
      pageCount: count,
    })),

  setSearchValue: (value: string) =>
    set((state: any) => ({
      searchValue: value,
    })),
  setSelectedPage: (page: number) =>
    set((state: any) => ({
      selectedPage: page,
    })),
  setSkipCount: (page: number) =>
    set((state: any) => ({
      skipCount: page,
    })),

  setSelectedVideoIds: (ids: []) =>
    set((state: any) => ({
      selectedVideoIds: ids,
    })),

  setLiveVideoPlayerTime: (time: any) => {
    set((state: any) => ({
      liveVideoPlayerTime: time,
    }));
  },

  setInfoModalStatus: (status: boolean) => {
    set((state: any) => ({
      infoModalStatus: status,
    }));
  },

  setPauseModalStatus: (status: boolean) => {
    set((state: any) => ({
      pauseModalStatus: status,
    }));
  },

  setInfoModalMessage: (msg: string) => {
    set((state: any) => ({
      infoMessage: msg,
    }));
  },

  setPlayerReference: (ref: any) => {
    set((state: any) => ({
      playerReference: ref,
    }));
  },

  setIsProgressBarClick: (value: boolean) => {
    set((state: any) => ({
      isProgressBarClick: value,
    }));
  },

  setLiveVideoList: (list: any[]) => {
    set((state: any) => ({
      liveVideoList: list,
    }));
  },

  setIsUpdateStreamList: (status: boolean) => {
    set((state: any) => ({
      isUpdateStreamList: status,
    }));
  },
});

const useStreamStore = create(StreamStore);

export default useStreamStore;
