import { NotAvailableProps } from '../../../Common/interface/types'
import './NotAvailable.scss'

const NotAvailable = (props: NotAvailableProps) => {
  const { msg, margin } = props;
  return (
    <div className={`not-available-outer-common ${margin ? 'margin' : ''}`}>
      <div className='heading'>{msg}</div>
    </div>
  )
}

export default NotAvailable