import "./UploadSection.scss";
import ArrowIcon from "../../../assets/images/New/arrow.svg";
import CloseIcon from "../../../assets/images/New/close.svg";
import UploadIcon from "../../../assets/images/New/Upload.svg";
import CameraIcon from "../../../assets/images/New/Camera.svg";
import { useState } from "react";
import { TAB_TYPE, UPLOAD_TAB_TYPE } from "../../../Constants/Common/Data";
import LinkList from "./LinkList/LinkList";
import {
  faLink,
  faServer,
  faArrowsRotate,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import useTabStore from "../../../Store/TabStore/TabStore";
import OperationList from "./OperationList/OperationList";

const UploadSection = () => {
  const [activeBtnStatus, setActiveBtnStatus] = useState(
    UPLOAD_TAB_TYPE.UPLOAD
  );
  const [updateOperationList, setUpdateOperationList] = useState(false);
  const handleBtnStatus = (type: number) => {
    setActiveBtnStatus(type);
  };

  const { toggleActiveItem } = useTabStore((state: any) => ({
    toggleActiveItem: state.toggleActiveItem,
  }));

  return (
    <div className="upload-section">
      <div className="header">
        <div>
          <img src={ArrowIcon} alt="arrow" />
        </div>
        <div
          className="close-btn"
          onClick={() => toggleActiveItem(TAB_TYPE.UPLOAD)}
        >
          <img src={CloseIcon} alt="close" />
        </div>
      </div>
      <div className="action">
        <div className="custom-btn" title="Upload">
          <img src={UploadIcon} alt="upload" />
        </div>
        <div className="custom-btn">
          <img src={CameraIcon} alt="camera" />
        </div>
        {activeBtnStatus === UPLOAD_TAB_TYPE.OPERATION && (
          <div
            className="custom-btn active"
            onClick={() => {
              setUpdateOperationList(!updateOperationList);
            }}
            title="Refresh"
          >
            <FontAwesomeIcon icon={faArrowsRotate} />
          </div>
        )}
        <div className="switch-btn-outer">
          <div
            className={`left ${!activeBtnStatus ? "active" : ""}`}
            onClick={() => handleBtnStatus(UPLOAD_TAB_TYPE.UPLOAD)}
          >
            <FontAwesomeIcon icon={faLink} style={{ marginRight: "5px" }} />
            Upload links
          </div>
          <div
            className={`right ${activeBtnStatus ? "active" : ""}`}
            onClick={() => handleBtnStatus(UPLOAD_TAB_TYPE.OPERATION)}
          >
            <FontAwesomeIcon icon={faServer} style={{ marginRight: "8px" }} />
            Operation
          </div>
        </div>
      </div>
      {activeBtnStatus === UPLOAD_TAB_TYPE.UPLOAD && <LinkList />}
      {activeBtnStatus === UPLOAD_TAB_TYPE.OPERATION && (
        <OperationList updateOperationListStatus={updateOperationList} />
      )}
    </div>
  );
};

export default UploadSection;
