import React, { useState } from "react";
import { Badge } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleInfo } from "@fortawesome/free-solid-svg-icons";
import { VideoFileProps } from "../../../../Common/interface/types";
import {
  checkFileNameLength,
  getExtension,
  getFileNameWithoutExtension,
  getLangName,
  secondsToMinutesAndHours,
  shortName,
} from "../../../../Common/helper/helper";
import SharedModalPopup from "../ShareModal/ShareModalPopup";
import { VIDEO_STATUS } from "../../../../Constants/Common/Data";
import CheckCircle from "../../../../assets/images/New/CheckCircle.svg";
import BlankCircle from "../../../../assets/images/New/blankCircle.svg";
import "./File.scss";
import dayjs from "dayjs";
import { MESSAGE } from "../../../../Constants/Messages";
import Toast from "../../../../Common/helper/toasterService";

const File: React.FC<VideoFileProps> = (props) => {
  const {
    id,
    title,
    size,
    date,
    user,
    data,
    status,
    selectedVideoId,
    selectedResourceId,
    isShared,
    handleVideoShare,
    handelVideoSelect,
    handleFileName,
  } = props;
  const [isHovered, setIsHovered] = useState(false);
  const [isSharedModalVisible, setIsSharedModalVisible] = useState(false);
  const [infoId, setInfoId] = useState("");
  const [selectedId, setSelectedId] = useState("");
  const [showTextBox, setShowTextBox] = useState(false);
  const [selectedTitle, setSelectedTitle] = useState("");
  const [selectedTitleExtension, setSelectedTitleExtension] = useState("");

  const handleMouseEnter = () => setIsHovered(true);
  const handleMouseLeave = () => setIsHovered(false);

  const handleInfoIconClick = (event: any, id: string) => {
    event.preventDefault();
    event.stopPropagation();
    setInfoId(id);
    setIsSharedModalVisible(true);
  };

  const handleRename = (event: any, id: string, title: string) => {
    event.stopPropagation();
    setSelectedTitle(getFileNameWithoutExtension(title));
    setSelectedTitleExtension("." + getExtension(title));
    setSelectedId(id);
    setShowTextBox(true);
  };

  const handleChangeFileName = () => {
    if (selectedTitle && selectedTitle.trim()) {
      if (checkFileNameLength(selectedTitle)) {
        handleFileName &&
          handleFileName(selectedId, selectedTitle + selectedTitleExtension);
      } else {
        Toast.showError(MESSAGE.ERROR.fileRenameError);
      }
    }
    setSelectedTitle("");
    setSelectedId("");
    setSelectedTitleExtension("");
    setShowTextBox(false);
    setIsHovered(false);
  };

  return (
    <>
      {!isHovered && (
        <div
          className={`file-main-outer ${
            selectedVideoId === id ? "active" : ""
          }`}
          key={id}
        >
          <div
            className="file"
            key={1}
            style={{ backgroundImage: `url(${data?.thumbnail})` }}
            onClick={() => handelVideoSelect(id)}
          >
            <div className="layer">
              <div className="heading">
                <div>
                  {status !== VIDEO_STATUS.PROCESSING &&
                    status !== VIDEO_STATUS.UPLOADED && (
                      <div
                        className="checkbox"
                        onClick={(event) => handleVideoShare(event, data)}
                      >
                        {selectedResourceId.indexOf(id) !== -1 ? (
                          <img src={CheckCircle} alt="check" />
                        ) : (
                          <img src={BlankCircle} alt="blank" />
                        )}
                      </div>
                    )}
                </div>
                <div>
                  {(status === VIDEO_STATUS.PROCESSING ||
                    status === VIDEO_STATUS.UPLOADED) && (
                    <Badge color="info" className="custom-badge" pill>
                      Newly Added
                    </Badge>
                  )}
                  {status === VIDEO_STATUS.NOT_PROCESSED && (
                    <Badge color="danger" className="custom-badge" pill>
                      Failed
                    </Badge>
                  )}
                  {status !== VIDEO_STATUS.PROCESSING && isShared && (
                    <Badge color="info" className="shared-badge" pill>
                      Shared
                    </Badge>
                  )}
                  <FontAwesomeIcon
                    icon={faCircleInfo}
                    onClick={(e) => handleInfoIconClick(e, id)}
                    className="mx-2"
                  />
                </div>
              </div>
              {data?.duration && (
                <div className="time">
                  {secondsToMinutesAndHours(data?.duration)}
                </div>
              )}
            </div>
          </div>
          {selectedId === id && showTextBox ? (
            <div className="file-details">
              <input
                type="text"
                autoFocus
                value={selectedTitle}
                className="file-details-input"
                onBlur={handleChangeFileName}
                onChange={(e) => setSelectedTitle(e.target.value)}
              />
              <i
                className="ri-save-line cursor-pointer"
                onClick={handleChangeFileName}
              ></i>
            </div>
          ) : (
            <div
              className="file-details"
              onMouseEnter={handleMouseEnter}
              onMouseLeave={handleMouseLeave}
              onClick={(e) => handleRename(e, id, title)}
            >
              <span>{shortName(title, 14)}</span>
            </div>
          )}
        </div>
      )}
      {isHovered && (
        <div
          className={`file-main-outer ${
            selectedVideoId === id ? "active" : ""
          }`}
          key={id}
        >
          <div
            className="details-section-outer"
            key={2}
            onClick={() => handelVideoSelect(id)}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
            style={{ backgroundImage: `url(${data?.thumbnail})` }}
          >
            <div className="details-section">
              <div className="text-check">
                <div>
                  {status !== VIDEO_STATUS.PROCESSING &&
                    status !== VIDEO_STATUS.UPLOADED && (
                      <div
                        className="checkbox"
                        onClick={(event) => handleVideoShare(event, data)}
                      >
                        {selectedResourceId.indexOf(id) !== -1 ? (
                          <img src={CheckCircle} alt="check" />
                        ) : (
                          <img src={BlankCircle} alt="blank" />
                        )}
                      </div>
                    )}
                </div>
                {size &&
                data?.defaultLanguage &&
                data?.defaultLanguage.length > 0 ? (
                  <div className="lang mx-4">
                    {data?.defaultLanguage[0]
                      ? getLangName(data?.defaultLanguage[0])
                      : ""}
                  </div>
                ) : (
                  <div className="text mx-4">
                    {status === VIDEO_STATUS.PROCESSING && "Processing"}
                    {status === VIDEO_STATUS.NOT_PROCESSED && "Failed"}
                  </div>
                )}
              </div>

              <div className="text">{shortName(user, 15)}</div>
              <div className="text">{dayjs(date).format("DD-MM-YYYY")}</div>
            </div>
          </div>
          {selectedId === id && showTextBox ? (
            <div className="file-details">
              <input
                type="text"
                autoFocus
                onBlur={handleChangeFileName}
                value={selectedTitle}
                className="file-details-input"
                onChange={(e) => setSelectedTitle(e.target.value)}
              />
              <i
                className="ri-save-line cursor-pointer"
                onClick={handleChangeFileName}
              ></i>
            </div>
          ) : (
            <div
              className="file-details"
              onMouseEnter={handleMouseEnter}
              onMouseLeave={handleMouseLeave}
              onClick={(e) => handleRename(e, id, title)}
            >
              <span>{shortName(title, 14)}</span>
            </div>
          )}
        </div>
      )}
      <SharedModalPopup
        modalStatus={isSharedModalVisible}
        handleModal={() => setIsSharedModalVisible(false)}
        resourceId={infoId}
      />
    </>
  );
};

export default File;
