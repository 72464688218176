import React from "react";
import _ from "lodash";

function Separator(props: any) {
  return (
    <div
      style={{
        marginTop: "-18.5px",
        marginLeft: "-0.5px",
        position: "absolute",
        height: "100%",
        transform: `rotate(${props.turns}turn)`,
      }}
    >
      <div style={props.style} />
    </div>
  );
}

function RadialSeparators(props: any) {
  const turns = 1 / props.count;
  return (
    <div> {/* Wrap in a parent element */}
      {_.range(props.count).map((index) => (
        <Separator key={index} turns={index * turns} style={props.style} />
      ))}
    </div>
  );
}

export default RadialSeparators;
