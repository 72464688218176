import React, { useEffect, useState } from "react";
import ModalLayout from "../../../../Common/ModalLayout";
import CheckIcon from "../../../../../assets/images/New/DeletePopupIcon.svg";
import ButtonComponent from "../../ButtonComponent";
import { LIVE_VIDEO_TYPE } from "../../../../../Constants/Common/Data";

const contentStyle = {
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  textAlign: "center",
};

const titleStyle = {
  fontWeight: "700",
  fontSize: "15px",
  width: "100%",
  wordWrap: "break-word" as "normal" | "break-word",
};

const btnOuterStyle = {
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  gap: "10px",
};

const timerStyle = {
  fontSize: "3rem",
};

const LiveTimerModal = (props: any) => {
  const {
    modalStatus,
    setModalStatus,
    title,
    videoType,
    timeRemaining,
    handleStart,
  } = props;

  const [seconds, setSeconds] = useState(0);

  useEffect(() => {
    setSeconds(timeRemaining);
  }, [timeRemaining]);

  useEffect(() => {
    if (seconds > 0) {
      const timerId = setTimeout(() => {
        setSeconds(seconds - 1000);
      }, 1000);

      // Cleanup the timeout if the component is unmounted or if seconds changes
      return () => clearTimeout(timerId);
    }
  }, [seconds]);

  const formatTime = (milliseconds: number) => {
    const totalSeconds = Math.floor(milliseconds / 1000);
    const minutes = Math.floor(totalSeconds / 60);
    const seconds = totalSeconds % 60;
    return `${minutes}:${seconds < 10 ? "0" : ""}${seconds}`;
  };

  return (
    <ModalLayout
      isShowing={modalStatus}
      style={{ background: "rgba(26, 26, 26, 1)" }}
      hideCloseButton
    >
      <div style={contentStyle as React.CSSProperties}>
        <img style={{ marginBottom: "10px" }} src={CheckIcon} alt="check" />
        <p style={titleStyle}>{`${
          videoType === LIVE_VIDEO_TYPE?.YOUTUBE ? "Youtube" : "SRT"
        } ${title} can be streamed ${seconds > 0 ? "in" : "now"}.`}</p>

        {seconds > 0 && <p style={timerStyle}> {formatTime(seconds)}</p>}

        <div style={btnOuterStyle}>
          <ButtonComponent
            onClick={() => setModalStatus(false)}
            title="Close"
            style={{
              backgroundColor: "white",
              color: "black",
              fontWeight: "700",
              width: "100px",
            }}
          />
          {seconds <= 0 && (
            <ButtonComponent
              onClick={handleStart}
              title="Start"
              style={{
                backgroundColor: "white",
                color: "black",
                fontWeight: "700",
                width: "100px",
              }}
            />
          )}
        </div>
      </div>
    </ModalLayout>
  );
};

export default LiveTimerModal;
