import { useEffect, useState } from "react";
// import CloseIcon from "../../../../assets/images/New/close.svg";
import CloudUpload from "../../../../assets/images/New/CloudUpload.gif";
import useFtpFileUploadStore from "../../../../Store/FtpFileUploadStore/FtpFileUploadStore";
import toast from "react-hot-toast";
import { postFtpFileUpload } from "../../../../ApiService/ApiCall";
import { MESSAGE } from "../../../../Constants/Messages";

const FileUploadToFtp = () => {
  const [showUploadSection, setShowUploadSection] = useState(false);
  const [uploadPercentage, setUploadPercentage] = useState(0);

  const {
    showFtpFileUpload,
    resourceIdForUpload,
    setCancelToken,
    setResourceIdForUpload,
    setShowFtpFileUpload,
  } = useFtpFileUploadStore((state: any) => ({
    showFtpFileUpload: state.showFtpFileUpload,
    resourceIdForUpload: state.resourceIdForUpload,
    setCancelToken: state.setCancelToken,
    setResourceIdForUpload: state.setResourceIdForUpload,
    setShowFtpFileUpload: state.setShowFtpFileUpload,
  }));

  useEffect(() => {
    if (
      showFtpFileUpload &&
      resourceIdForUpload &&
      resourceIdForUpload !== ""
    ) {
      setShowUploadSection(true);
      uploadFile();
    } else {
      setShowUploadSection(false);
      setResourceIdForUpload("");
      setUploadPercentage(0);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showFtpFileUpload]);

  useEffect(() => {
    let timer: any;
    if (showUploadSection) {
      timer = setInterval(() => {
        setUploadPercentage((prevState) => {
          if (prevState < 85) {
            return prevState + 1;
          } else {
            clearInterval(timer);
            return prevState;
          }
        });
      }, 1000);
    }
    return () => clearInterval(timer);
  }, [showUploadSection]);

  //   const handleCancel = (event: any) => {
  //     event.stopPropagation();
  //     // cancelToken?.abort();  // todo
  //     setShowFtpFileUpload(false);
  //     setResourceIdForUpload("");
  //   };

  const uploadFile = async () => {
    try {
      const res: any = await uploadFileToFTP(); // Waits for the API response
      if (res && res?.status) {
        setUploadPercentage(100);
        toast.success(MESSAGE?.SUCCESS?.fileUpload);
        setShowUploadSection(false);
        setShowFtpFileUpload(false);
        setResourceIdForUpload("");
        setUploadPercentage(0);
      }
    } catch (err: any) {
      console.log(err);
      toast.error(err.message || "Upload failed");
      setShowUploadSection(false);
      setShowFtpFileUpload(false);
      setResourceIdForUpload("");
      setUploadPercentage(0);
    }
  };

  const uploadFileToFTP = async () => {
    try {
      const controller = new AbortController();
      setCancelToken(controller);
      const payload = {
        resourceId: resourceIdForUpload,
      };
      const res: any = await postFtpFileUpload(payload, controller?.signal);
      return res;
    } catch (err: any) {
      console.log(err);
      toast.error(err?.msg || err?.message);
    }
  };

  return (
    <>
      {showUploadSection && (
        <div className="header-ftp-upload cursor-pointer">
          <img src={CloudUpload} className="upload-icon mx-1" alt="upload" />
          <span>Uploading {`(${uploadPercentage}%)`}</span>
          {/* <div>
            <img
              className="cursor-pointer mx-2"
              onClick={(e) => handleCancel(e)}
              src={CloseIcon}
              alt="cancel"
            />
          </div> */}
        </div>
      )}
    </>
  );
};

export default FileUploadToFtp;
