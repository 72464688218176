import { create } from 'zustand';

//constants
import {
  layoutTypes,
  leftSidebarTypes,
  layoutModeTypes,
  layoutWidthTypes,
  layoutPositionTypes,
  topbarThemeTypes,
  leftsidbarSizeTypes,
  leftSidebarViewTypes,
  leftSidebarImageTypes,
  preloaderTypes,
  sidebarVisibilitytypes,
  customSideBarShow
} from "../../Constants/layout";

import { changeHTMLAttribute } from './layout-helper';

const layoutStore = (set: any) => ({
  layoutType: layoutTypes.VERTICAL,
  leftSidebarType: leftSidebarTypes.DARK,
  layoutModeType: layoutModeTypes.DARKMODE,
  layoutWidthType: layoutWidthTypes.FLUID,
  layoutPositionType: layoutPositionTypes.FIXED,
  topbarThemeType: topbarThemeTypes.DARK,
  leftsidbarSizeType: leftsidbarSizeTypes.SMALLICON,
  leftSidebarViewType: leftSidebarViewTypes.DEFAULT,
  leftSidebarImageType: leftSidebarImageTypes.NONE,
  preloader: preloaderTypes.DISABLE,
  sidebarVisibilitytype: sidebarVisibilitytypes.SHOW,
  sidebarBarShow: customSideBarShow.SHOW,

  changeLayout: async (layout: any) => {
    try {
      if (layout === "twocolumn") {
        document.documentElement.removeAttribute("data-layout-width");
      } else if (layout === "horizontal") {
        document.documentElement.removeAttribute("data-sidebar-size");
      } else if (layout === "semibox") {
        changeHTMLAttribute("data-layout-width", "fluid");
        changeHTMLAttribute("data-layout-style", "default");
      }
      changeHTMLAttribute("data-layout", layout);
      set(() => ({ layoutType: layout }))
    } catch (error) { }
  },

  changeLayoutMode: async (layoutMode: any) => {
    try {
      changeHTMLAttribute("data-layout-mode", layoutMode);
      set(() => ({ layoutModeType: layoutMode }))
    } catch (error) { }
  },

  changeSidebarTheme: async (theme: any) => {
    try {
      changeHTMLAttribute("data-sidebar", theme);
      set(() => ({ leftSidebarTypes: theme }))
    } catch (error) {
      // console.log(error);
    }
  },

  changeLayoutWidth: async (layoutWidth: any) => {
    try {
      if (layoutWidth === 'lg') {
        changeHTMLAttribute("data-layout-width", "fluid");
      } else {
        changeHTMLAttribute("data-layout-width", "boxed");
      }
      set(() => ({ layoutWidthType: layoutWidth }))
    } catch (error) {
      return error;
    }
  },

  changeLayoutPosition: async (layoutPosition: any) => {
    try {
      changeHTMLAttribute("data-layout-position", layoutPosition);
      set(() => ({ layoutPositionType: layoutPosition }))
    } catch (error) {
      // console.log(error);
    }
  },

  changeTopBarTheme: async (topBarTheme: any) => {
    try {
      changeHTMLAttribute("data-topbar", topBarTheme);
      set(() => ({ topbarThemeType: topBarTheme }))

    } catch (error) {
      // console.log(error);
    }
  },

  changeSidebarImageType: async (leftSideBarImageType: any) => {
    try {
      changeHTMLAttribute("data-sidebar-image", leftSideBarImageType);
      set(() => ({ leftSidebarImageType: leftSideBarImageType }))
    } catch (error) {
      // console.log(error);
    }
  },

  changePreLoader: async (preloaderTypes: any) => {
    try {
      changeHTMLAttribute("data-preloader", preloaderTypes);
      set(() => ({ preloader: preloaderTypes }))
    } catch (error) {
      // console.log(error);
    }
  },

  changeLeftsidebarSizeType: async (leftsidebarSizetype: any) => {
    try {
      switch (leftsidebarSizetype) {
        case 'lg':
          changeHTMLAttribute("data-sidebar-size", "lg");
          break;
        case 'md':
          changeHTMLAttribute("data-sidebar-size", "md");
          break;
        case "sm":
          changeHTMLAttribute("data-sidebar-size", "sm");
          break;
        case "sm-hover":
          changeHTMLAttribute("data-sidebar-size", "sm-hover");
          break;
        default:
          changeHTMLAttribute("data-sidebar-size", "lg");
      }
      set(() => ({ leftsidbarSizeType: leftsidebarSizetype }))

    } catch (error) {
      // console.log(error);
    }
  },

  changeLeftsidebarViewType: async (leftsidebarViewtype: any) => {
    try {
      changeHTMLAttribute("data-layout-style", leftsidebarViewtype);
      set(() => ({ leftSidebarViewType: leftsidebarViewtype }))

    } catch (error) {
      // console.log(error);
    }
  },

  changeSidebarVisibility: async (sidebarVisibilitytype: any) => {
    try {
      changeHTMLAttribute("data-sidebar-visibility", sidebarVisibilitytype);
      set(() => ({ sidebarVisibilitytype: sidebarVisibilitytype }))
    } catch (error) { }
  },

  changeSidebarShow: async (sidebarStatus: any) => {
    try {
      set(() => ({ sidebarBarShow: sidebarStatus }))
    } catch (error) { }
  }

})

const useLayoutStore = create(layoutStore);

export default useLayoutStore;