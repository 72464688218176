import React, { useState, useEffect } from "react";
import { Button, Modal, ModalHeader, ModalBody, Row, Col } from "reactstrap";
import VideoCard from "./VideoCard";
import "./ShareModalPopup.scss";
import UserList from "./UserList";
import { AxiosResponse } from "axios";
import { getVideoDetail } from "../../../../ApiService/ApiCall";
import dayjs from "dayjs";
import { ShareModalInfoProps } from "../../../../Common/interface/types";
import { VIDEO_CARD_TYPE } from "../../../../Constants/Common/Data";
// import UploadIcon from "../../../../assets/images/New/Upload.svg";
import useTranscriptionStore from "../../../../Store/TranscriptionStore/TranscriptionStore";
// import useFtpFileUploadStore from "../../../../Store/FtpFileUploadStore/FtpFileUploadStore";
// import toast from "react-hot-toast";
// import { MESSAGE } from "../../../../Constants/Messages";

const SharedModalPopup: React.FC<ShareModalInfoProps> = (props) => {
  const { modalStatus, handleModal, resourceId } = props;
  const [videoData, setVideoData] = useState<any>(null);
  // const [tooltipFileUpload, setTooltipFileUpload] = useState(false);

  const { setLoading } = useTranscriptionStore();
  // const { setShowFtpFileUpload, setResourceIdForUpload, resourceIdForUpload } =
  //   useFtpFileUploadStore();

  const fetchVideoDetails = async (resourceId: string) => {
    try {
      setLoading(true);
      const response: AxiosResponse = await getVideoDetail({ resourceId });
      setVideoData(response);
      setLoading(false);
    } catch (err) {
      console.log(err);
    }
  };

  const handleModalClose = () => {
    handleModal();
  };

  // const toggleTooltipFileUpload = () => {
  //   setTooltipFileUpload(!tooltipFileUpload);
  // };

  // const handleFtpFileUpload = () => {
  //   if (resourceIdForUpload === "") {
  //     setResourceIdForUpload(resourceId);
  //     setShowFtpFileUpload(true);
  //     handleModal();
  //     toast.success(MESSAGE.SUCCESS.addedForTransfer);
  //   } else {
  //     toast.error(MESSAGE.WARNING.ftpUploadWarning);
  //   }
  // };

  useEffect(() => {
    if (modalStatus) {
      fetchVideoDetails(resourceId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [resourceId, modalStatus]);

  return (
    <Modal
      id="shareModal"
      isOpen={modalStatus}
      toggle={() => handleModalClose()}
      className="share-modal"
      centered
      size="md"
    >
      <ModalHeader>
        <h5 className="modal-title">Video Info</h5>
        <div className="upload-outer">
          {/* <div
            id="upload-icon"
            className="upload-icon cursor-pointer"
            onClick={handleFtpFileUpload}
          >
            <img src={UploadIcon} alt="upload" />
            <Tooltip
              placement="top"
              isOpen={tooltipFileUpload}
              autohide={true}
              target={`upload-icon`}
              toggle={toggleTooltipFileUpload}
            >
              Upload to FTP server
            </Tooltip>
          </div> */}
          <Button
            type="button"
            className="btn-close"
            onClick={() => handleModalClose()}
            aria-label="Close"
          />
        </div>
      </ModalHeader>
      <ModalBody>
        {videoData && !videoData.sharedBy && !videoData.sharedTo && (
          <div className="text-center mb-4">
            <VideoCard
              title={videoData?.fileName}
              lang={videoData?.defaultLanguage[0]}
              status={videoData?.status}
              date={videoData?.createdAt}
              size={videoData?.fileSize}
              user={videoData?.videoUploadedBy}
              trimSize={100}
              thumbnail={videoData?.thumbnail}
              id={videoData?._id}
              type={VIDEO_CARD_TYPE?.INFO}
            />
          </div>
        )}
        {videoData && (videoData.sharedBy || videoData.sharedTo) && (
          <div>
            <div className="text-center mb-4">
              <VideoCard
                title={videoData?.fileName}
                status={videoData?.status}
                lang={videoData?.defaultLanguage[0]}
                date={videoData?.createdAt}
                size={videoData?.fileSize}
                user={videoData?.videoUploadedBy}
                trimSize={100}
                thumbnail={videoData?.thumbnail}
                id={videoData?._id}
                type={VIDEO_CARD_TYPE?.INFO}
              />
            </div>
            <Row>
              <Col>
                <div className="main-modal">
                  <div className="modal-cool">
                    {videoData && videoData?.sharedBy && (
                      <>
                        <div className="modal-shared">
                          <p className="modal-info">Shared By:</p>
                          <div className="list-shared-by-user">
                            <div>
                              {videoData?.sharedBy?.firstName && (
                                <div className="sharedby">
                                  {videoData?.sharedBy?.firstName[0]}
                                  {videoData?.sharedBy?.lastName
                                    ? videoData?.sharedBy?.lastName[0]
                                    : videoData?.sharedBy?.firstName[1]}
                                </div>
                              )}
                            </div>
                            <div className="sharedby-user">
                              <div className="sharedby-name">{`${videoData.sharedBy.firstName} ${videoData.sharedBy.lastName}`}</div>
                              <div className="sharedby-email">
                                {videoData.sharedBy.email}
                              </div>
                            </div>
                          </div>
                        </div>
                      </>
                    )}

                    {videoData && videoData?.sharedTo ? (
                      <>
                        <div className="sharedto">
                          <div>
                            <p className="sharedto-data">
                              Shared With:
                              <UserList
                                users={videoData.sharedTo}
                                emails={videoData.sharedTo.map(
                                  (user: { email: any }) => user.email
                                )}
                              />
                            </p>
                          </div>
                        </div>
                      </>
                    ) : null}

                    {videoData && videoData?.sharedDate && (
                      <p>
                        Shared Date:{" "}
                        {dayjs(videoData?.sharedDate).format("DD-MM-YYYY")}
                      </p>
                    )}
                  </div>
                </div>
              </Col>
            </Row>
          </div>
        )}
      </ModalBody>
    </Modal>
  );
};

export default SharedModalPopup;
