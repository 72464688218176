
import React from "react";
import Modal from "react-modal";
import { FaDownload } from "react-icons/fa6";
import { GoFileZip } from "react-icons/go";
import '../../FileSection/DownloadModal/DownloadModal.css'
interface DownloadModalProps {
  isOpen: boolean;
  onClose: () => void;
  handleCompressDownload: () => void;
  handleDirectDownload: () => void;
  selectedResourceInfo: any[];
}

const DownloadModal: React.FC<DownloadModalProps> = ({
  isOpen,
  onClose,
  handleCompressDownload,
  handleDirectDownload,
}) => {
  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onClose}
      ariaHideApp={false}
      className='modal-container'
    >
      <FaDownload style={{ fontSize: "30px", marginBottom: "10px" }} />
      <div className="choose-option">Choose download options:</div>
      <div className="button-container">
        <button onClick={handleCompressDownload} className="download-btn">
        Compressed File
          <GoFileZip className="filezip" />
        </button>
        <button
          onClick={handleDirectDownload}
          className='btn-original'

        >
          Original File <FaDownload className="filedownload" />
        </button>
      </div>
      <button onClick={onClose} className="cancel-btn">
        Cancel
      </button>
    </Modal>
  );
};

export default DownloadModal;
