import "./VideoPlayerSection.scss";
import VideoIcon from "../../../assets/images/New/video.svg";
import CloseIcon from "../../../assets/images/New/close.svg";
import CustomVideoJsPlayer from "./VideoPlayer/VideoJsPlayer";
import useTabStore from "../../../Store/TabStore/TabStore";
import { TAB_TYPE } from "../../../Constants/Common/Data";
import { shortName } from "../../../Common/helper/helper";
import { useEffect, useState } from "react";
import useTranscriptionStore from "../../../Store/TranscriptionStore/TranscriptionStore";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleInfo } from "@fortawesome/free-solid-svg-icons";
import { Tooltip } from "reactstrap";
const VideoPlayerSection = () => {
  const [, setWidth] = useState("60%");
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const [hideButton, setHideButton] = useState(true);
  const { toggleActiveItem, activeItems } = useTabStore((state: any) => ({
    toggleActiveItem: state.toggleActiveItem,
    activeItems: state.activeItems,
  }));

  const { selectedVideoInfo, videoLoad, selectedVideoId } =
    useTranscriptionStore((state: any) => ({
      selectedVideoInfo: state.selectedVideoInfo,
      videoLoad: state.videoLoad,
      selectedVideoId: state.selectedVideoId,
    }));

  useEffect(() => {
    const containerDiv = document.getElementById("video-player-section");
    const handleResize = () => {
      if (containerDiv && containerDiv?.offsetWidth < 400) {
        let widthPercentage = 100;
        const calculatedWidth =
          (widthPercentage / 100) * containerDiv?.clientWidth;
        if (activeItems.includes(TAB_TYPE.WAVE)) {
          setWidth(calculatedWidth + "px");
        } else {
          setWidth(calculatedWidth + "px");
        }
      } else if (
        containerDiv &&
        containerDiv?.offsetWidth < 800 &&
        containerDiv?.offsetWidth > 400
      ) {
        let widthPercentage = 80;
        if (containerDiv?.offsetWidth < 400) {
          widthPercentage = activeItems.includes(TAB_TYPE.WAVE) ? 60 : 80;
        } else {
          widthPercentage = activeItems.includes(TAB_TYPE.WAVE) ? 100 : 80;
        }
        const calculatedWidth =
          (widthPercentage / 100) * containerDiv?.clientWidth;
        if (activeItems.includes(TAB_TYPE.WAVE)) {
          setWidth(calculatedWidth - 200 + "px");
        } else {
          setWidth(calculatedWidth + "px");
        }
        if (calculatedWidth < 450) {
          setHideButton(true);
        } else {
          setHideButton(false);
        }
      } else if (
        containerDiv &&
        containerDiv?.offsetWidth > 800 &&
        containerDiv?.offsetWidth < 1200
      ) {
        let widthPercentage = 90;
        const calculatedWidth =
          (widthPercentage / 100) * containerDiv?.clientWidth;
        if (activeItems.includes(TAB_TYPE.WAVE)) {
          setWidth(calculatedWidth - 250 + "px");
        } else {
          setWidth(calculatedWidth + "px");
          setHideButton(false);
        }
      } else if (containerDiv && containerDiv?.offsetWidth > 1200) {
        let widthPercentage = 60;
        if (activeItems.length === 2 && activeItems.includes(TAB_TYPE.WAVE)) {
          widthPercentage = 45;
        }
        const calculatedWidth =
          (widthPercentage / 100) * containerDiv?.clientWidth;
        setWidth(calculatedWidth + "px");
      }
    };
    containerDiv?.addEventListener("resize", handleResize);

    var resizeObserver = new ResizeObserver(handleResize);
    containerDiv && resizeObserver.observe(containerDiv);

    if (activeItems.length > 3) {
      setHideButton(true);
    } else if (
      activeItems.length === 1 &&
      activeItems.includes(TAB_TYPE.VIDEO)
    ) {
      setHideButton(false);
    } else {
      setHideButton(true);
    }

    return () => {
      containerDiv?.removeEventListener("resize", handleResize);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeItems]);

  const toggleTooltip = () => {
    setTooltipOpen(!tooltipOpen);
  };

  return (
    <div
      className={`video-player-section ${
        activeItems.includes(TAB_TYPE.WAVE) ? "h-80" : ""
      }`}
      id="video-player-section"
    >
      <div className="header">
        <div>
          <img src={VideoIcon} alt="file" />
        </div>
        <div className="video-title">
          <span id={`video-title-${selectedVideoId}`}>
            {shortName(selectedVideoInfo?.title, 25)}
          </span>
          <FontAwesomeIcon
            icon={faCircleInfo}
            className="mx-2"
            id={`icon-${selectedVideoId}`}
          />
          <Tooltip
            placement="bottom"
            isOpen={tooltipOpen}
            autohide={true}
            target={`icon-${selectedVideoId}`}
            toggle={toggleTooltip}
          >
            {selectedVideoInfo?.title}
          </Tooltip>
        </div>
        <div
          className="close-btn"
          onClick={() => toggleActiveItem(TAB_TYPE.VIDEO)}
        >
          <img src={CloseIcon} alt="close" />
        </div>
      </div>
      {/* <div className="player" style={{ width: width }}> */}
      <div className="player">
        {videoLoad && <CustomVideoJsPlayer hideButton={hideButton} />}
      </div>
    </div>
  );
};

export default VideoPlayerSection;
