import React from 'react';
import { Routes, Route, BrowserRouter } from "react-router-dom";

//Layouts
import VerticalLayout from "../Layouts/index";

//routes
import { authProtectedRoutes, publicRoutes } from "./allRoutes";
import NonAuthLayout from '../Layouts/NonAuthLayout';
import { AuthProtected } from './AuthProtected';

const Index = () => {
  return (
    <React.Fragment>
      <BrowserRouter>
        <Routes >
          <Route>
            {publicRoutes.map((route, idx) => (
              <Route
                path={route.path}
                element={
                  <NonAuthLayout>
                    {route.component}
                  </NonAuthLayout>
                }
                key={idx}
              />
            ))}
          </Route>
          <Route>
            {authProtectedRoutes.map((route, idx) => (
              <Route
                path={route.path}
                element={
                  <AuthProtected>
                    <VerticalLayout>{route.component}</VerticalLayout>
                  </AuthProtected>
                }
                key={idx}
              />
            ))}
          </Route>
        </Routes>
      </BrowserRouter>
    </React.Fragment>
  );
};

export default Index;