import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import { useEffect, useState } from "react";
import "./StreamModal.scss";
import { Modal, ModalBody } from "reactstrap";
import CLoseIcon from "../../../../../assets/images/New/close.svg";

const StreamSaveProcess = (props: any) => {
  const { isSaveProcess, setIsSaveProcess } = props;

  const [counter, setCounter] = useState(0);

  useEffect(() => {
    if (counter < 100) {
      const interval = setInterval(() => setCounter(counter + 1), 30);
      //Cleaning it up
      return () => clearInterval(interval);
    }
  }, [counter]);

  return (
    <Modal
      isOpen={isSaveProcess}
      onToggle={() => setIsSaveProcess(false)}
      className="stream-save-process-modal"
    >
      <ModalBody>
        <div className="header">
          <img
            alt="close"
            className="cursor-pointer"
            src={CLoseIcon}
            onClick={() => setIsSaveProcess(false)}
          />
        </div>
        <div className="stream-save-process-inner">
          <CircularProgressbar
            value={counter}
            text={`${counter}%`}
            strokeWidth={5}
            styles={buildStyles({
              strokeLinecap: "butt",
              pathColor: "#A546EF",
              textColor: "#A546EF",
            })}
          />
          <div> Your project is being saved...</div>
        </div>
      </ModalBody>
    </Modal>
  );
};

export default StreamSaveProcess;
