import { create } from "zustand";

const videoRef = (set: any) => ({
  waveSufferRef: null,
  waveSufferRegionRef: null,
  playerRef: null,
  segmentData: [],

  setWaveSufferRef: (currentRef: any) =>
    set((state: any) => ({
      waveSufferRef: currentRef,
    })),

  setWaveSufferRegionRef: (currentRef: any) =>
    set((state: any) => ({
      waveSufferRegionRef: currentRef,
    })),

  setPlayerRef: (currentRef: any) =>
    set((state: any) => ({
      playerRef: currentRef,
    })),

  setSegmentData: (data: []) =>
    set((state: any) => ({
      segmentData: data,
    })),
});

const useVideoRefStore = create(videoRef);

export default useVideoRefStore;