import { useEffect, useState } from "react";
import "./Upload.scss";
import useFileUploadStore from "../../../../Store/UploadStore/uploadStore";
import axios from "axios";
import {
  POST_VIDEO_FILE_UPLOAD,
  POST_VIDEO_FILE_UPLOAD_V1,
} from "../../../../ApiService/UrlHelper";
import CloseIcon from "../../../../assets/images/New/close.svg";
import { PopoverBody, Progress, UncontrolledPopover } from "reactstrap";
import {
  getExtension,
  isAllowedExtension,
} from "../../../../Common/helper/helper";
import {
  VIDEO_RESPONSE_STATUS,
  VIDEO_STATUS_TYPE,
} from "../../../../Constants/Common/Data";
import useTranscriptionStore from "../../../../Store/TranscriptionStore/TranscriptionStore";
import CloudUpload from "../../../../assets/images/New/CloudUpload.gif";
import { postCompressFile } from "../../../../ApiService/ApiCall";
import CancelModal from "../../UploadSection/CancelModal/CancelModal";
import { MESSAGE } from "../../../../Constants/Messages";
import Toast from "../../../../Common/helper/toasterService";

const Upload = () => {
  const {
    files,
    uploadStartStatus,
    uploadedPercentage,
    cancelToken,
    setShowUpload,
    setUploadStatus,
    resetFile,
    setShowUploadInfo,
    setShowUploadStart,
    setUploadedPercentage,
    setCancelToken,
    cancelModalStatus,
    setCancelModalStatus,
    setUploadFailedStatus,
    setUploadCancelStatus,
  } = useFileUploadStore((state: any) => ({
    files: state.files,
    uploadStartStatus: state.uploadStartStatus,
    uploadedPercentage: state.uploadedPercentage,
    cancelToken: state.cancelToken,
    setShowUpload: state.setShowUpload,
    setUploadStatus: state.setUploadStatus,
    resetFile: state.resetFile,
    setShowUploadInfo: state.setShowUploadInfo,
    setShowUploadStart: state.setShowUploadStart,
    setUploadedPercentage: state.setUploadedPercentage,
    setCancelToken: state.setCancelToken,
    cancelModalStatus: state.cancelModalStatus,
    setCancelModalStatus: state.setCancelModalStatus,
    setUploadFailedStatus: state.setUploadFailedStatus,
    setUploadCancelStatus: state.setUploadCancelStatus,
  }));
  const { setListUpdateStatus } = useTranscriptionStore();
  const [showUploadSection, setShowUploadSection] = useState(false);
  const [fileInfo, setFileInfo] = useState<any>();
  const [response, setResponse] = useState(VIDEO_RESPONSE_STATUS.UPLOADING);
  let UPLOAD_URL = POST_VIDEO_FILE_UPLOAD;

  useEffect(() => {
    if (files && files.length > 0 && uploadStartStatus) {
      setFileInfo(files[0]);
      setShowUploadSection(true);
      setShowUpload(false);
      if (isAllowedExtension(files[0]?.["name"])) {
        if (getExtension(files[0]?.["name"]) === "mp4") {
          uploadFiles(files[0], true);
        } else {
          uploadFiles(files[0], false);
        }
        setUploadStatus(true);
      } else {
        resetFile([]);
        setShowUploadStart(false);
        setShowUploadSection(false);
        setUploadStatus(false);
        Toast.showError(MESSAGE.ERROR.fileFormatError);
      }
    }
    return () => {
      setResponse(VIDEO_RESPONSE_STATUS.UPLOADING);
      setUploadedPercentage(0);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [files, uploadStartStatus]);

  const uploadFiles = async (files: any, type: boolean) => {
    if (files) {
      const formData = new FormData();
      formData.append("files", files);

      const controller = new AbortController();
      setCancelToken(controller);

      if (
        localStorage.getItem("API_VERSION") &&
        localStorage.getItem("API_VERSION") === "true"
      ) {
        UPLOAD_URL = POST_VIDEO_FILE_UPLOAD_V1;
      }

      try {
        const res: any = await axios.post(
          UPLOAD_URL + `?isCompressed=${type}`,
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
            signal: controller.signal,
            onUploadProgress: ({ loaded, total = 100 }) => {
              const percentage = Math.round((loaded * 100) / total);
              setUploadedPercentage(percentage);
              if (percentage === 100) {
                setResponse(VIDEO_RESPONSE_STATUS.COMPRESSING);
              }
            },
          }
        );
        if (res && (res?.status || res?.rawResourceId)) {
          setResponse(VIDEO_RESPONSE_STATUS.PROCESSING);
          if (
            localStorage.getItem("API_VERSION") &&
            localStorage.getItem("API_VERSION") === "true" &&
            res?.rawResourceId
          ) {
            postCompressFileApi(res?.rawResourceId);
          } else {
            setResponse(VIDEO_RESPONSE_STATUS.COMPLETED);
            setUploadStatus(false);
            Toast.showSuccess(MESSAGE.SUCCESS.upload);
            setTimeout(() => {
              setListUpdateStatus(true);
              resetFile([]);
              setShowUploadStart(false);
              setShowUploadSection(false);
            }, 2000);
          }
        } else {
          Toast.showError(res?.message || "Some error");
          setUploadStatus(false);
          resetFile([]);
          setShowUploadStart(false);
          setShowUploadSection(false);
        }
      } catch (err: any) {
        if (err?.message?.code === "ERR_NETWORK" || err?.statusCode === 500) {
          Toast.showError(err?.msg || err?.message?.message);
          setUploadFailedStatus(true);
          setUploadStatus(false);
          setShowUploadStart(false);
          setShowUploadSection(false);
        } else {
          setUploadStatus(false);
          resetFile([]);
          setShowUploadStart(false);
          setShowUploadSection(false);
        }
      }
    }
  };

  const postCompressFileApi = async (rawResourceId: string) => {
    try {
      setResponse(VIDEO_RESPONSE_STATUS.COMPRESSING);
      Toast.showSuccess(MESSAGE.SUCCESS.upload);
      await postCompressFile({
        rawResourceId: rawResourceId,
      });
      setResponse(VIDEO_RESPONSE_STATUS.COMPLETED);
      Toast.showSuccess(MESSAGE.SUCCESS.upload);
      setUploadStatus(false);
      setTimeout(() => {
        resetFile([]);
        setShowUploadStart(false);
        setListUpdateStatus(true);
        setShowUploadSection(false);
      }, 2000);
    } catch (err: any) {
      Toast.showError(err?.msg);
      console.log(err);
    }
  };

  const handleCancel = (event: any) => {
    event.stopPropagation();
    setCancelModalStatus(true);
  };

  const cancelUpload = () => {
    cancelToken && cancelToken?.abort();
    Toast.showCustom(MESSAGE.WARNING.uploadStop);
    setUploadStatus(false);
    resetFile([]);
    setShowUploadInfo(false);
    setShowUploadStart(false);
    setShowUploadSection(false);
    setCancelModalStatus(false);
    setUploadCancelStatus(true);
  };

  return (
    <>
      {showUploadSection && (
        <>
          <div className="header-upload cursor-pointer">
            <img src={CloudUpload} className="upload-icon mx-1" alt="upload" />
            <span id="upload">
              {`${
                uploadedPercentage < 100 &&
                response === VIDEO_RESPONSE_STATUS.UPLOADING
                  ? `${VIDEO_STATUS_TYPE.UPLOADING}(${uploadedPercentage}%)`
                  : `${
                      uploadedPercentage === 100 &&
                      response === VIDEO_RESPONSE_STATUS.PROCESSING
                        ? `${VIDEO_STATUS_TYPE.UPLOADED_SUCCESSFULLY}`
                        : response === VIDEO_RESPONSE_STATUS.COMPRESSING
                        ? `${VIDEO_STATUS_TYPE.COMPRESS_IN_PROCESS}`
                        : response === VIDEO_RESPONSE_STATUS.COMPLETED
                        ? `${VIDEO_STATUS_TYPE.COMPLETED}`
                        : ""
                    }`
              }`}
            </span>
            <div>
              <img
                className="cursor-pointer mx-2"
                onClick={(e) => handleCancel(e)}
                src={CloseIcon}
                alt="cancel"
              />
            </div>
          </div>
          <UncontrolledPopover
            target="upload"
            // trigger="focus"
            placement="bottom"
          >
            <PopoverBody>
              <div className="pop-over-section">
                <div className="icon">
                  <img
                    src={CloudUpload}
                    className="upload-icon-large mx-1"
                    alt="upload"
                  />
                </div>
                <div>
                  <div className="mb-2">{fileInfo?.["name"]}</div>
                  <Progress
                    color="primary"
                    striped
                    className="progress-xl"
                    value={uploadedPercentage}
                  >
                    {" "}
                    {uploadedPercentage} %{" "}
                  </Progress>
                </div>
              </div>
            </PopoverBody>
          </UncontrolledPopover>
        </>
      )}
      <CancelModal
        title={`${uploadedPercentage < 100 ? "upload" : "compression"}`}
        show={cancelModalStatus}
        onCloseClick={() => setCancelModalStatus(!cancelModalStatus)}
        onSubmitClick={() => cancelUpload()}
      />
    </>
  );
};

export default Upload;
