import Dropzone from "react-dropzone";
import "./UploadFile.scss";
import useFileUploadStore from "../../../../Store/UploadStore/uploadStore";
import UploadIcon from "../../../../assets/images/New/UploadIcon.svg";

const UploadFileDrop = () => {
  const { files, addFile, setShowUploadInfo, setShowUpload, resetFile } =
    useFileUploadStore();

  const handleAcceptedFiles = (file: any) => {
    if (files && files.length > 0) {
      resetFile([]);
    }
    addFile(file[0]);
    setShowUploadInfo(true);
    setShowUpload(false);
  };
  return (
    <div className="upload-file-drop">
      <Dropzone
        onDrop={(acceptedFiles: any) => {
          handleAcceptedFiles(acceptedFiles);
        }}
      >
        {({ getRootProps, getInputProps }) => (
          <div className="dropzone dz-clickable">
            <div className="dz-message needsclick" {...getRootProps()}>
              <div className="mb-1">
                <img
                  src={UploadIcon}
                  alt="upload-icon"
                  className="upload-icon"
                />
              </div>
              <h5>
                Drag your file(s) or <span className="browse">browse</span>
              </h5>
              <h5>Max 4 GB files are allowed</h5>
            </div>
          </div>
        )}
      </Dropzone>
    </div>
  );
};

export default UploadFileDrop;
