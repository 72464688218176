import { SUPABASE_KEY, SUPABASE_URL } from './Constants/Environment';
import { createClient, SupabaseClient } from '@supabase/supabase-js';

let SBObject: SupabaseClient<any, "public", any> | null = null;
export const init = () => {
    try {
        SBObject = createClient(SUPABASE_URL, SUPABASE_KEY);
    } catch (error) {
        console.log(error)
    }

}

export const getInstance = () => {
    if (SBObject) {
        return SBObject;
    } else {
        return SBObject = createClient(SUPABASE_URL, SUPABASE_KEY);
    }
}