import React, { useState } from "react";
import BlankCircle from "../../../../assets/images/New/blankCircle.svg";
import CheckCircle from "../../../../assets/images/New/CheckCircle.svg";
import InfoIcon from "../../../../assets/images/New/infoIcon.svg";
import ToggleSwitch from "../../ToggleComponent/ToggleSwitch";
import useStreamStore from "../../../../Store/StreamStore/StreamStore";
// import Pagination from "../../Common/Pagination/Pagination";
import {
  RenderLiveVideoStatus,
  RenderLiveVideoStatusColor,
  RenderLiveVideoToggle,
} from "../../../../Common/helper/helper";
import {
  ERROR_CODE,
  LIVE_VIDEO_STATUS,
} from "../../../../Constants/Common/Data";
import {
  getCheckLiveExist,
  postStartVideoStatus,
} from "../../../../ApiService/ApiCall";
import toast from "react-hot-toast";
import { MESSAGE } from "../../../../Constants/Messages";
import { ACTIVE_LIVE_VIDEO_COUNT } from "../../../../Constants/Environment";
import useStreamData from "../Hooks/useStreamData";
import SpinnerLoader from "../../../Common/Spinner/Spinner";
import usePermissionStore from "../../../../Store/PermissionStore/PermissionStore";

const VideoStatusChip = (props: any) => {
  const { status } = props;
  return (
    <div
      className="video-status-container"
      style={{ backgroundColor: RenderLiveVideoStatusColor(status) }}
    >
      <span>{RenderLiveVideoStatus(status)}</span>
    </div>
  );
};

let TrimString = function (string: string, length: number) {
  if (string) {
    return string?.length > length
      ? string.substring(0, length) + "..."
      : string;
  } else {
    return "";
  }
};

const StreamListSection = (props: any) => {
  const { handleShowStream, handleInfoEdit, loadData } = props;
  // const [limit] = useState(10);
  const [isLoading, setIsLoading] = useState(false);

  const LIVE_VIDEO_COUNT = +ACTIVE_LIVE_VIDEO_COUNT;

  const {
    streamVideoList,
    setStreamList,
    totalCount,
    selectedVideoIds,
    setSelectedVideoIds,
    setInfoModalMessage,
    setInfoModalStatus,
    setLiveVideoList,
    liveVideoList,
    setPauseModalStatus,
  } = useStreamStore((state: any) => ({
    streamVideoList: state.streamVideoList,
    setStreamList: state.setStreamList,
    totalCount: state.totalCount,
    selectedVideoIds: state.selectedVideoIds,
    setSelectedVideoIds: state.setSelectedVideoIds,
    setInfoModalMessage: state.setInfoModalMessage,
    setInfoModalStatus: state.setInfoModalStatus,
    setLiveVideoList: state.setLiveVideoList,
    liveVideoList: state.liveVideoList,
    setPauseModalStatus: state.setPauseModalStatus,
  }));

  const { moveToListingPage } = useStreamData();

  const { liveFieldPermission } = usePermissionStore();

  const handleVideoSelect = (e: any, data: any) => {
    e.stopPropagation();
    const index = selectedVideoIds.indexOf(data?._id);
    if (index === -1) {
      setSelectedVideoIds([...selectedVideoIds, data?._id]);
    } else {
      const newArray = [...selectedVideoIds];
      newArray.splice(index, 1);
      setSelectedVideoIds(newArray);
    }
  };

  const getStatus = (status: number) => {
    switch (status) {
      case LIVE_VIDEO_STATUS?.LIVE:
        return LIVE_VIDEO_STATUS?.PAUSE;
      case LIVE_VIDEO_STATUS?.PAUSE:
        return LIVE_VIDEO_STATUS?.RE_START;
      case LIVE_VIDEO_STATUS?.NOT_STARTED:
        return LIVE_VIDEO_STATUS?.LIVE;
      default:
        return LIVE_VIDEO_STATUS?.PAUSE;
    }
  };

  const changeVideoStatus = async (e: any, data: any) => {
    let status = getStatus(data?.status);
    if (
      data?.status === LIVE_VIDEO_STATUS?.PAUSE ||
      data?.status === LIVE_VIDEO_STATUS?.NOT_STARTED
    ) {
      const localStatus = checkLocalStatus();
      if (localStatus) {
        setIsLoading(true);
        const output = await checkLive();
        setIsLoading(false);
        if (output && output?.length === LIVE_VIDEO_COUNT - 1) {
          await changeVideoStatusApi(data?._id, status);
          const tempList = [...liveVideoList, data];
          setLiveVideoList(tempList);
        } else if (output && output[0]) {
          setInfoModalMessage(
            MESSAGE?.ERROR?.anotherLive +
              `(${output[0]?.title} ) by different user.  `
          );
          setInfoModalStatus(true);
          const tempList = [...liveVideoList, output[0]];
          setLiveVideoList(tempList);
        } else {
          setInfoModalMessage("Internal server error");
          setInfoModalStatus(true);
        }
      } else {
        setInfoModalMessage(
          MESSAGE?.ERROR?.anotherLive +
            `(${liveVideoList[0]?.title} ) by different user.  `
        );
        setInfoModalStatus(true);
      }
    } else {
      await changeVideoStatusApi(data?._id, status);
      changeLocalVideoStatus(data?._id);
    }
  };

  const checkLocalStatus = () => {
    if (liveVideoList && liveVideoList.length >= LIVE_VIDEO_COUNT) {
      return false;
    } else {
      return true;
    }
  };

  const changeLocalVideoStatus = (id: string) => {
    const updatedList = liveVideoList.filter((_list: any) => {
      return _list?._id !== id;
    });
    setLiveVideoList(updatedList);
  };

  const changeVideoStatusApi = async (id: string, status: number) => {
    try {
      const payload = {
        productionResourceId: id,
        status: status,
      };

      setIsLoading(true);
      const res: any = await postStartVideoStatus(payload);
      setIsLoading(false);
      if (res && res?.status) {
        if (status === LIVE_VIDEO_STATUS?.RE_START) {
          setInfoModalMessage(MESSAGE.CONTENT.restartStream);
          setInfoModalStatus(true);
        } else if (status === LIVE_VIDEO_STATUS?.LIVE) {
          setInfoModalMessage(MESSAGE.CONTENT.startStream);
          setInfoModalStatus(true);
        } else {
          setPauseModalStatus(true);
        }

        const temList = streamVideoList?.result;
        const newList = temList?.map((item: any) => {
          if (item?._id === id) {
            return { ...item, status: status };
          } else {
            return { ...item };
          }
        });
        setStreamList({ ...streamVideoList, result: newList });
      } else if (res?.message) {
        toast.error(res?.message);
      }
    } catch (err: any) {
      console.log(err);
      if (err?.type && err?.type === ERROR_CODE?.DELETE) {
        moveToListingPage();
      }
      toast.error(err?.msg || err?.message);
    }
  };
  const checkLive = async () => {
    try {
      const res: any = await getCheckLiveExist({});
      return res;
    } catch (err: any) {
      console.log(err);
      toast.error(err?.msg || err?.message);
    }
  };

  // const handlePageChange = (event: any) => {
  //   let temSkip = event.selected * limit;
  //   setSkipCount(temSkip);
  //   setSelectedPage(event.selected);
  // };

  const handleLoadMoreData = async () => {
    if (streamVideoList?.result?.length !== streamVideoList?.total) {
      await loadData();
    }
  };

  return (
    <>
      {isLoading && <SpinnerLoader />}
      <div className="list-section-outer">
        <div className="list-section">
          <div
            style={{
              padding: "30px",
              display: "flex",
              gap: "10px",
              flexWrap: "wrap",
            }}
          >
            {streamVideoList?.result &&
              streamVideoList?.result.length > 0 &&
              streamVideoList?.result?.map((item: any, index: number) => {
                return (
                  <div
                    key={`list-section-${index}`}
                    className="list-section-container"
                    onClick={(e) => handleShowStream(e, item, index)}
                    style={{
                      backgroundImage: `url(${item?.thumbnail})`,
                    }}
                  >
                    <div className="content">
                      <div className="heading">
                        {liveFieldPermission.ARCHIVE ? (
                          <div
                            className="checkbox"
                            onClick={(event) => handleVideoSelect(event, item)}
                          >
                            {selectedVideoIds.indexOf(item?._id) !== -1 ? (
                              <img src={CheckCircle} alt="check" />
                            ) : (
                              <img src={BlankCircle} alt="blank" />
                            )}
                          </div>
                        ) : (
                          <div></div>
                        )}
                        <div className="right-content">
                          <div>
                            <img
                              onClick={(e) => handleInfoEdit(e, index)}
                              src={InfoIcon}
                              alt="Info"
                              width={"20px"}
                            />
                          </div>
                          {item?.status !== LIVE_VIDEO_STATUS?.LIVE_ENDED &&
                            item?.status !== LIVE_VIDEO_STATUS?.ERROR &&
                            liveFieldPermission.CHANGE_STATUS && (
                              <ToggleSwitch
                                id={`switch-${index}`}
                                checked={RenderLiveVideoToggle(item?.status)}
                                onChange={(e: any) =>
                                  changeVideoStatus(e, item)
                                }
                              />
                            )}
                        </div>
                      </div>
                      <div className="details">
                        <span>{TrimString(item?.title, 15)}</span>
                        <VideoStatusChip status={item?.status} />
                      </div>
                    </div>
                  </div>
                );
              })}
          </div>
        </div>
        <div className="load-btn-outer">
          {streamVideoList?.result && streamVideoList?.result?.length > 0 && (
            <>
              <div className="showing">
                Showing {streamVideoList?.result.length} / {totalCount}
              </div>
              <div
                className={`load-btn ${
                  streamVideoList?.result?.length === streamVideoList?.total
                    ? "disable"
                    : ""
                }`}
                onClick={handleLoadMoreData}
              >
                Load more
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default StreamListSection;
