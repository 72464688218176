import React from "react";
import ModalLayout from "../../Common/ModalLayout";
import CheckIcon from "../../../assets/images/New/CheckIcon.svg";
import ButtonComponent from "./ButtonComponent";
import { LIVE_VIDEO_TYPE } from "../../../Constants/Common/Data";

const contentStyle = {
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  textAlign: "center",
};

const titleStyle = {
  fontWeight: "700",
  fontSize: "15px",
  width: "100%",
  wordWrap: "break-word" as "normal" | "break-word",
};

const subTitleStyle = {
  fontSize: "15px",
  width: "100%",
  wordWrap: "break-word" as "normal" | "break-word",
};

const StreamInfoModal = (props: any) => {
  const { infoShow, setInfoShow, formData, videoType } = props;
  return (
    <ModalLayout
      isShowing={infoShow}
      style={{ background: "rgba(26, 26, 26, 1)" }}
      hideCloseButton
    >
      <div style={contentStyle as React.CSSProperties}>
        <img style={{ marginBottom: "10px" }} src={CheckIcon} alt="check" />
        <p style={titleStyle}>{`${
          videoType === LIVE_VIDEO_TYPE?.YOUTUBE ? "Youtube" : "SRT"
        } ${formData?.title} can be streamed now.`}</p>
        <p style={subTitleStyle}>{`${
          videoType === LIVE_VIDEO_TYPE?.YOUTUBE ? "Youtube" : "SRT"
        } ${
          formData?.title
        } is listed in your Live media. You are now able to add and change subtitles, and edit this file.`}</p>
        <ButtonComponent
          onClick={() => setInfoShow(false)}
          title="Okay"
          style={{
            backgroundColor: "white",
            color: "black",
            fontWeight: "700",
            width: "100px",
          }}
        />
      </div>
    </ModalLayout>
  );
};

export default StreamInfoModal;
