import React from "react";
import './UserList.scss';


interface User {
    firstName: string;
    lastName: string;
}

const UserList: React.FC<{ users?: User[]; emails: string[] }> = ({ users = [], emails }) => {
    const getInitials = (name: string | undefined) => {
        if (!name) {
            return '';
        }

        const names = name.split(' ');
        const firstNameInitial = names[0] ? names[0][0] : '';
        const lastNameInitial = names[1] ? names[1][0] : '';
        return `${firstNameInitial}${lastNameInitial}`.toUpperCase();
    };

    return (
        <div className="list">
            {users && users.map((user, index) => (
                <div key={index} className="user-list">
                    <div className="list-data">
                        {getInitials(`${user.firstName} ${user.lastName}`)}
                    </div>
                    <div className="list-internal">
                        <p className="userlist-name">{`${user.firstName} ${user.lastName}`}</p>
                        <p className="userlist-email">{emails[index]}</p>
                    </div>
                </div>
            ))}
        </div>
    );
};

export default UserList;