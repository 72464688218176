import { create } from "zustand";

const fileUploadStore = (set: any) => ({
  files: [],
  currentUploadFileId: "",
  filesDetails: [],
  uploadedPercentage: 0,
  cancelToken: null,
  showUpload: false,
  showUploadInfo: false,
  uploadStatus: false,
  uploadFailedStatus: false,
  uploadStartStatus: false,
  cancelModalStatus: false,
  uploadedVideoDetails: [],
  uploadCancelStatus: false,

  addFile: (file: any) =>
    set((state: any) => ({
      files: [file],
    })),

  resetFile: (files: any) =>
    set((state: any) => ({
      files: files,
    })),

  setShowUpload: (value: boolean) =>
    set((state: any) => ({
      showUpload: value,
    })),

  setShowUploadInfo: (value: boolean) =>
    set((state: any) => ({
      showUploadInfo: value,
    })),

  setShowUploadStart: (value: boolean) =>
    set((state: any) => ({
      uploadStartStatus: value,
    })),

  setUploadStatus: (value: boolean) =>
    set((state: any) => ({
      uploadStatus: value,
    })),

  setUploadFailedStatus: (value: boolean) =>
    set((state: any) => ({
      uploadFailedStatus: value,
    })),

  setUploadedPercentage: (value: number) =>
    set((state: any) => ({
      uploadedPercentage: value,
    })),

  setCancelToken: (value: any) =>
    set((state: any) => ({
      cancelToken: value,
    })),

  setCancelModalStatus: (value: boolean) =>
    set((state: any) => ({
      cancelModalStatus: value,
    })),

  setFileDetails: (data: any) =>
    set((state: any) => ({
      filesDetails: data,
    })),

  setUploadedVideoDetails: (details: any[]) =>
    set((state: any) => ({
      uploadedVideoDetails: details,
    })),

  setCurrentUploadFileId: (id: string) =>
    set((state: any) => ({
      currentUploadFileId: id,
    })),

  setUploadCancelStatus: (status: boolean) =>
    set((state: any) => ({
      uploadCancelStatus: status,
    })),
});

const useFileUploadStore = create(fileUploadStore);

export default useFileUploadStore;
