export const AddIcon = (props: any) => {
  const { color } = props;
  return (
    <svg
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.667 7C13.667 7.5625 13.1982 8.03125 12.667 8.03125H8.16699V12.5312C8.16699 13.0625 7.69824 13.5 7.16699 13.5C6.60449 13.5 6.16699 13.0625 6.16699 12.5312V8.03125H1.66699C1.10449 8.03125 0.666992 7.5625 0.666992 7C0.666992 6.46875 1.10449 6.03125 1.66699 6.03125H6.16699V1.53125C6.16699 0.96875 6.60449 0.5 7.16699 0.5C7.69824 0.5 8.16699 0.96875 8.16699 1.53125V6.03125H12.667C13.1982 6 13.667 6.46875 13.667 7Z"
        fill={color}
      />
    </svg>
  );
};

export const MediaIcon = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.99992 19.1668V0.833497M5.41659 19.1668V14.5835M5.41659 5.41683V0.833497M14.5833 19.1668V14.5835M14.5833 5.41683V0.833497M0.833252 5.41683H19.1666M0.833252 14.5835H19.1666M19.1666 14.7668V5.2335C19.1666 3.69335 19.1666 2.92328 18.8669 2.33502C18.6032 1.81758 18.1825 1.39688 17.6651 1.13323C17.0768 0.833496 16.3067 0.833496 14.7666 0.833496L5.23325 0.833497C3.69311 0.833497 2.92304 0.833497 2.33478 1.13323C1.81733 1.39688 1.39664 1.81758 1.13298 2.33502C0.833252 2.92328 0.833252 3.69335 0.833252 5.2335L0.833252 14.7668C0.833252 16.307 0.833252 17.077 1.13298 17.6653C1.39664 18.1827 1.81733 18.6034 2.33478 18.8671C2.92304 19.1668 3.69311 19.1668 5.23325 19.1668H14.7666C16.3067 19.1668 17.0768 19.1668 17.6651 18.8671C18.1825 18.6034 18.6032 18.1827 18.8669 17.6653C19.1666 17.077 19.1666 16.307 19.1666 14.7668Z"
        stroke="#BBBBBB"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export const TextIcon = () => {
  return (
    <svg
      width="16"
      height="11"
      viewBox="0 0 16 11"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.56 0.804C11.1547 0.804 10.952 1.06533 10.952 1.588V8.068C10.952 8.676 11.112 9.11867 11.432 9.396C11.752 9.67333 12.1733 9.812 12.696 9.812H14.632V10.5H1.032V9.812H2.968C3.49067 9.812 3.90667 9.67333 4.216 9.396C4.536 9.11867 4.696 8.676 4.696 8.068V1.588C4.696 1.06533 4.49867 0.804 4.104 0.804C3.92267 0.804 3.66667 0.937333 3.336 1.204C3.016 1.47067 2.69067 1.79067 2.36 2.164C1.69867 2.91067 1.13333 3.60933 0.664 4.26L0.376 4.66H0.056V0.0999994H15.88V4.66H15.272C15.1013 4.41467 14.8027 4.02 14.376 3.476C13.9493 2.932 13.5813 2.48933 13.272 2.148C12.9733 1.80667 12.6587 1.49733 12.328 1.22C11.9973 0.942666 11.7413 0.804 11.56 0.804ZM6.824 9.812V0.787999H5.784V9.812H6.824Z"
        fill="#BBBBBB"
      />
    </svg>
  );
};

export const BookMarkIcon = () => {
  return (
    <svg
      width="16"
      height="20"
      viewBox="0 0 16 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.58325 6.15C1.58325 4.60986 1.58325 3.83978 1.88298 3.25153C2.14664 2.73408 2.56733 2.31338 3.08478 2.04973C3.67304 1.75 4.44311 1.75 5.98325 1.75H10.0166C11.5567 1.75 12.3268 1.75 12.9151 2.04973C13.4325 2.31338 13.8532 2.73408 14.1169 3.25153C14.4166 3.83978 14.4166 4.60986 14.4166 6.15V18.25L7.99992 14.5833L1.58325 18.25V6.15Z"
        stroke="#BBBBBB"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export const DownloadSmallIcon = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M18.25 12.75V13.85C18.25 15.3901 18.25 16.1602 17.9503 16.7485C17.6866 17.2659 17.2659 17.6866 16.7485 17.9503C16.1602 18.25 15.3901 18.25 13.85 18.25H6.15C4.60986 18.25 3.83978 18.25 3.25153 17.9503C2.73408 17.6866 2.31338 17.2659 2.04973 16.7485C1.75 16.1602 1.75 15.3901 1.75 13.85V12.75M14.5833 8.16667L10 12.75M10 12.75L5.41667 8.16667M10 12.75V1.75"
        stroke="#BBBBBB"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export const InfoIcon = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.78599 3.92861H8.21456V5.35718H6.78599V3.92861Z"
        fill="white"
      />
      <path
        d="M6.78599 6.78575H8.21456V11.0715H6.78599V6.78575Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.357422 7.50004C0.357422 3.55718 3.55741 0.357178 7.50028 0.357178C11.4432 0.357178 14.6431 3.55718 14.6431 7.50004C14.6431 11.4429 11.4432 14.6429 7.50028 14.6429C3.55741 14.6429 0.357422 11.4429 0.357422 7.50004ZM1.78599 7.50004C1.78599 10.65 4.35025 13.2143 7.50028 13.2143C10.6503 13.2143 13.2146 10.65 13.2146 7.50004C13.2146 4.35003 10.6503 1.78575 7.50028 1.78575C4.35025 1.78575 1.78599 4.35003 1.78599 7.50004Z"
        fill="white"
      />
    </svg>
  );
};

export const PencilIcon = () => {
  return (
    <svg
      width="22"
      height="22"
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.5 20.4998L7.04927 18.3655C7.40421 18.229 7.58168 18.1607 7.74772 18.0716C7.8952 17.9924 8.0358 17.901 8.16804 17.7984C8.31692 17.6829 8.45137 17.5484 8.72028 17.2795L20 5.99982C21.1046 4.89525 21.1046 3.10438 20 1.99981C18.8955 0.895245 17.1046 0.895244 16 1.99981L4.72028 13.2795C4.45138 13.5484 4.31692 13.6829 4.20139 13.8318C4.09877 13.964 4.0074 14.1046 3.92823 14.2521C3.83911 14.4181 3.77085 14.5956 3.63433 14.9506L1.5 20.4998ZM1.5 20.4998L3.55812 15.1488C3.7054 14.7659 3.77903 14.5744 3.90534 14.4867C4.01572 14.4101 4.1523 14.3811 4.2843 14.4063C4.43533 14.4351 4.58038 14.5802 4.87048 14.8703L7.12957 17.1294C7.41967 17.4195 7.56472 17.5645 7.59356 17.7155C7.61877 17.8475 7.58979 17.9841 7.51314 18.0945C7.42545 18.2208 7.23399 18.2944 6.85107 18.4417L1.5 20.4998Z"
        stroke="#BBBBBB"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export const SubtitleDownloadIcon = () => {
  return (
    <svg
      width="28"
      height="20"
      viewBox="0 0 28 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.3072 9.54844V1.17089C13.3072 0.801041 13.617 0.5 13.9994 0.5C14.3817 0.5 14.6915 0.801041 14.6915 1.17089V9.54844L17.8797 6.82186C18.1654 6.57673 18.6004 6.60253 18.8531 6.87992C19.1058 7.15731 19.0794 7.57877 18.7938 7.8239L14.4564 11.531C14.1905 11.7589 13.795 11.7525 13.5379 11.5267L9.20493 7.8239C8.91928 7.57877 8.89292 7.15731 9.1456 6.87992C9.39829 6.60469 9.83335 6.57888 10.119 6.82186L13.3072 9.54844ZM0.519137 10.6988C0.424655 10.3182 0.664157 9.93549 1.05307 9.84303C1.44199 9.75057 1.8331 9.98495 1.92758 10.3656C2.1539 11.2816 2.3143 12.0707 2.46152 12.7954C3.15805 16.2337 3.53159 18.0787 8.4271 18.0787H19.9737C24.5484 18.0787 24.9198 16.1283 25.5372 12.8986C25.6844 12.1245 25.8448 11.2859 26.0733 10.3656C26.1678 9.98495 26.5589 9.75057 26.9479 9.84303C27.3368 9.93549 27.5741 10.3182 27.4818 10.6988C27.2665 11.5633 27.1083 12.3933 26.9632 13.1609C26.2074 17.1132 25.7525 19.5 19.9759 19.5H8.4249C2.33847 19.5 1.88364 17.2551 1.03549 13.0685C0.875094 12.2772 0.714694 11.4837 0.519137 10.6988Z"
        fill="#BBBBBB"
      />
    </svg>
  );
};

export const TranscriptRefreshIcon = () => {
  return (
    <svg
      width="13"
      height="12"
      viewBox="0 0 13 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2.88889 12V10.2H11.5556V6.6H10.1111V9H2.88889V7.2L0 9.6L2.88889 12ZM13 2.4L10.1111 0V1.8H1.44444V5.4H2.88889V3H10.1111V4.8L13 2.4Z"
        fill="#BBBBBB"
      />
    </svg>
  );
};

export const DeleteIcon = () => {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2.86182 3.80957H11.0222"
        stroke="white"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.92218 5.98633V9.25161"
        stroke="white"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.96265 5.98633V9.25161"
        stroke="white"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M3.37207 3.80957L3.88209 10.3401C3.88209 10.6288 3.98956 10.9057 4.18086 11.1098C4.37215 11.3139 4.63161 11.4286 4.90214 11.4286H8.98232C9.25285 11.4286 9.51231 11.3139 9.7036 11.1098C9.8949 10.9057 10.0024 10.6288 10.0024 10.3401L10.5124 3.80957"
        stroke="white"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.41193 3.80967V2.17703C5.41193 2.03269 5.46566 1.89427 5.56131 1.79221C5.65696 1.69015 5.78668 1.63281 5.92195 1.63281H7.96204C8.0973 1.63281 8.22703 1.69015 8.32268 1.79221C8.41833 1.89427 8.47206 2.03269 8.47206 2.17703V3.80967"
        stroke="white"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export const BackWardIcon = () => {
  return (
    <svg
      width="11"
      height="13"
      viewBox="0 0 11 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        opacity="0.5"
        d="M1.63525 1.2793C2.1626 1.2793 2.60205 1.68945 2.60205 2.2168V11.5625C2.60205 12.0898 2.1626 12.5 1.66455 12.5C1.13721 12.5 0.727051 12.0898 0.727051 11.5625V2.2168C0.727051 1.68945 1.10791 1.2793 1.63525 1.2793ZM8.54932 1.48438C9.16455 0.986328 10.0728 1.39648 10.0728 2.1875V11.5625C10.0728 12.3535 9.16455 12.7637 8.54932 12.2656L2.92432 7.60742C2.68994 7.43164 2.60205 7.16797 2.60205 6.875C2.60205 6.61133 2.68994 6.34766 2.92432 6.14258L8.54932 1.48438Z"
        fill="white"
      />
    </svg>
  );
};

export const ForwardIcon = () => {
  return (
    <svg
      width="10"
      height="13"
      viewBox="0 0 10 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        opacity="0.5"
        d="M8.95898 12.5C8.46094 12.5 8.02148 12.0898 8.02148 11.5625V2.1875C8.02148 1.68945 8.40234 1.2793 8.92969 1.2793C9.42773 1.2793 9.86719 1.68945 9.86719 2.2168V11.5625C9.86719 12.0605 9.45703 12.5 8.95898 12.5ZM2.07422 12.2949C1.45898 12.793 0.550781 12.3828 0.550781 11.5625V2.2168C0.550781 1.39648 1.45898 0.986328 2.07422 1.54297L7.69922 6.20117C7.93359 6.37695 8.02148 6.64062 8.02148 6.9043C8.02148 7.16797 7.93359 7.46094 7.69922 7.63672L2.07422 12.2949Z"
        fill="white"
      />
    </svg>
  );
};

export const DropDown = () => {
  return (
    <svg
      width="12"
      height="8"
      viewBox="0 0 12 8"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.5886 0.744078C1.26317 0.418641 0.73553 0.418641 0.410093 0.744078C0.0846564 1.06951 0.0846564 1.59715 0.410093 1.92259L5.41009 6.92259C5.73553 7.24803 6.26317 7.24803 6.5886 6.92259L11.5886 1.92259C11.914 1.59715 11.914 1.06951 11.5886 0.744078C11.2632 0.418641 10.7355 0.418641 10.4101 0.744078L5.99935 5.15482L1.5886 0.744078Z"
        fill="#BBBBBB"
      />
    </svg>
  );
};

export const DropUp = () => {
  return (
    <svg
      width="12"
      height="8"
      viewBox="0 0 12 8"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.5886 7.25592C1.26317 7.58136 0.73553 7.58136 0.410093 7.25592C0.0846564 6.93049 0.0846564 6.40285 0.410093 6.07741L5.41009 1.07741C5.73553 0.751975 6.26317 0.751975 6.5886 1.07741L11.5886 6.07741C11.914 6.40285 11.914 6.93049 11.5886 7.25592C11.2632 7.58136 10.7355 7.58136 10.4101 7.25592L5.99935 2.84518L1.5886 7.25592Z"
        fill="#BBBBBB"
      />
    </svg>
  );
};

export const CopyIcon = () => {
  return (
    <svg
      width="17"
      height="17"
      viewBox="0 0 17 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.3335 3.5C12.3335 4.0625 12.771 4.5 13.3335 4.5H16.3335V11C16.3335 11.8438 15.646 12.5 14.8335 12.5H8.8335C7.98975 12.5 7.3335 11.8438 7.3335 11V2C7.3335 1.1875 7.98975 0.5 8.8335 0.5H12.3335V3.5ZM13.3335 0.5L16.3335 3.5H13.3335V0.5ZM6.3335 11.5C6.3335 12.625 7.2085 13.5 8.3335 13.5H9.3335V15C9.3335 15.8438 8.646 16.5 7.8335 16.5H1.8335C0.989746 16.5 0.333496 15.8438 0.333496 15V6C0.333496 5.1875 0.989746 4.5 1.8335 4.5H6.3335V11.5Z"
        fill="white"
      />
    </svg>
  );
};

export const SearchIcon = () => {
  return (
    <svg
      width="17"
      height="16"
      viewBox="0 0 17 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15.325 15.7625L10.1917 10.8625C9.73333 11.2125 9.20625 11.4896 8.61042 11.6937C8.01458 11.8979 7.38056 12 6.70833 12C5.04306 12 3.63383 11.4493 2.48067 10.348C1.3275 9.24667 0.750611 7.9015 0.75 6.3125C0.75 4.72292 1.32689 3.37775 2.48067 2.277C3.63444 1.17625 5.04367 0.625583 6.70833 0.625C8.37361 0.625 9.78283 1.17567 10.936 2.277C12.0892 3.37833 12.6661 4.7235 12.6667 6.3125C12.6667 6.95417 12.5597 7.55937 12.3458 8.12812C12.1319 8.69687 11.8417 9.2 11.475 9.6375L16.6313 14.5594C16.7993 14.7198 16.8833 14.9167 16.8833 15.15C16.8833 15.3833 16.7917 15.5875 16.6083 15.7625C16.4403 15.9229 16.2264 16.0031 15.9667 16.0031C15.7069 16.0031 15.4931 15.9229 15.325 15.7625ZM6.70833 10.25C7.85417 10.25 8.82828 9.86704 9.63067 9.10113C10.4331 8.33521 10.8339 7.40567 10.8333 6.3125C10.8333 5.21875 10.4321 4.28892 9.62975 3.523C8.82736 2.75708 7.85356 2.37442 6.70833 2.375C5.5625 2.375 4.58839 2.75796 3.786 3.52388C2.98361 4.28979 2.58272 5.21933 2.58333 6.3125C2.58333 7.40625 2.98453 8.33608 3.78692 9.102C4.58931 9.86792 5.56311 10.2506 6.70833 10.25Z"
        fill="#ffff"
      />
    </svg>
  );
};

export const CloseIcon = () => {
  return (
    <svg
      width="12"
      height="11"
      viewBox="0 0 12 11"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        opacity="0.5"
        d="M10.6875 9.3125C11.0938 9.6875 11.0938 10.3438 10.6875 10.7188C10.5 10.9062 10.25 11 10 11C9.71875 11 9.46875 10.9062 9.28125 10.7188L6 7.4375L2.6875 10.7188C2.5 10.9062 2.25 11 2 11C1.71875 11 1.46875 10.9062 1.28125 10.7188C0.875 10.3438 0.875 9.6875 1.28125 9.3125L4.5625 6L1.28125 2.71875C0.875 2.34375 0.875 1.6875 1.28125 1.3125C1.65625 0.90625 2.3125 0.90625 2.6875 1.3125L6 4.59375L9.28125 1.3125C9.65625 0.90625 10.3125 0.90625 10.6875 1.3125C11.0938 1.6875 11.0938 2.34375 10.6875 2.71875L7.40625 6.03125L10.6875 9.3125Z"
        fill="white"
      />
    </svg>
  );
};
