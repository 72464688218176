import React, { useEffect, useState } from "react";
import "./playerComponent.scss";
import { TAB_TYPE } from "../../../../Constants/Common/Data";
import useTabStore from "../../../../Store/TabStore/TabStore";
import CloseIcon from "../../../../assets/images/New/close.svg";
import { shortName } from "../../../../Common/helper/helper";
import useStreamStore from "../../../../Store/StreamStore/StreamStore";
import SrtVideoPlayer from "../SrtPlayer/SrtPlayer";
import {
  BookMarkIcon,
  DownloadSmallIcon,
  InfoIcon,
  MediaIcon,
  TextIcon,
} from "../../../Common/Icon";
import { handleDivResize } from "../../../../Common/helper/util";
import { Tooltip } from "reactstrap";
const VideoEditor = (props: any) => {
  const { handleCloseVideoEditor, state, handleInfo } = props;
  const [divSize, setDivSize] = useState(false);
  const [tooltipTitle, setTooltipTitle] = useState(false);
  const { activeItems } = useTabStore((state: any) => ({
    activeItems: state.activeItems,
  }));

  const { selectedVideoInfo } = useStreamStore((state: any) => ({
    selectedVideoInfo: state.selectedVideoInfo,
  }));

  useEffect(() => {
    const containerDiv = document.getElementById("stream-player-section");
    const handleResize = () => {
      if (containerDiv && containerDiv?.offsetWidth < 1000) {
        // console.log("Screen size is less than 765 pixels.");
        setDivSize(true);
      } else {
        setDivSize(false);
      }
    };
    containerDiv?.addEventListener("resize", handleResize);

    var resizeObserver = new ResizeObserver(handleResize);
    containerDiv && resizeObserver.observe(containerDiv);

    return () => {
      containerDiv?.removeEventListener("resize", handleResize);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleResizeDiv = () => {
    const containerDiv = document.getElementById("stream-player-section");
    handleDivResize(containerDiv, setDivSize);
  };

  const toggleTooltipTitle = () => {
    setTooltipTitle(!tooltipTitle);
  };

  const videoJsOptions = {
    autoplay: true,
    controls: true,
    preload: "auto",
    liveui: true,
    responsive: true,
    liveTracker: true,
    muted: true,
    fluid: true,
    fill: true,
    breakpoints: true,
    playbackRates: [0.5, 1, 1.5, 2],
    controlBar: {
      skipButtons: false,
      nextButton: false,
      pictureInPictureToggle: false,
      volumePanel: {
        inline: false,
        volumeControl: {
          vertical: true,
        },
      },
      // progressControl: !selectedVideoInfo?.isLive,
      // progressControl: true,
    },
    thumbnailUrl: selectedVideoInfo?.frameThumbnail,
    // textTracks: addSubtitle(selectedVideoInfo?.languages),
    sources: [
      {
        src: selectedVideoInfo?.resourceDetail?.path,
        // src: "https://bitdash-a.akamaihd.net/content/sintel/hls/playlist.m3u8",
        // src: "/video/index1.m3u8",
        type: "application/x-mpegURL",
      },
    ],
    aspectRatio: "16:9",
  };

  return (
    <div
      className={`video-player-section live-stream-section ${
        activeItems.includes(TAB_TYPE.LIVE_STREAM) ? "h-80" : ""
      }`}
      id="stream-player-section"
      onResize={handleResizeDiv}
    >
      {/* <div className="header">
        <div>
          <img src={LiveStreamIcon} width={"24px"} alt="Live-Stream" />
        </div>
        <div
          className="close-btn"
          onClick={() => toggleActiveItem(TAB_TYPE.LIVE_STREAM)}
        >
          <img src={CloseIcon} alt="close" />
        </div>
      </div> */}
      <div className="playerParentContainer">
        <div className="close-btn" onClick={() => handleCloseVideoEditor()}>
          <img src={CloseIcon} alt="close" />
        </div>
        <div
          className={`main-container 
          ${state?.length > 1 || divSize ? "column" : "row"}
          `}
        >
          <div className="left-container">
            <div className="button-container">
              <div className="video-btn">
                <MediaIcon />
              </div>
              <div className="video-btn">
                <TextIcon />
              </div>
              <div className="video-btn">
                <BookMarkIcon />
              </div>
              <div className="video-btn">
                <DownloadSmallIcon />
              </div>
              <div className="video-btn" onClick={() => handleInfo()}>
                <InfoIcon />
              </div>
            </div>
          </div>
          <div className="right-container">
            <div className="stream-container">
              <div className="title" id="video-title">
                {shortName(selectedVideoInfo?.title, 50)}
                <Tooltip
                  placement="top"
                  isOpen={tooltipTitle}
                  autohide={true}
                  target={`video-title`}
                  toggle={toggleTooltipTitle}
                >
                  {selectedVideoInfo?.title}
                </Tooltip>
              </div>
              <SrtVideoPlayer
                widthCss={state?.length > 1}
                id={selectedVideoInfo?._id}
                options={videoJsOptions}
                videoStatus={selectedVideoInfo?.status}
                videoType={selectedVideoInfo?.type}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default VideoEditor;
