import { STREAM_TAB_TYPE } from "../../../../Constants/Common/Data";
import useStreamStore from "../../../../Store/StreamStore/StreamStore";

const useStreamData = () => {
  const { setStreamActiveItem, setIsUpdateStreamList } = useStreamStore();

  const moveToListingPage = () => {
    setStreamActiveItem([STREAM_TAB_TYPE.LIVE_STREAM_LIST]);
    setIsUpdateStreamList(true);
  };

  return {
    moveToListingPage,
  };
};

export default useStreamData;
