import LiveStream from "../../../../../assets/images/New/liveStream.svg";
import CloseIcon from "../../../../../assets/images/New/close.svg";
import {
  STREAM_TAB_TYPE,
  TAB_TYPE,
} from "../../../../../Constants/Common/Data";
import useTabStore from "../../../../../Store/TabStore/TabStore";
import "./Header.scss";
import { AddIcon } from "../../../../Common/Icon";
import useStreamStore from "../../../../../Store/StreamStore/StreamStore";
import { isBlank } from "../../../../../Common/helper/helper";
import toast from "react-hot-toast";
import { MESSAGE } from "../../../../../Constants/Messages";
import { StreamHeaderProps } from "../../../../../Common/interface/types";
import usePermissionStore from "../../../../../Store/PermissionStore/PermissionStore";

const Header = (props: StreamHeaderProps) => {
  const { handleOpenAutoDetectForm } = props;
  const { toggleActiveItem } = useTabStore((state: any) => ({
    toggleActiveItem: state.toggleActiveItem,
  }));

  const {
    activeStreamItems,
    selectedVideoInfo,
    toggleStreamActiveItem,
    setStreamActiveItem,
  } = useStreamStore();

  const { liveFieldPermission } = usePermissionStore();

  const toggleTab = (tab: string) => {
    if (isBlank(selectedVideoInfo)) {
      toast.error(MESSAGE.WARNING.selectVideo);
      return;
    }

    const isActive = (tabType: string) => activeStreamItems.includes(tabType);
    const deactivate = (tabType: string) =>
      isActive(tabType) && toggleStreamActiveItem(tabType);

    if (tab === STREAM_TAB_TYPE.LIVE_STREAM_EDITOR) {
      if (isActive(STREAM_TAB_TYPE.LIVE_STREAM_EDITOR)) {
        if (activeStreamItems.length === 1) {
          setStreamActiveItem([STREAM_TAB_TYPE.LIVE_STREAM_LIST]);
        } else {
          toggleStreamActiveItem(tab);
        }
      } else {
        setStreamActiveItem([STREAM_TAB_TYPE.LIVE_STREAM_EDITOR]);
      }
      return;
    }

    if (tab === STREAM_TAB_TYPE.SCRIPT_EDITOR) {
      deactivate(STREAM_TAB_TYPE.TEXT_EDITOR);
      deactivate(STREAM_TAB_TYPE.LIVE_STREAM_LIST);
      deactivate(STREAM_TAB_TYPE.TRANSCRIPT_EDITOR);
    } else if (tab === STREAM_TAB_TYPE.TEXT_EDITOR) {
      deactivate(STREAM_TAB_TYPE.SCRIPT_EDITOR);
      deactivate(STREAM_TAB_TYPE.TRANSCRIPT_EDITOR);
      deactivate(STREAM_TAB_TYPE.LIVE_STREAM_LIST);
    } else if (tab === STREAM_TAB_TYPE.TRANSCRIPT_EDITOR) {
      deactivate(STREAM_TAB_TYPE.SCRIPT_EDITOR);
      deactivate(STREAM_TAB_TYPE.TEXT_EDITOR);
      deactivate(STREAM_TAB_TYPE.LIVE_STREAM_LIST);
    }

    toggleStreamActiveItem(tab);
  };

  return (
    <div className="header">
      <div>
        <img src={LiveStream} width={"24px"} alt="Live-Stream" />
      </div>
      <div className="tab-outer">
        <div className="header-container">
          <div
            onClick={() =>
              setStreamActiveItem([STREAM_TAB_TYPE?.LIVE_STREAM_LIST])
            }
            className={`header-btn ${
              activeStreamItems.includes(STREAM_TAB_TYPE?.LIVE_STREAM_LIST)
                ? "media"
                : ""
            }`}
          >
            <span>Media</span>
          </div>
          {liveFieldPermission.LISTING && (
            <>
              <div
                onClick={() => toggleTab(STREAM_TAB_TYPE?.LIVE_STREAM_EDITOR)}
                className={`header-btn ${
                  activeStreamItems.includes(
                    STREAM_TAB_TYPE?.LIVE_STREAM_EDITOR
                  )
                    ? "editor"
                    : ""
                } `}
              >
                <img src={LiveStream} alt="Live-Stream" />
                <span>Video</span>
              </div>
              <div
                className={`header-btn ${
                  activeStreamItems.includes(STREAM_TAB_TYPE?.TEXT_EDITOR)
                    ? "shortlist"
                    : ""
                } `}
                onClick={() => toggleTab(STREAM_TAB_TYPE?.TEXT_EDITOR)}
              >
                <span>Shotlist</span>
              </div>
              <div
                onClick={() => toggleTab(STREAM_TAB_TYPE?.TRANSCRIPT_EDITOR)}
                className={`header-btn ${
                  activeStreamItems.includes(STREAM_TAB_TYPE?.TRANSCRIPT_EDITOR)
                    ? "transcript"
                    : ""
                }`}
              >
                <span>Transcript</span>
              </div>
              <div
                onClick={() => toggleTab(STREAM_TAB_TYPE?.SCRIPT_EDITOR)}
                className={`header-btn ${
                  activeStreamItems.includes(STREAM_TAB_TYPE?.SCRIPT_EDITOR)
                    ? "script"
                    : ""
                }`}
              >
                <span>Script</span>
              </div>
            </>
          )}
        </div>
        {liveFieldPermission.CREATE && (
          <div className="action">
            <div className="new-stream-btn" onClick={handleOpenAutoDetectForm}>
              <AddIcon color={"#bbbbbb"} />
              NEW STREAM
            </div>
            {/* <div className="save-all-btn">SAVE ALL</div> */}
            <div
              className="close-btn"
              onClick={() => toggleActiveItem(TAB_TYPE.LIVE_STREAM)}
            >
              <img src={CloseIcon} alt="close" />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Header;
