import { create } from "zustand";

const FtpFileUploadStore = (set: any) => ({
  showFtpFileUpload: false,
  resourceIdForUpload: "",
  cancelToken: null,

  setShowFtpFileUpload: (value: boolean) =>
    set((state: any) => ({
      showFtpFileUpload: value,
    })),

  setResourceIdForUpload: (id: string) =>
    set((state: any) => ({
      resourceIdForUpload: id,
    })),

  setCancelToken: (value: any) =>
    set((state: any) => ({
      cancelToken: value,
    })),
});

const useFtpFileUploadStore = create(FtpFileUploadStore);

export default useFtpFileUploadStore;
