import { toast } from "react-hot-toast";

class Toast {
  static showSuccess(message: string) {
    toast.success(message);
  }

  static showError(message: string) {
    toast.error(message);
  }

  static showCustom(message: string, options = {}) {
    toast(message, options);
  }
}

export default Toast;
