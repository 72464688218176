const {
  REACT_APP_API_URL,
  REACT_APP_COOKIES_KEY,
  REACT_APP_SUPABASE_URL,
  REACT_APP_SUPABASE_KEY,
  REACT_APP_LIVE_VIDEO_LOAD_TIME,
  REACT_APP_ACTIVE_LIVE_VIDEO_COUNT,
} = process.env;

export const API_URL = REACT_APP_API_URL || "";
export const COOKIES_KEY = REACT_APP_COOKIES_KEY || "";
export const SUPABASE_URL = REACT_APP_SUPABASE_URL || "";
export const SUPABASE_KEY = REACT_APP_SUPABASE_KEY || "";
export const LIVE_VIDEO_LOAD_TIME = REACT_APP_LIVE_VIDEO_LOAD_TIME || ""; // in min
export const ACTIVE_LIVE_VIDEO_COUNT = REACT_APP_ACTIVE_LIVE_VIDEO_COUNT || "";
