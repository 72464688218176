import React from "react";
import { Modal, ModalBody } from "reactstrap";
import DeleteIcon from '../../../../assets/images/New/Delete.svg';
import { DeleteModalProps } from "../../../../Common/interface/types";

const DeleteModal: React.FC<DeleteModalProps> = ({ show, onDeleteClick, onCloseClick, recordId, deleteType }) => {

  return (
    <Modal fade={true} isOpen={show} toggle={onCloseClick} centered={true}>
      <ModalBody className="py-3 px-5">
        <div className="mt-2 text-center">
          {deleteType ?
            <div>
              <i style={{ fontSize: '24px', color: '#ced4da' }} className="ri-time-fill"></i>
            </div>
            :
            <div style={{ marginTop: "2px", fontSize: "12px", color: "red" }}>
              <img src={DeleteIcon} alt='delete' />
            </div>
          }
          <div className="mt-4 pt-2 fs-15 mx-4 mx-sm-5">
            <h4>Are you sure ?</h4>
            <p className="text-muted mx-4 mb-0">
              Are you sure you want to {deleteType ? 'expire' : 'remove'} this Link ?
            </p>
          </div>
        </div>
        <div className="d-flex gap-2 justify-content-center mt-4 mb-2">
          <button
            type="button"
            className="btn w-sm btn-light"
            data-bs-dismiss="modal"
            onClick={onCloseClick}
          >
            Cancel
          </button>
          <button
            type="button"
            className="btn w-sm btn-danger "
            id="delete-record"
            onClick={() => onDeleteClick && recordId !== undefined && onDeleteClick(recordId)}
          >
            Yes, {deleteType ? 'expire' : 'remove'} It!
          </button>
        </div>
      </ModalBody>
    </Modal>
  )
};

export default DeleteModal;