import { useState } from "react";
import { Tooltip } from "reactstrap";
import { shortName } from "../../../../Common/helper/helper";

const TitleIcon = ({ index, item }: any) => {
  const [tooltipOpen, setTooltipOpen] = useState(false);

  const toggleTooltip = () => {
    setTooltipOpen(!tooltipOpen);
  };

  return (
    <div>
      <div
        className="name"
        id={`icon-${index}`}
        onMouseEnter={toggleTooltip}
        onMouseLeave={toggleTooltip}
      >
        {shortName(item?.title, 25)}
      </div>
      <Tooltip
        placement="bottom"
        isOpen={tooltipOpen}
        autohide={true}
        target={`icon-${index}`}
        toggle={toggleTooltip}
      >
        {item.title} {item?.fileName ? `(${item?.fileName})` : ""}
      </Tooltip>
    </div>
  );
};

export default TitleIcon;
