import React, { useEffect, useState } from "react";

//import Components
import Header from "./Header";
import Sidebar from "./Sidebar";
// import Footer from './Footer';
import useLayoutStore from "../Store/layouts/layouts";
import withRouter from "../Components/Common/withRouter";
import { customSideBarShow } from "../Constants/layout";
import RightSidebar from "../Components/Common/RightSidebar";
import useUserStore from "../Store/UserStore/UserStore";
import useTranscriptionStore from "../Store/TranscriptionStore/TranscriptionStore";

const Layout = (props: any) => {
  const [headerClass, setHeaderClass] = useState("");
  const [showRightSection, setShowRightSection] = useState(false);

  const { currentUser } = useUserStore((state: any) => ({
    currentUser: state.currentUser,
  }));

  const { transcriptionVideoList } = useTranscriptionStore();

  const { layoutType,
    leftSidebarType,
    layoutModeType,
    layoutWidthType,
    layoutPositionType,
    topbarThemeType,
    leftsidbarSizeType,
    leftSidebarViewType,
    leftSidebarImageType,
    sidebarVisibilitytype,
    changeLeftsidebarViewType,
    changeLeftsidebarSizeType,
    changeSidebarTheme,
    changeLayoutMode,
    changeLayoutWidth,
    changeLayoutPosition,
    changeTopbarTheme,
    changeLayout,
    changeSidebarImageType,
    changeSidebarVisibility,
    sidebarBarShow,
  } = useLayoutStore((state: any) => ({
    layoutType: state.layoutType,
    leftSidebarType: state.leftSidebarType,
    layoutModeType: state.layoutModeType,
    layoutWidthType: state.layoutWidthType,
    layoutPositionType: state.layoutPositionType,
    topbarThemeType: state.topbarThemeType,
    leftsidbarSizeType: state.leftsidbarSizeType,
    leftSidebarViewType: state.leftSidebarViewType,
    leftSidebarImageType: state.leftSidebarImageType,
    sidebarVisibilitytype: state.sidebarVisibilitytype,
    changeLeftsidebarViewType: state.changeLeftsidebarViewType,
    changeLeftsidebarSizeType: state.changeLeftsidebarSizeType,
    changeSidebarTheme: state.changeSidebarTheme,
    changeLayoutMode: state.changeLayoutMode,
    changeLayoutWidth: state.changeLayoutWidth,
    changeLayoutPosition: state.changeLayoutPosition,
    changeTopbarTheme: state.changeTopBarTheme,
    changeLayout: state.changeLayout,
    changeSidebarImageType: state.changeSidebarImageType,
    changeSidebarVisibility: state.changeSidebarVisibility,
    sidebarBarShow: state.sidebarBarShow,
  }));

  /*
   layout settings
   */
  useEffect(() => {
    if (
      layoutType ||
      leftSidebarType ||
      layoutModeType ||
      layoutWidthType ||
      layoutPositionType ||
      topbarThemeType ||
      leftsidbarSizeType ||
      leftSidebarViewType ||
      leftSidebarImageType ||
      sidebarVisibilitytype
    ) {
      window.dispatchEvent(new Event("resize"));
      changeLeftsidebarViewType(leftSidebarViewType);
      changeLeftsidebarSizeType(leftsidbarSizeType);
      changeSidebarTheme(leftSidebarType);
      changeLayoutMode(layoutModeType);
      changeLayoutWidth(layoutWidthType);
      changeLayoutPosition(layoutPositionType);
      changeTopbarTheme(topbarThemeType);
      changeLayout(layoutType);
      changeSidebarImageType(leftSidebarImageType);
      changeSidebarVisibility(sidebarVisibilitytype);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    layoutType,
    leftSidebarType,
    layoutModeType,
    layoutWidthType,
    layoutPositionType,
    topbarThemeType,
    leftsidbarSizeType,
    leftSidebarViewType,
    leftSidebarImageType,
    sidebarVisibilitytype,
  ]);

  useEffect(() => {
    if (currentUser && currentUser?.configSetting) {
      setShowRightSection(currentUser?.configSetting?.isLayoutChangeAllow || false);
    } else {
      setShowRightSection(false);
    }
  }, [currentUser]);

  // class add remove in header
  useEffect(() => {
    window.addEventListener("scroll", scrollNavigation, true);
  });

  function scrollNavigation() {
    var scrollup = document.documentElement.scrollTop;
    if (scrollup > 50) {
      setHeaderClass("topbar-shadow");
    } else {
      setHeaderClass("");
    }
  }

  useEffect(() => {
    const hamburgerIcon = document.querySelector(".hamburger-icon");
    if (
      sidebarVisibilitytype === "show" ||
      layoutType === "vertical" ||
      layoutType === "twocolumn"
    ) {
      if (hamburgerIcon != null) {
        hamburgerIcon.classList.remove("open");
      }
    } else {
      if (hamburgerIcon != null) hamburgerIcon.classList.add("open");
    }
  }, [sidebarVisibilitytype, layoutType]);

  return (
    <React.Fragment>
      <div id="layout-wrapper">
        <Header headerClass={headerClass} />
        {sidebarBarShow === customSideBarShow.SHOW && (
          <Sidebar layoutType={layoutType} />
        )}
        <div
          className={`main-content ${sidebarBarShow === customSideBarShow.SHOW ? "show" : ""
            }`}
        >
          {props.children}
          {/* <Footer /> */}
        </div>
      </div>
      {showRightSection && transcriptionVideoList && transcriptionVideoList.length > 0 && <RightSidebar />}
    </React.Fragment>

  );
};

export default withRouter(Layout);