import { useState } from "react";
import { Tooltip } from "reactstrap";

const UserNameToolTip = ({ index, item }: any) => {
  const [tooltipOpen, setTooltipOpen] = useState(false);

  const toggleTooltip = () => {
    setTooltipOpen(!tooltipOpen);
  };

  return (
    <div>
      <div
        className="user-name"
        id={`icon-${index}`}
        onMouseEnter={toggleTooltip}
        onMouseLeave={toggleTooltip}
      >
       {item.firstName.toUpperCase()}
        <span>{item.lastName && " " + item.lastName.toUpperCase()}</span>
      </div>
      <Tooltip
        placement="bottom"
        isOpen={tooltipOpen}
        autohide={true}
        target={`icon-${index}`}
        toggle={toggleTooltip}
      >
        {item.firstName.toUpperCase()}
        <span>{item.lastName && " " + item.lastName.toUpperCase()}</span>
      </Tooltip>
    </div>
  );
};

export default UserNameToolTip;
