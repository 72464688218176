import { useEffect, useRef, useState } from "react";
import videojs from "video.js";
import "@videojs/http-streaming";
import "video.js/dist/video-js.css";
import "videojs-sprite-thumbnails";
import PlayIcon from "../../../../assets/images/New/Play.svg";
import MarkOutIcon from "../../../../assets/images/New/MarkOut.svg";
import MarkInIcon from "../../../../assets/images/New/MarkIn.svg";
import PauseIcon from "../../../../assets/images/New/Pause.png";
import { LIVE_VIDEO_STATUS } from "../../../../Constants/Common/Data";
import { BackWardIcon, ForwardIcon } from "../../../Common/Icon";
import useStreamStore from "../../../../Store/StreamStore/StreamStore";
import toast from "react-hot-toast";

const VideoControls = (props: any) => {
  const {
    onClickPlayPause,
    // onClick,
    isPlaying,
    // onClickCut,
    backwardBtnDisable,
    forwardBtnDisable,
    handlePrevBtn,
    handleNextBtn,
  } = props;
  return (
    <div className="video-action small">
      {/* <div className="btn">
        <span style={{ color: "white" }}>Cut</span>
        <img src={CutIcon} onClick={() => onClickCut()} alt="Cut" />
      </div>*/}
      <div className="btn mark">
        <img src={MarkInIcon} alt="Mark In" />
        <span style={{ color: "white" }}>Mark In</span>
      </div>
      <div
        className={`btn live ${backwardBtnDisable ? "disable" : ""}`}
        onClick={() => handlePrevBtn()}
      >
        <BackWardIcon />
      </div>
      <div className="btn play" onClick={() => onClickPlayPause()}>
        {isPlaying ? (
          <img src={PlayIcon} alt="Play" />
        ) : (
          <img src={PauseIcon} alt="Pause" />
        )}
      </div>
      <div
        className={`btn live ${forwardBtnDisable ? "disable" : ""}`}
        onClick={() => handleNextBtn()}
      >
        <ForwardIcon />
      </div>
      <div className="btn mark">
        <span style={{ color: "white" }}>Mark Out</span>
        <img src={MarkOutIcon} alt="Mark Out" />
      </div>
      {/* <div className="btn">
        <span style={{ color: "white" }}>Delete</span>
        <img src={DeleteIcon} onClick={() => onClick()} alt="Delete" />
      </div> */}
    </div>
  );
};

const VideoPlayerComponent = (props: any) => {
  const { options, widthCss, videoStatus } = props;
  const videoRef = useRef<any>(null);
  const playerRef = useRef<any>(null);
  const [isPlaying, setIsPlaying] = useState<Boolean>(false);
  const [videoTotalDuration, setVideoTotalDuration] = useState(0);

  const {
    selectedVideoInfo,
    setLiveVideoPlayerTime,
    setVideoLoadStatus,
    setPlayerReference,
    setIsProgressBarClick,
    isProgressBarClick,
  } = useStreamStore((state: any) => ({
    selectedVideoInfo: state.selectedVideoInfo,
    setLiveVideoPlayerTime: state.setLiveVideoPlayerTime,
    setVideoLoadStatus: state.setVideoLoadStatus,
    setPlayerReference: state.setPlayerReference,
    setIsProgressBarClick: state.setIsProgressBarClick,
    isProgressBarClick: state.isProgressBarClick,
  }));

  useEffect(() => {
    if (!playerRef.current) {
      const videoElement = document.createElement("video-js");

      videoElement.classList.add("vjs-big-play-centered");
      videoRef.current.appendChild(videoElement);

      const player: any = (playerRef.current = videojs(
        videoElement,
        options,
        () => {
          videojs.log("player is ready");

          player.on("loadedmetadata", () => {
            const duration = player.duration();
            setVideoTotalDuration(duration);
            setVideoLoadStatus(true);
          });

          player.on(["pause", "ended"], () => {
            setIsPlaying(true);
          });

          player.on("error", (err: any) => {
            console.log(err);
          });

          player.on("ended", (err: any) => {
            console.log(err);
            console.log("Live stream has ended!");
            toast(
              `Live stream ${selectedVideoInfo?.title} has ended and stopped from sources. The stream will now be saved as a file.`
            );
          });

          player.on("timeupdate", () => {
            const time = player.currentTime();
            setTime(time);
          });

          player.controlBar.progressControl.on("mousedown", (event: any) => {
            // console.log(" current:" + player.currentTime());
            // eslint-disable-next-line react-hooks/exhaustive-deps
            setIsProgressBarClick(true);
          });

          player.on(["play"], () => {
            setIsPlaying(false);
          });
        }
      ));
      setPlayerReference(player);
    } else {
      const player = playerRef.current;

      player.autoplay(options.autoplay);
      player.src(options.sources);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [videoRef, playerRef]);

  // Dispose the Video.js player when the functional component unmounts
  useEffect(() => {
    const player = playerRef.current;

    return () => {
      if (player && !player.isDisposed()) {
        player.dispose();
        playerRef.current = null;
        setPlayerReference(null);
        setVideoLoadStatus(false);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [playerRef]);

  const onClickPlayPause = () => {
    if (playerRef?.current) {
      isPlaying ? playerRef?.current?.play() : playerRef.current.pause();
      setIsPlaying(!isPlaying);
    }
  };

  const setTime = (time: any) => {
    const tempTime = isProgressBarClick ? time + 2 : time;
    setLiveVideoPlayerTime(tempTime);
  };

  const onClickCut = () => {
    console.log("on click cut");
  };

  const handleSeekForward = () => {
    seekVideo(5);
  };

  const handleSeekBackward = () => {
    seekVideo(-5);
  };

  const seekVideo = (seconds: number) => {
    if (playerRef.current) {
      const currentTime = playerRef.current.currentTime();
      const newTime = currentTime + seconds;

      // Ensure the new time is within the valid range (0 to videoTotalDuration)
      const clampedTime = Math.max(0, Math.min(newTime, videoTotalDuration));

      playerRef.current.currentTime(clampedTime);
    }
  };

  return (
    <>
      <div className={`video-player-outer ${widthCss ? "small" : ""}`}>
        {videoStatus && videoStatus === LIVE_VIDEO_STATUS.LIVE && (
          <div className="video-status">Live</div>
        )}
        <div className="player-outer">
          <div data-vjs-player>
            <div ref={videoRef} />
          </div>
        </div>
        <VideoControls
          onClickPlayPause={onClickPlayPause}
          onClickCut={onClickCut}
          isPlaying={isPlaying}
          handlePrevBtn={handleSeekBackward}
          handleNextBtn={handleSeekForward}
          backwardBtnDisable={false}
          forwardBtnDisable={false}
        />
      </div>
    </>
  );
};

export default VideoPlayerComponent;
