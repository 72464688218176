import React, { useEffect, useState } from "react";
import InfoIcon from "../../../../../assets/images/New/DeletePopupIcon.svg";
import ButtonComponent from "../../ButtonComponent";
import useStreamStore from "../../../../../Store/StreamStore/StreamStore";
import { Modal, ModalBody, Progress } from "reactstrap";
import { MESSAGE } from "../../../../../Constants/Messages";
import "react-circular-progressbar/dist/styles.css";
import { PAUSE_MODAL_TIME_LIMIT } from "../../../../../Constants/Common/Data";

const contentStyle = {
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  textAlign: "center",
};

const titleStyle = {
  fontWeight: "700",
  fontSize: "15px",
  width: "100%",
  wordWrap: "break-word" as "normal" | "break-word",
};

const PauseModal = () => {
  const [counter, setCounter] = useState(0);
  const [seconds, setSeconds] = useState(0);
  const [closeBtnStatus, setCloseBtnStatus] = useState(false);

  const { pauseModalStatus, setPauseModalStatus } = useStreamStore();

  useEffect(() => {
    if (pauseModalStatus && seconds <= PAUSE_MODAL_TIME_LIMIT) {
      const interval = setInterval(() => {
        setCounter((prevCounter) => prevCounter + 100 / PAUSE_MODAL_TIME_LIMIT);
        setSeconds((prevSeconds) => prevSeconds + 1);
      }, 1000);

      // Cleaning up the interval on component unmount or when `seconds` reaches 30
      return () => clearInterval(interval);
    }
  }, [seconds, pauseModalStatus]);

  useEffect(() => {
    if (seconds === PAUSE_MODAL_TIME_LIMIT) {
      setCloseBtnStatus(true);
    }
  }, [seconds]);

  useEffect(() => {
    if (!pauseModalStatus) {
      clear();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pauseModalStatus]);

  const clear = () => {
    setPauseModalStatus(false);
    setCloseBtnStatus(false);
    setCounter(0);
    setSeconds(0);
  };

  const handleClose = () => {
    clear();
  };
  return (
    <Modal
      fade={true}
      centered={true}
      className={`cancel-modal-outer`}
      returnFocusAfterClose={true}
      isOpen={pauseModalStatus}
    >
      <ModalBody>
        <div style={contentStyle as React.CSSProperties}>
          <img style={{ marginBottom: "10px" }} src={InfoIcon} alt="info" />
          <p style={titleStyle}>
            {seconds >= PAUSE_MODAL_TIME_LIMIT
              ? MESSAGE.CONTENT.dataSaved
              : MESSAGE.CONTENT.saveData}
          </p>
          {!closeBtnStatus && (
            <>
              <div className="mb-2">{counter.toFixed(2)} %</div>
              <Progress
                className="pause-progress"
                color="success"
                value={counter}
              />
            </>
          )}
          {closeBtnStatus && (
            <ButtonComponent
              onClick={handleClose}
              title="Close"
              style={{
                backgroundColor: "white",
                color: "black",
                fontWeight: "700",
                width: "100px",
              }}
            />
          )}
        </div>
      </ModalBody>
    </Modal>
  );
};

export default PauseModal;
