import { create } from "zustand";

const tabStore = (set: any) => ({
  activeItems: [],
  windowSmallSize: false,
  fileActive: false,

  toggleActiveItem: (item: any) =>
    set((state: any) => {
      const isActive = state.activeItems.includes(item);
      const updatedItems = isActive
        ? state.activeItems.filter((activeItem: any) => activeItem !== item)
        : [...state.activeItems, item];
      return { activeItems: updatedItems };
    }),

  setActiveItem: (item: any[]) =>
    set((state: any) => {
      const updatedItems: any[] = [];
      item.forEach((item: any) => {
        updatedItems.push(item);
      });
      return { activeItems: updatedItems };
    }),

  inActiveItem: (item: any) =>
    set((state: any) => {
      const updatedItems = state.activeItems.filter(
        (activeItem: any) => activeItem !== item
      );
      return { activeItems: updatedItems };
    }),

  setWindowSmall: (status: boolean) =>
    set((state: any) => ({
      windowSmallSize: status,
    })),

  setFileActive: (status: boolean) =>
    set(() => ({
      fileActive: status,
    })),
});

const useTabStore = create(tabStore);

export default useTabStore;
