export const LangListForWhisper = [
  { value: "af", label: "AFRIKAANS" },
  { value: "sq", label: "ALBANIAN" },
  { value: "am", label: "AMHARIC" },
  { value: "ar", label: "ARABIC" },
  { value: "hy", label: "ARMENIAN" },
  { value: "as", label: "ASSAMESE" },
  { value: "az", label: "AZERBAIJANI" },
  { value: "ba", label: "BASHKIR" },
  { value: "eu", label: "BASQUE" },
  { value: "be", label: "BELARUSIAN" },
  { value: "bn", label: "BENGALI" },
  { value: "bs", label: "BOSNIAN" },
  { value: "br", label: "BRETON" },
  { value: "bg", label: "BULGARIAN" },
  { value: "yue", label: "CANTONESE" },
  { value: "ca", label: "CATALAN" },
  { value: "zh", label: "CHINESE" },
  { value: "hr", label: "CROATIAN" },
  { value: "cs", label: "CZECH" },
  { value: "da", label: "DANISH" },
  { value: "nl", label: "DUTCH" },
  { value: "en", label: "ENGLISH" },
  { value: "et", label: "ESTONIAN" },
  { value: "fo", label: "FAROESE" },
  { value: "fi", label: "FINNISH" },
  { value: "fr", label: "FRENCH" },
  { value: "gl", label: "GALICIAN" },
  { value: "ka", label: "GEORGIAN" },
  { value: "de", label: "GERMAN" },
  { value: "el", label: "GREEK" },
  { value: "gu", label: "GUJARATI" },
  { value: "ht", label: "HAITIAN CREOLE" },
  { value: "ha", label: "HAUSA" },
  { value: "haw", label: "HAWAIIAN" },
  { value: "he", label: "HEBREW" },
  { value: "hi", label: "HINDI" },
  { value: "hu", label: "HUNGARIAN" },
  { value: "is", label: "ICELANDIC" },
  { value: "id", label: "INDONESIAN" },
  { value: "it", label: "ITALIAN" },
  { value: "ja", label: "JAPANESE" },
  { value: "jw", label: "JAVANESE" },
  { value: "kn", label: "KANNADA" },
  { value: "kk", label: "KAZAKH" },
  { value: "km", label: "KHMER" },
  { value: "ko", label: "KOREAN" },
  { value: "lo", label: "LAO" },
  { value: "la", label: "LATIN" },
  { value: "lv", label: "LATVIAN" },
  { value: "ln", label: "LINGALA" },
  { value: "lt", label: "LITHUANIAN" },
  { value: "lb", label: "LUXEMBOURGISH" },
  { value: "mk", label: "MACEDONIAN" },
  { value: "mg", label: "MALAGASY" },
  { value: "ms", label: "MALAY" },
  { value: "ml", label: "MALAYALAM" },
  { value: "mt", label: "MALTESE" },
  { value: "mi", label: "MAORI" },
  { value: "mr", label: "MARATHI" },
  { value: "mn", label: "MONGOLIAN" },
  { value: "my", label: "MYANMAR" },
  { value: "ne", label: "NEPALI" },
  { value: "no", label: "NORWEGIAN" },
  { value: "nn", label: "NYNORSK" },
  { value: "oc", label: "OCCITAN" },
  { value: "ps", label: "PASHTO" },
  { value: "fa", label: "PERSIAN" },
  { value: "pl", label: "POLISH" },
  { value: "pt", label: "PORTUGUESE" },
  { value: "pa", label: "PUNJABI" },
  { value: "ro", label: "ROMANIAN" },
  { value: "ru", label: "RUSSIAN" },
  { value: "sa", label: "SANSKRIT" },
  { value: "sr", label: "SERBIAN" },
  { value: "sn", label: "SHONA" },
  { value: "sd", label: "SINDHI" },
  { value: "si", label: "SINHALA" },
  { value: "sk", label: "SLOVAK" },
  { value: "sl", label: "SLOVENIAN" },
  { value: "so", label: "SOMALI" },
  { value: "es", label: "SPANISH" },
  { value: "su", label: "SUNDANESE" },
  { value: "sw", label: "SWAHILI" },
  { value: "sv", label: "SWEDISH" },
  { value: "tl", label: "TAGALOG" },
  { value: "tg", label: "TAJIK" },
  { value: "ta", label: "TAMIL" },
  { value: "tt", label: "TATAR" },
  { value: "te", label: "TELUGU" },
  { value: "th", label: "THAI" },
  { value: "bo", label: "TIBETAN" },
  { value: "tr", label: "TURKISH" },
  { value: "tk", label: "TURKMEN" },
  { value: "uk", label: "UKRAINIAN" },
  { value: "ur", label: "URDU" },
  { value: "uz", label: "UZBEK" },
  { value: "vi", label: "VIETNAMESE" },
  { value: "cy", label: "WELSH" },
  { value: "yi", label: "YIDDISH" },
  { value: "yo", label: "YORUBA" },
];
