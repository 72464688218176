import useStreamStore from "../../../../../Store/StreamStore/StreamStore";
import ButtonComponent from "../../ButtonComponent";
import StreamListSection from "../StreamListSection";
import AddIcon from "../../../../../assets/images/New/Add.svg";
import DeleteIcon from "../../../../../assets/images/New/Delete.svg";
import "./StreamListOuter.scss";
import { useEffect, useState } from "react";
import usePermissionStore from "../../../../../Store/PermissionStore/PermissionStore";
import { Input } from "reactstrap";
import { CloseIcon, SearchIcon } from "../../../../Common/Icon";
import { MESSAGE } from "../../../../../Constants/Messages";

const StreamListOuter = (props: any) => {
  const {
    handleOpenAutoDetectForm,
    handleDeleteModalOpen,
    handleShowStream,
    handleInfoEdit,
    setIsLoading,
    loadData,
  } = props;

  const [search, setSearch] = useState("");

  const {
    streamVideoList,
    selectedVideoIds,
    setSelectedVideoInfo,
    setSelectedVideoStatus,
    setSelectedVideoSubtitle,
    setSearchValue,
    searchValue,
    setSkipCount,
    setSelectedPage,
  } = useStreamStore((state: any) => ({
    streamVideoList: state.streamVideoList,
    selectedVideoIds: state.selectedVideoIds,
    setSelectedVideoInfo: state.setSelectedVideoInfo,
    setSelectedVideoStatus: state.setSelectedVideoStatus,
    setSelectedVideoSubtitle: state.setSelectedVideoSubtitle,
    setSearchValue: state.setSearchValue,
    searchValue: state.searchValue,
    setSkipCount: state.setSkipCount,
    setSelectedPage: state.setSelectedPage,
  }));

  useEffect(() => {
    if (searchValue && searchValue !== "") {
      setSearch(searchValue);
    }
  }, [searchValue]);

  const { liveFieldPermission } = usePermissionStore();

  useEffect(() => {
    setSelectedVideoInfo({});
    setSelectedVideoStatus(null);
    setSelectedVideoSubtitle({});
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSearchChange = (value: string) => {
    setSearch(value);
  };

  const handleSearch = () => {
    setSearchValue(search);
    setSkipCount(0);
    setSelectedPage(0);
  };

  const handleRemoveSearch = () => {
    setSearch("");
    setSearchValue("");
    setSkipCount(0);
    setSelectedPage(0);
  };

  return (
    <div className="stream-list-outer">
      <div className="wrapper">
        <div className="wrapper-action">
          {liveFieldPermission.CREATE && (
            <ButtonComponent
              className="stream-btn"
              onClick={() => handleOpenAutoDetectForm()}
              icon={AddIcon}
            />
          )}
          {liveFieldPermission.ARCHIVE && (
            <ButtonComponent
              className="stream-btn"
              onClick={() => handleDeleteModalOpen()}
              icon={DeleteIcon}
              disable={selectedVideoIds.length > 0 ? false : true}
            />
          )}
          {/* <ButtonComponent
            className='stream-btn'
            onClick={() => handleDownload()}
            icon={DownloadIcon}
            disable={selectedVideoIds.length > 0 ? false : true}
          /> */}
        </div>
        <div className="search-outer">
          <Input
            type="text"
            className="form-control"
            placeholder="Search"
            autoComplete="off"
            value={search}
            id="search-options"
            onChange={(e) => handleSearchChange(e.target.value)}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                handleSearch();
              }
            }}
          />
          {searchValue && searchValue.length > 0 && (
            <div className="cancel-search" onClick={handleRemoveSearch}>
              <CloseIcon />
            </div>
          )}
          <div className="search-btn" onClick={handleSearch}>
            <SearchIcon />
          </div>
        </div>
      </div>

      {/* Stream List Section */}
      {streamVideoList?.result && streamVideoList?.result?.length > 0 ? (
        <StreamListSection
          handleShowStream={handleShowStream}
          handleInfoEdit={handleInfoEdit} //autoDetect Form Open start
          setIsLoading={setIsLoading}
          loadData={loadData}
        />
      ) : (
        <div className="add-srt-button-outer">
          {searchValue === "" && liveFieldPermission.CREATE && (
            <div className="add-srt-button">
              <h4>
                Click on the{" "}
                <span style={{ color: "rgba(172, 75, 190, 1)" }}>+</span> to add
                a new SRT stream to your collection
              </h4>
            </div>
          )}
          {searchValue === "" && !liveFieldPermission.CREATE && (
            <div className="not-found">
              <h4>{MESSAGE.CONTENT.listNotAvailable}</h4>
            </div>
          )}
          {searchValue !== "" && (
            <div className="not-found">
              <h4>{MESSAGE.CONTENT.searchNotFound}</h4>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default StreamListOuter;
