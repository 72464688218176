import React from "react";
import { Modal, ModalBody } from "reactstrap";

interface DeleteModalProps {
  show?: boolean;
  onDeleteClick?: (recordId: string) => void;
  onCloseClick?: () => void;
  recordId?: string;
  deleteType?: boolean;
}

const DeleteModal: React.FC<DeleteModalProps> = ({ show, onDeleteClick, onCloseClick, recordId, deleteType }) => {
  return (
    <Modal fade={true} isOpen={show} toggle={onCloseClick} centered={true}>
      <ModalBody className="py-3 px-5">
        <div className="mt-2 text-center">
          {deleteType ?
            <i className="ri-eye-fill display-5 text-danger"></i> :
            <i className="ri-eye-off-fill display-5 text-danger"></i>
          }
          <div className="mt-4 pt-2 fs-15 mx-4 mx-sm-5">
            <h4>Are you sure ?</h4>
            <p className="text-muted mx-4 mb-0">
              Are you sure you want to {deleteType ? 'activated' : 'deactivated'} this user ?
            </p>
          </div>
        </div>
        <div className="d-flex gap-2 justify-content-center mt-4 mb-2">
          <button
            type="button"
            className="btn w-sm btn-light"
            data-bs-dismiss="modal"
            onClick={onCloseClick}
          >
            Close
          </button>
          <button
            type="button"
            className="btn w-sm btn-danger "
            id="delete-record"
            onClick={() => onDeleteClick && recordId !== undefined && onDeleteClick(recordId)}
          >
            Yes, {deleteType ? 'activated' : 'deactivated'} It!
          </button>
        </div>
      </ModalBody>
    </Modal>
  ) as unknown as JSX.Element;
};

export default DeleteModal;