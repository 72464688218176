import React from "react";

const BlankMessageBox = () => {
  return (
    <div className="stream-processing">
      <div className="header">
        <h4>
          You don't have permission to access this. Please contact to Admin...
        </h4>
      </div>
    </div>
  );
};

export default BlankMessageBox;
