import "./App.css";
import "./assets/scss/themes.scss";
import { TOASTER_CONFIG } from "./Constants/Common/Data";
import Route from "./Routes";
import { Toaster } from "react-hot-toast";

function App() {
  return (
    <div className="App">
      <Toaster {...TOASTER_CONFIG} />
      <Route />
    </div>
  );
}

export default App;
