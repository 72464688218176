import React from 'react'
import VideoFileUploader from '../Components/uploadFile/UploadFile'

const FileUpload = () => {
  return (
    <div>
        <VideoFileUploader/>
      
    </div>
  )
}

export default FileUpload
