import { Modal, ModalBody } from "reactstrap";
import "./CancelModal.scss";
import { CancelModelProps } from "../../../../Common/interface/types";
import CloseIcon from "../../../../assets/images/New/close.svg";

const CancelModal = (props: CancelModelProps) => {
  const { show, title, onCloseClick, onSubmitClick } = props;
  return (
    <Modal
      fade={true}
      isOpen={show}
      className="cancel-modal-outer"
      toggle={onCloseClick}
      centered={true}
    >
      <ModalBody className="cancel-modal">
        <div className="close">
          <img src={CloseIcon} className="cursor-pointer" alt="close" />
        </div>
        <div className="header">
          <div className="title">Do you want to cancel this {title}?</div>
        </div>
        <div className="d-flex gap-2 justify-content-center mt-4 button-outer">
          <button
            type="button"
            className="btn custom-btn-close"
            data-bs-dismiss="modal"
            onClick={onSubmitClick}
          >
            Yes
          </button>
          <button
            type="button"
            className="btn custom-btn-submit"
            id="delete-record"
            onClick={onCloseClick}
          >
            No
          </button>
        </div>
      </ModalBody>
    </Modal>
  );
};

export default CancelModal;
