export const VideoSegmentsTypeDefaultValue = {
  avg_logprob: 0,
  compression_ratio: 0,
  end: 0,
  id: 0,
  no_speech_prob: 0,
  seek: 0,
  start: 0,
  temperature: 0,
  text: "",
  tokens: [],
};

export const uploadVideoDetailsDefaultValues = {
  id: null,
  uploadDetail: { time: 0, date: "", uploadedBy: "", percentage: 0 },
  compressedDetail: {
    time: 0,
    date: "",
    format: "",
    percentage: 0,
    originalFileType: "",
    compressedFileType: "",
  },
  transcriptionDetail: {
    status: 0,
    time: 0,
    date: "",
    autoDetectLanguage: "",
    reprocessed: "",
    percentage: 0,
    reprocessedLang: null,
    reprocessedCreatedAt: null,
  },
};

export const LiveVideoAddFormDataDefaultValue = {
  title: "",
  liveVideoUrl: "",
  language: "",
  videoChannel: "",
  audioChannel: "",
  description: "",
  url: "",
  thumbnail: "",
  frameThumbnail: "",
};

export const LivePermissionDefaultValue = {
  CREATE: false,
  LISTING: false,
  AUTO_DETECT: false,
  UPDATE: false,
  ARCHIVE: false,
  CHANGE_STATUS: false,
  CHECK_STREAM: false,
  START_STREAM: false,
  GENERATE_SRT: false,
};
