import { create } from "zustand";

const userStore = (set: any) => ({
  currentUser: {},
  isLoading: false,

  setLoading: (loading: boolean) =>
    set((state: any) => ({
      isLoading: loading,
    })),
  setCurrentUser: (userDetails: any) =>
    set((state: any) => ({
      currentUser: userDetails,
    })),
});

const useUserStore = create(userStore);

export default useUserStore;
