import React from "react";
import ModalLayout from "../../../../Common/ModalLayout";
import InfoIcon from "../../../../../assets/images/New/DeletePopupIcon.svg";
import ButtonComponent from "../../ButtonComponent";
import useStreamStore from "../../../../../Store/StreamStore/StreamStore";

const contentStyle = {
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  textAlign: "center",
};

const titleStyle = {
  fontWeight: "700",
  fontSize: "15px",
  width: "100%",
  wordWrap: "break-word" as "normal" | "break-word",
};

const InfoModal = () => {
  const {
    infoModalStatus,
    infoMessage,
    setInfoModalStatus,
    setInfoModalMessage,
  } = useStreamStore();

  const handleClose = () => {
    setInfoModalMessage("");
    setInfoModalStatus(false);
  };
  return (
    <ModalLayout
      isShowing={infoModalStatus}
      style={{ background: "rgba(26, 26, 26, 1)" }}
      hideCloseButton
    >
      <div style={contentStyle as React.CSSProperties}>
        <img style={{ marginBottom: "10px" }} src={InfoIcon} alt="info" />
        <p style={titleStyle}>{infoMessage}</p>
        <ButtonComponent
          onClick={handleClose}
          title="Okay"
          style={{
            backgroundColor: "white",
            color: "black",
            fontWeight: "700",
            width: "100px",
          }}
        />
      </div>
    </ModalLayout>
  );
};

export default InfoModal;
