import React, { useEffect, useState } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import ButtonComponent from "../ButtonComponent";
import CloseIcon from "../../../../assets/images/New/close.svg";
import { createShortSummary } from "../../../../ApiService/ApiCall";
import SpinnerLoader from "../../../Common/Spinner/Spinner";
import { toast } from "react-hot-toast";
import useStreamStore from "../../../../Store/StreamStore/StreamStore";
import {
  ERROR_CODE,
  LIVE_SUBTITLE_TYPE,
  STREAM_TAB_TYPE,
} from "../../../../Constants/Common/Data";
import "./TextEditor.scss";
import { PencilIcon, SubtitleDownloadIcon } from "../../../Common/Icon";
import { Tooltip } from "reactstrap";
import useStreamData from "../Hooks/useStreamData";

const TextEditor = (props: any) => {
  const { toggleComponent, title = "Shotlist", type } = props;
  const [code, setCode] = useState<string>("");
  const [downloadType, setDownloadType] = useState<string>("");
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isEdit, setIsEdit] = useState(true);
  const [tooltipDownload, setTooltipDownload] = useState(false);

  const {
    selectedVideoInfo,
    selectedVideoSubtitle,
    setSelectedVideoSubtitle,
    setInfoModalStatus,
    setInfoModalMessage,
  } = useStreamStore((state: any) => ({
    selectedVideoInfo: state.selectedVideoInfo,
    selectedVideoSubtitle: state.selectedVideoSubtitle,
    setSelectedVideoSubtitle: state.setSelectedVideoSubtitle,
    setInfoModalStatus: state.setInfoModalStatus,
    setInfoModalMessage: state.setInfoModalMessage,
  }));

  const { moveToListingPage } = useStreamData();

  const modules = {
    toolbar: [
      // [{ header: [1, 2, 3, 4, 5, 6, false] }],
      [{ font: [] }],
      [{ size: [] }],
      ["bold", "italic", "underline", "strike", "blockquote"],
      [
        { align: "" },
        { align: "center" },
        { align: "right" },
        { align: "justify" },
      ],
      [{ list: "ordered" }, { list: "bullet" }],
      ["link"],
      [{ color: ["red", "#785412"] }],
      // [{ background: ["red", "#785412"] }]
    ],
  };
  const formats = [
    "header",
    "bold",
    "italic",
    "underline",
    "strike",
    "blockquote",
    "list",
    "bullet",
    "link",
    "background",
    "align",
    "size",
    "font",
  ];

  const handleProcedureContentChange = (content: any) => {
    setCode(content);
  };

  function getText(code: any) {
    var divContainer = document.createElement("div");
    divContainer.innerHTML = code;
    return divContainer.textContent || divContainer.innerText || "";
  }

  useEffect(() => {
    // Download Txt Code
    if (downloadType === "txt") {
      const url = window.URL.createObjectURL(
        new Blob([getText(code)], { type: "text/plain;charset=utf-8" })
      );
      const link = document.createElement("a");
      link.href = url;
      const name =
        type === STREAM_TAB_TYPE?.TEXT_EDITOR ? "shortlist" : "description";
      link.setAttribute("download", `${name}.txt`);
      // Append to html link element page
      document.body.appendChild(link);
      // Start download
      link.click();
      setDownloadType("");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [downloadType]);

  const handleSave = async () => {
    if (!selectedVideoInfo?._id)
      return toast.error("Please Select Live Stream");
    const out = {
      productionResourceId: selectedVideoInfo?._id || "",
      ...(type === STREAM_TAB_TYPE?.TEXT_EDITOR && { shortSummary: code }),
      ...(type === STREAM_TAB_TYPE?.SCRIPT_EDITOR && { description: code }),
      type: LIVE_SUBTITLE_TYPE, // this type indicate to LIVE Stream
    };
    try {
      setIsLoading(true);
      await createShortSummary(out);
      setIsLoading(false);
      setInfoModalMessage(
        `${
          type === STREAM_TAB_TYPE?.TEXT_EDITOR ? "Shotlist" : "Script"
        } updated for live stream: ${selectedVideoInfo?.title}`
      );
      setInfoModalStatus(true);

      const tempSubtitle = [...selectedVideoSubtitle];

      if (tempSubtitle.length > 0) {
        const firstItem = tempSubtitle[0];

        if (type === STREAM_TAB_TYPE?.TEXT_EDITOR) {
          tempSubtitle[0] = {
            ...firstItem,
            shortSummary: code,
          };
        } else if (type === STREAM_TAB_TYPE?.SCRIPT_EDITOR) {
          tempSubtitle[0] = {
            ...firstItem,
            description: code,
          };
        }
      }

      setSelectedVideoSubtitle(tempSubtitle);
      setIsEdit(!isEdit);
    } catch (err: any) {
      setIsLoading(false);
      if (err?.type && err?.type === ERROR_CODE?.DELETE) {
        moveToListingPage();
      }
      toast.error(err?.msg || err?.message);
      setIsEdit(!isEdit);
    }
  };

  const toggleTooltip = () => {
    setTooltipDownload(!tooltipDownload);
  };

  useEffect(() => {
    if (selectedVideoInfo?._id) {
      if (selectedVideoSubtitle && selectedVideoSubtitle.length > 0) {
        setCode(
          type === STREAM_TAB_TYPE?.TEXT_EDITOR
            ? selectedVideoSubtitle[0]?.shortSummary
            : selectedVideoSubtitle[0]?.description
        );
      }
    } else {
      toast.error("Please Select Live Stream");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedVideoInfo, selectedVideoSubtitle]);

  return (
    <div className={`text-editor-outer`}>
      {isLoading ? <SpinnerLoader /> : ""}
      <div className="flex-between">
        <div className="heading">{title}</div>
        <div className="flex-between" style={{ gap: 20 }}>
          {isEdit ? (
            <div className="edit-btn" onClick={() => setIsEdit(!isEdit)}>
              <PencilIcon />
            </div>
          ) : (
            <ButtonComponent
              title="Save"
              className="subtitle-save-btn"
              onClick={() => handleSave()}
            />
          )}
          <div
            className="sub-download-btn"
            onClick={() => {
              setDownloadType("txt");
            }}
            id="download-txt"
          >
            <SubtitleDownloadIcon />
          </div>
          <Tooltip
            placement="top"
            isOpen={tooltipDownload}
            autohide={true}
            target={`download-txt`}
            toggle={toggleTooltip}
          >
            Download TXT file
          </Tooltip>
          <div
            style={{ cursor: "pointer" }}
            onClick={() => toggleComponent(type)}
          >
            <img src={CloseIcon} alt="close" />
          </div>
        </div>
      </div>
      <ReactQuill
        theme="snow"
        modules={modules}
        formats={formats}
        value={code}
        readOnly={isEdit}
        onChange={handleProcedureContentChange}
      />
    </div>
  );
};

export default TextEditor;
