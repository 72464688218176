import React, { useEffect, useState } from "react";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import "../StreamModal/StreamModal.scss";
import CLoseIcon from "../../../../../assets/images/New/close.svg";
import { Modal, ModalBody } from "reactstrap";
import { ProcessingModalProps } from "../../../../../Common/interface/types";

const ProcessingModal = (props: ProcessingModalProps) => {
  const {
    setModalStatus,
    modalStatus,
    data,
    processingMsg,
    completedMsg,
    handleClose,
  } = props;

  const [counter, setCounter] = useState(0);

  useEffect(() => {
    if (counter < 100) {
      const interval = setInterval(() => setCounter(counter + 1), 20);
      //Cleaning it up
      return () => clearInterval(interval);
    }
  }, [counter]);

  useEffect(() => {
    if (data) {
      setTimeout(() => {
        setModalStatus(false);
      }, 1500);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  return (
    <Modal
      isOpen={modalStatus}
      className="auto-detect-process-modal"
      onToggle={() => handleClose()}
    >
      <ModalBody>
        <div className="header">
          <img
            alt="close"
            className="cursor-pointer"
            src={CLoseIcon}
            onClick={() => handleClose()}
          />
        </div>
        <div className="auto-detect-processing">
          {data ? (
            <h6>
              {completedMsg}
              {data && data !== null && data !== "Unknown" && (
                <>
                  <span style={{ margin: "0px 2px" }}>into</span>
                  <span style={{ color: "#6b25b1", margin: "0px 2px" }}>
                    {data}
                  </span>
                </>
              )}
            </h6>
          ) : (
            <div className="auto-detect-processing">
              <CircularProgressbar
                value={counter}
                text={`${counter}%`}
                strokeWidth={5}
                styles={buildStyles({
                  strokeLinecap: "butt",
                  pathColor: "#A546EF",
                  textColor: "#A546EF",
                })}
              />
              <h6>{processingMsg}</h6>
            </div>
          )}
        </div>
      </ModalBody>
    </Modal>
  );
};

export default ProcessingModal;
