import "./FileSection.scss";
import AddIcon from "../../../assets/images/New/Add.svg";
import DownloadIcon from "../../../assets/images/New/Download.svg";
import DeleteIcon from "../../../assets/images/New/Delete.svg";
import FileIcon from "../../../assets/images/New/file.svg";
import CloseIcon from "../../../assets/images/New/close.svg";
import File from "./File/File";
import { useEffect, useState } from "react";
import { SortData, TAB_TYPE } from "../../../Constants/Common/Data";
import Select from "react-select";
import DownloadModal from "./DownloadModal/DownloadModal";
import useTabStore from "../../../Store/TabStore/TabStore";
import { handleDivResize } from "../../../Common/helper/util";
import {
  getResources,
  postDeleteResource,
  postFetchTextDetails,
  postRenameFile,
} from "../../../ApiService/ApiCall";
import useTranscriptionStore from "../../../Store/TranscriptionStore/TranscriptionStore";
import { getLangName } from "../../../Common/helper/helper";
import { VideoTextType } from "../../../Common/interface/types";
import Pagination from "../Common/Pagination/Pagination";
import {
  faArrowsRotate,
  faShareNodes,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ShareModal from "./ShareModal/ShareModal";
import UploadFile from "./UploadFile/UploadFile";
import useFileUploadStore from "../../../Store/UploadStore/uploadStore";
import DeleteModal from "../Common/DeleteModal";
import { getVideoListApi } from "../../../Common/helper/videohelper";
import useFileDownloadStore from "../../../Store/DownloadStore/DownloadStore";
import UploadFileInfo from "./UploadFile/UploadFileInfo";
import { MESSAGE } from "../../../Constants/Messages";
import Toast from "../../../Common/helper/toasterService";
import { Input } from "reactstrap";
import { SearchIcon } from "../../Common/Icon";

const FileSection = () => {
  const [selectedSort, setSelectedSort] = useState(SortData[0]);
  const [limit] = useState(10);
  const [divSize, setDivSize] = useState(false);
  const [loadingDiv, setLoadingDiv] = useState(false);
  const [isReRender, setIsReRender] = useState(false);
  const [shareModalStatus, setShareModalStatus] = useState(false);
  const [downloadModalOpen, setDownloadModalOpen] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [search, setSearch] = useState("");

  const {
    transcriptionVideoList,
    selectedVideoId,
    setSelectedVideo,
    selectedVideoInfo,
    setLangList,
    setSelectedLang,
    setTranscriptionList,
    setLoading,
    listUpdateStatus,
    setListUpdateStatus,
    setDefaultLang,
    setRightSubtitleData,
    setLeftSubtitleData,
    setVideoLoad,
    selectedResourceId,
    selectedResourceInfo,
    setSelectedResourceId,
    setSelectedResourceInfo,
    setPageCount,
    totalCount,
    pageCount,
    setSubtitleData,
    setSelectedPage,
    selectedPage,
    setSkipCount,
    skipCount,
    setSearchValue,
    searchValue,
  } = useTranscriptionStore((state: any) => ({
    transcriptionVideoList: state.transcriptionVideoList,
    selectedVideoId: state.selectedVideoId,
    setSelectedVideo: state.setSelectedVideo,
    selectedVideoInfo: state.selectedVideoInfo,
    setLangList: state.setLangList,
    setSelectedLang: state.setSelectedLang,
    setTranscriptionList: state.setTranscriptionList,
    setLoading: state.setLoading,
    listUpdateStatus: state.listUpdateStatus,
    setListUpdateStatus: state.setListUpdateStatus,
    setDefaultLang: state.setDefaultLang,
    setRightSubtitleData: state.setRightSubtitleData,
    setLeftSubtitleData: state.setLeftSubtitleData,
    setVideoLoad: state.setVideoLoad,
    selectedResourceId: state.selectedResourceId,
    selectedResourceInfo: state.selectedResourceInfo,
    setSelectedResourceId: state.setSelectedResourceId,
    setSelectedResourceInfo: state.setSelectedResourceInfo,
    setPageCount: state.setPageCount,
    totalCount: state.totalCount,
    pageCount: state.pageCount,
    setSubtitleData: state.setSubtitleData,
    setSelectedPage: state.setSelectedPage,
    selectedPage: state.selectedPage,
    setSkipCount: state.setSkipCount,
    skipCount: state.skipCount,
    setSearchValue: state.setSearchValue,
    searchValue: state.searchValue,
  }));

  const { addFileToDownload, setDownloadType, downloadFiles } =
    useFileDownloadStore();

  const {
    toggleActiveItem,
    windowSmallSize,
    activeItems,
    setFileActive,
    fileActive,
  } = useTabStore((state: any) => ({
    toggleActiveItem: state.toggleActiveItem,
    windowSmallSize: state.windowSmallSize,
    activeItems: state.activeItems,
    setFileActive: state.setFileActive,
    fileActive: state.fileActive,
  }));
  const toggleDownloadModal = () => {
    setDownloadModalOpen(!downloadModalOpen);
    if (downloadModalOpen) {
      setSelectedResourceId([]);
      setSelectedResourceInfo([]);
    }
  };

  const {
    showUpload,
    setShowUpload,
    uploadStatus,
    showUploadInfo,
    setShowUploadInfo,
  } = useFileUploadStore((state: any) => ({
    showUpload: state.showUpload,
    setShowUpload: state.setShowUpload,
    uploadStatus: state.uploadStatus,
    showUploadInfo: state.showUploadInfo,
    setShowUploadInfo: state.setShowUploadInfo,
  }));

  useEffect(() => {
    const containerDiv = document.getElementById("file-section");
    const handleResize = () => {
      if (containerDiv && containerDiv?.offsetWidth < 765) {
        // console.log('Screen size is less than 765 pixels.');
        setDivSize(true);
      } else {
        setDivSize(false);
      }
    };
    containerDiv?.addEventListener("resize", handleResize);

    var resizeObserver = new ResizeObserver(handleResize);
    containerDiv && resizeObserver.observe(containerDiv);

    return () => {
      containerDiv?.removeEventListener("resize", handleResize);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (activeItems.includes(TAB_TYPE.FILE)) {
      setFileActive(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeItems]);

  const handleResizeDiv = () => {
    const containerDiv = document.getElementById("file-section");
    handleDivResize(containerDiv, setDivSize);
  };

  const getTranscriptionList = async (sortValue: string) => {
    try {
      let res: any = await getResources({
        skip: skipCount,
        limit: limit,
        sortBy: sortValue,
        searchStr: searchValue,
      });
      return res;
      // return setJson(res);
    } catch (err: any) {
      Toast.showError(err);
      console.log(err);
    }
  };

  useEffect(() => {
    const getMoreList = async () => {
      await getMoreVideoList(selectedSort.value);
    };
    if (loadingDiv) {
      getMoreList();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [skipCount]);

  const getMoreVideoList = async (sortValue: string) => {
    setLoading(true);
    let res = await getTranscriptionList(sortValue);
    if (res && res?.result.length > 0) {
      setTranscriptionList(res?.result);
    }
    setLoadingDiv(false);
    setLoading(false);
  };

  useEffect(() => {
    if (!fileActive || isReRender) {
      if (
        selectedVideoInfo &&
        selectedVideoInfo?.defaultLanguage &&
        selectedVideoInfo?.defaultLanguage[0]
      ) {
        getTextDetails();
      } else {
        setVideoLoad(false);
        setLeftSubtitleData("");
        setRightSubtitleData("");
        setSubtitleData("");
        setTimeout(() => {
          setVideoLoad(true);
        }, 200);
      }
      setPageCount(Math.ceil(totalCount / limit));
      setSkipCount(selectedPage * limit);
      setIsReRender(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedVideoId, selectedVideoInfo]);

  const getTextDetails = async () => {
    setLoading(true);
    setVideoLoad(false);
    let res: VideoTextType | any = await getTextDetailsApi();

    if (res && res[0]) {
      setLeftSubtitleData(res[0]);
      setSubtitleData(res);
    } else {
      setLeftSubtitleData("");
      setRightSubtitleData("");
      setSubtitleData("");
    }
    if (res && res[1]) {
      setRightSubtitleData(res[1]);
    } else {
      setRightSubtitleData("");
    }
    setVideoLoad(true);
    setLoading(false);
  };

  const getTextDetailsApi = async () => {
    try {
      let res = await postFetchTextDetails({
        productionResourceId: selectedVideoId,
      });
      return res;
    } catch (err: any) {
      Toast.showError(err?.msg);
      setLeftSubtitleData("");
      setRightSubtitleData("");
      setSubtitleData("");
      console.log(err);
    }
  };

  useEffect(() => {
    setIsReRender(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedSort, listUpdateStatus, selectedVideoId, selectedResourceInfo]);

  useEffect(() => {
    if (selectedVideoId && listUpdateStatus) {
      updateList();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [listUpdateStatus]);

  const handleSortChange = async (selectedValue: any) => {
    setSelectedSort(selectedValue);
    await getMoreVideoList(selectedValue.value);
  };

  const handleVideoSelect = (videoId: string) => {
    setSelectedVideo(videoId);
    setIsReRender(true);
    setVideoLoad(false);
    setLoading(true);

    let videoList = transcriptionVideoList.filter((_data: any) => {
      return _data._id === videoId;
    });

    if (
      videoList &&
      videoList[0]?.languages &&
      videoList[0]?.languages.length > 0
    ) {
      setDefaultLang(videoList[0]?.defaultLanguage[0]);
      setLangList(videoList[0]?.languages);
      if (videoList[0]?.languages.length > 0) {
        setSelectedLang({
          value:
            videoList[0]?.languages[1]?.language[0] &&
            videoList[0]?.languages[1]?.language[0].toUpperCase(),
          label:
            videoList[0]?.languages[1]?.language[0] &&
            getLangName(videoList[0]?.languages[1]?.language[0].toUpperCase()),
        });
      }
      setVideoLoad(true);
      setLoading(false);
    } else {
      setTimeout(() => {
        setVideoLoad(true);
      }, 200);
      setLoading(false);
      setLeftSubtitleData("");
      setLangList([]);
      setDefaultLang("");
      setSelectedLang({
        value: "",
        label: "",
      });
    }
    checkVideoTab();
  };

  const checkVideoTab = () => {
    if (!activeItems.includes(TAB_TYPE.VIDEO)) {
      toggleActiveItem(TAB_TYPE.VIDEO);
    }
  };

  const handleVideoShare = (e: any, data: any) => {
    e.stopPropagation();
    const index = selectedResourceId.indexOf(data?._id);
    if (index === -1) {
      setSelectedResourceId([...selectedResourceId, data?._id]);
      setSelectedResourceInfo([...selectedResourceInfo, data]);
    } else {
      const newArray = [...selectedResourceId];
      newArray.splice(index, 1);
      setSelectedResourceId(newArray);

      const tempObj = [...selectedResourceInfo];
      tempObj.splice(index, 1);
      setSelectedResourceInfo(tempObj);
    }
  };

  const handleUpload = () => {
    if (!uploadStatus) {
      setShowUpload(!showUpload);
    } else {
      Toast.showCustom(MESSAGE.WARNING.uploading);
    }
    if (showUploadInfo) {
      setShowUploadInfo(false);
    }
  };

  const handleDownloadFiles = async (property: string) => {
    setDownloadModalOpen(false);
    if (downloadFiles && downloadFiles.length === 0) {
      addFileToDownload(selectedResourceInfo);
      setDownloadType(property);
      setSelectedResourceId([]);
      setSelectedResourceInfo([]);
    } else {
      Toast.showCustom(MESSAGE.WARNING.downloadWarning);
    }
  };

  const handleDeleteModal = () => {
    if (selectedResourceInfo && selectedResourceInfo.length > 0) {
      setShowDeleteModal(!showDeleteModal);
    }
  };

  const handleDelete = async () => {
    if (
      selectedResourceId &&
      selectedResourceId.length > 0 &&
      !checkVideoShareOrNot("delete")
    ) {
      setLoading(true);
      setShowDeleteModal(false);
      const res: any = await deleteResource();
      if (res && res?.status) {
        setLoading(false);
        Toast.showSuccess(res?.message);
        await handleRefresh();
      } else if (res?.message) {
        setLoading(false);
        Toast.showError(res?.message);
      }
      setSelectedResourceId([]);
      setSelectedResourceInfo([]);
      setLoading(false);
    }
  };

  const deleteResource = async () => {
    try {
      let res = await postDeleteResource({
        resourceIds: selectedResourceId,
      });
      return res;
    } catch (err: any) {
      setLoading(false);
      Toast.showError(err.msg);
      console.log(err);
    }
  };

  const handleChangeFileName = async (id: string, title: string) => {
    setLoading(true);
    await callUpdateFileNameApi(id, title);
    const updatedList = transcriptionVideoList.map((data: any) => {
      if (data._id === id) {
        data.title = title;
      }
      return data;
    });
    setTranscriptionList(updatedList);
    setLoading(false);
  };

  const callUpdateFileNameApi = async (id: string, title: string) => {
    try {
      const res: any = await postRenameFile({
        id: id,
        fileName: title,
      });
      Toast.showSuccess(res.message);
    } catch (err: any) {
      console.log(err);
      Toast.showError(err?.msg);
    }
  };

  const handlePageChange = (event: any) => {
    let temSkip = event.selected * limit;
    setSkipCount(temSkip);
    setSelectedPage(event.selected);
    setLoadingDiv(true);
  };

  const handleShareModal = () => {
    if (selectedResourceId && selectedResourceId.length > 0) {
      setShareModalStatus(!shareModalStatus);
    }
  };

  const checkVideoShareOrNot = (type: string) => {
    let found = false;
    const sharedVideos = selectedResourceInfo.filter(
      (video: any) => video.shared === true
    );
    if (sharedVideos.length > 0) {
      sharedVideos.forEach((video: any) => {
        Toast.showError(
          `Video '${video.title}' is shared. You can't ${type} this`
        );
      });
      found = true;
    }
    return found;
  };

  const handleDownload = () => {
    if (selectedResourceId && selectedResourceId.length > 0) {
      setDownloadModalOpen(true);
    }
  };

  const updateList = async () => {
    const storeMethods = useTranscriptionStore.getState();
    await getVideoListApi(storeMethods, false);
    setSkipCount(0);
    setSelectedPage(0);
    setListUpdateStatus(false);
  };

  const handleRefresh = async () => {
    const storeMethods = useTranscriptionStore.getState();
    setLoading(true);
    setSkipCount(0);
    setSelectedPage(0);
    setSelectedSort(SortData[0]);
    await getVideoListApi(storeMethods, true);
    setLoading(false);
  };

  useEffect(() => {
    if (searchValue) {
      setSearch(searchValue);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSearchChange = (value: string) => {
    setSearch(value);
  };

  const handleSearch = () => {
    setSkipCount(0);
    setSelectedPage(0);
    setSearchValue(search);
  };

  const handleRemoveSearch = () => {
    setSearch("");
    setSearchValue("");
    setSkipCount(0);
    setSelectedPage(0);
  };

  return (
    <div
      className={`file-section ${
        activeItems.includes(TAB_TYPE.WAVE) ? "h-80" : ""
      }`}
      id="file-section"
      onResize={handleResizeDiv}
    >
      <div className="header">
        <div>
          <img src={FileIcon} alt="file" />
        </div>
        <div
          className="close-btn"
          onClick={() => toggleActiveItem(TAB_TYPE.FILE)}
        >
          <img src={CloseIcon} alt="close" />
        </div>
      </div>
      <div className="action-section">
        {!showUpload && !showUploadInfo ? (
          <div className="custom-btn" onClick={handleUpload} title="Add Media">
            <img src={AddIcon} alt="add" />
            {!windowSmallSize && !divSize ? "Add media" : ""}
          </div>
        ) : (
          <div className="custom-btn" onClick={handleUpload}>
            <img src={CloseIcon} alt="cancel" />
            {!windowSmallSize && !divSize ? "Cancel" : ""}
          </div>
        )}
        <div
          className={`custom-btn ${
            selectedResourceId && selectedResourceId.length > 0 ? "" : "disable"
          }`}
          onClick={handleDownload}
          title="Download"
        >
          <img src={DownloadIcon} alt="download" />
          {!windowSmallSize && !divSize ? "Download" : ""}
        </div>
        <div
          className={`custom-btn ${
            selectedResourceId && selectedResourceId.length > 0 ? "" : "disable"
          }`}
          onClick={handleDeleteModal}
          title="Delete"
        >
          <img src={DeleteIcon} alt="delete" />
          {!windowSmallSize && !divSize ? "Delete" : ""}
        </div>
        <div
          className={`custom-btn ${
            selectedResourceId && selectedResourceId.length > 0 ? "" : "disable"
          }`}
          onClick={handleShareModal}
          title="Share"
        >
          <FontAwesomeIcon icon={faShareNodes} />
          {!windowSmallSize && !divSize ? "Share" : ""}
        </div>
        <div
          className={`custom-btn`}
          onClick={() => {
            handleRefresh();
            // setRefreshBtnStatus(true);
          }}
          title="Refresh"
        >
          <FontAwesomeIcon icon={faArrowsRotate} />
          {!windowSmallSize && !divSize ? "Refresh" : ""}
        </div>
        <div className="search">
          <Input
            type="text"
            className="form-control"
            placeholder="Search"
            autoComplete="off"
            value={search}
            id="search"
            onChange={(e) => handleSearchChange(e.target.value)}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                handleSearch();
              }
            }}
          />
          {searchValue && searchValue.length > 0 && (
            <div className="cancel-search" onClick={handleRemoveSearch}>
              <img alt="close" src={CloseIcon} />
            </div>
          )}
          <div className="search-btn" onClick={handleSearch}>
            <SearchIcon />
          </div>
        </div>
        <div>
          <Select
            className="sort-select"
            defaultValue={selectedSort}
            value={selectedSort}
            onChange={handleSortChange}
            options={SortData}
            name="Sort"
            placeholder="Sort by"
          />
        </div>
      </div>
      <div className="file-uploader">
        {showUpload && <UploadFile />}
        {showUploadInfo && <UploadFileInfo />}
      </div>
      <div
        className={`file-outer ${divSize ? "small" : ""}`}
        id="file-main-outer"
      >
        {transcriptionVideoList &&
          transcriptionVideoList.length > 0 &&
          transcriptionVideoList.map((_data: any, index: any) => {
            return (
              <File
                key={index}
                id={_data?._id}
                title={_data?.title}
                date={_data?.date}
                size={_data?.size}
                user={_data?.user}
                status={_data?.status}
                languages={_data?.languages}
                selectedVideoId={selectedVideoId}
                isShared={_data?.shared}
                data={_data}
                handelVideoSelect={(id) => handleVideoSelect(id)}
                handleVideoShare={(e, data) => handleVideoShare(e, data)}
                handleFileName={(id, title) => handleChangeFileName(id, title)}
                selectedResourceId={selectedResourceId}
                openModal={function (id: string): unknown {
                  throw new Error("Function not implemented.");
                }}
              />
            );
          })}

        {transcriptionVideoList &&
          transcriptionVideoList.length === 0 &&
          searchValue !== "" && (
            <div className="not-found">{MESSAGE.CONTENT.searchNotFound}</div>
          )}
      </div>
      {transcriptionVideoList && transcriptionVideoList.length > 0 && (
        <Pagination
          list={transcriptionVideoList}
          totalCount={totalCount}
          limit={limit}
          skipCount={skipCount}
          pageCount={pageCount}
          selectedPage={selectedPage}
          handlePageChange={handlePageChange}
        />
      )}
      {selectedResourceInfo &&
        selectedResourceInfo.length > 0 &&
        shareModalStatus && (
          <ShareModal
            modalStatus={shareModalStatus}
            handleModal={handleShareModal}
            selectedResourceInfo={selectedResourceInfo}
          />
        )}
      {downloadModalOpen && (
        <DownloadModal
          isOpen={downloadModalOpen}
          onClose={toggleDownloadModal}
          handleCompressDownload={() => handleDownloadFiles("url")}
          handleDirectDownload={() => handleDownloadFiles("RawResourcePath")}
          selectedResourceInfo={selectedResourceInfo}
        />
      )}
      {selectedResourceInfo &&
        selectedResourceInfo.length > 0 &&
        showDeleteModal && (
          <DeleteModal
            show={showDeleteModal}
            onCloseClick={() => {
              setShowDeleteModal(false);
            }}
            onDeleteClick={handleDelete}
            deleteType={true}
          />
        )}
    </div>
  );
};

export default FileSection;
