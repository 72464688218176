// utils.ts
export const handleDivResize = (containerDiv: any, setDivSize: any) => {
  if (containerDiv && containerDiv.offsetWidth < 765) {
    // console.log('Screen size is less than 765 pixels.');
    setDivSize(true)
  }
  else {
    setDivSize(false)
  }
};
