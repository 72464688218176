import { Modal, ModalBody } from "reactstrap";
import "./ConfirmationModel.scss";
import { CommonConfirmationModalProps } from "../../../../Common/interface/types";
import { CONFIRMATION_MODAL_TYPE } from "../../../../Constants/Common/Data";

const ConfirmationModal = (props: CommonConfirmationModalProps) => {
  const {
    show,
    handleClose,
    handleSubmit,
    icon,
    title,
    description,
    actionType,
    modalType,
  } = props;
  return (
    <Modal
      className="confirmation-modal-popup"
      fade={true}
      isOpen={show}
      toggle={handleClose}
      centered={true}
    >
      <ModalBody>
        <div className="confirmation-modal-container">
          <div className="header">
            <img src={icon} alt="icon" />
          </div>
          <div className="content">
            <div className="title">{title}</div>
            <div className="description">{description}</div>
          </div>
          <div className="action">
            {CONFIRMATION_MODAL_TYPE?.ACTION === modalType && (
              <>
                <div className="cancel-btn" onClick={handleClose}>
                  Cancel
                </div>
                <div className="submit-btn" onClick={handleSubmit}>
                  {actionType}
                </div>
              </>
            )}
            {CONFIRMATION_MODAL_TYPE?.INFO === modalType && (
              <>
                <div className="submit-btn" onClick={handleSubmit}>
                  {actionType}
                </div>
              </>
            )}
          </div>
        </div>
      </ModalBody>
    </Modal>
  );
};
export default ConfirmationModal;
