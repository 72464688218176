import React from "react";
import "./ToggleSwitch.scss";
const ToggleSwitch = (props: any) => {
  const { id, name, checked, onChange } = props;

  function handleKeyPress(e: any) {
    if (e.keyCode !== 32) return;
    e.preventDefault();
    onChange(!checked);
  }

  return (
    <div
      className={"toggle-switch small-switch"}
      onClick={(evt) => evt.stopPropagation()}
    >
      <input
        type="checkbox"
        name={name}
        className="toggle-switch-checkbox"
        id={id}
        checked={checked}
        onChange={(e) => onChange(e.target.checked)}
      />
      {id ? (
        <label
          className="toggle-switch-label"
          onKeyDown={(e) => handleKeyPress(e)}
          htmlFor={id}
        >
          <span className={"toggle-switch-inner"} />
          <span className={"toggle-switch-switch"} />
        </label>
      ) : null}
    </div>
  );
};

export default ToggleSwitch;
